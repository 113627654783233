import {
  Breadcrumb,
  Breadcrumbs as BreadcrumbsComponent,
} from "@/components/breadcrumbs";

export function Breadcrumbs() {
  return (
    <BreadcrumbsComponent>
      <Breadcrumb href="/">Account Name</Breadcrumb>
      <Breadcrumb href="/">Brand Name</Breadcrumb>
      <Breadcrumb href="/">Graphic</Breadcrumb>
      <Breadcrumb>Long Request Name for testing- ID</Breadcrumb>
    </BreadcrumbsComponent>
  );
}
