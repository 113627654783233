import { CloudUpload } from "lucide-react";

export function UploadAssetsInput() {
  return (
    <div className="flex flex-col items-center gap-lg">
      <div className="grid size-5xl place-items-center rounded-md border border-border-secondary">
        <CloudUpload className="text-fg-secondary" size={16} />
      </div>
      <div className="flex flex-col gap-xs text-center">
        <span className="text-sm font-semibold text-button-tertiary-color-fg">
          Click to upload{" "}
          <span className="text-sm font-normal text-text-tertiary">
            or drag and drop
          </span>
        </span>
        <span className="text-xs text-text-tertiary">
          Share asset files or links to use as reference for inspiration. When
          sharing links please grant access to{" "}
          <span className="font-semibold text-text-brand-tertiary">
            admin@nolimitcreatives.com
          </span>{" "}
          for Google Drive, and{" "}
          <span className="font-semibold text-text-brand-tertiary">
            teams@nlc.com
          </span>{" "}
          for Dropbox.
        </span>
      </div>
    </div>
  );
}
