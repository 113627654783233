import { useSearch } from "@tanstack/react-router";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

import {
  ComboBox,
  ComboBoxButton,
  ComboBoxGroup,
  ComboBoxInput,
  ComboBoxListBox,
  ComboBoxListItem,
  ComboBoxPopover,
  ComboBoxSection,
} from "@/components/combobox";
import { FieldError, Label } from "@/components/field";
import { SelectListItemDescription } from "@/components/select";
import { SelectListItemLabel } from "@/components/select";
import { Text } from "@/components/text";

import { useCreateWebsiteDesignRequestFormContext } from "../useCreateWebsiteDesignRequestForm";
import { useGetDesignTypes } from "./useGetDesignTypes";

export function SelectDesignType() {
  const { control, setValue } = useCreateWebsiteDesignRequestFormContext();
  const { designType } = useSearch({
    from: "/_auth/_app-shell/requests/create",
  });

  useEffect(() => {
    if (designType) {
      setValue("designTypeId", designType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useGetDesignTypes();

  if (data) {
    return (
      <Controller
        control={control}
        name="designTypeId"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <ComboBox
            allowsEmptyCollection
            isInvalid={invalid}
            menuTrigger="input"
            name={name}
            onBlur={onBlur}
            onSelectionChange={onChange}
            selectedKey={value}
          >
            <Label requiredHint>Design type</Label>
            <ComboBoxGroup>
              <ComboBoxInput placeholder="Select design type" ref={ref} />
              <ComboBoxButton />
            </ComboBoxGroup>
            <FieldError>{error?.message}</FieldError>

            <ComboBoxPopover>
              <ComboBoxListBox
                className="max-h-[291px]"
                renderEmptyState={() => (
                  <Text className="p-2">No results found</Text>
                )}
              >
                {data
                  // Filters out design types that are not available for the current subscription plan
                  .filter((subcategory) => !subcategory.isBlocked)
                  .map((subcategory) => (
                    <ComboBoxSection
                      key={subcategory.name}
                      title={subcategory.name}
                    >
                      {subcategory.types
                        .filter((type) => !type.isBlocked)
                        .map((type) => {
                          if (!type.description) {
                            return (
                              <ComboBoxListItem id={type.id} key={type.name}>
                                {type.name}
                              </ComboBoxListItem>
                            );
                          }

                          return (
                            <ComboBoxListItem
                              id={type.id}
                              key={type.name}
                              textValue={type.name}
                            >
                              <SelectListItemLabel>
                                {type.name}
                              </SelectListItemLabel>
                              <SelectListItemDescription className="block w-full">
                                {type.description}
                              </SelectListItemDescription>
                            </ComboBoxListItem>
                          );
                        })}
                    </ComboBoxSection>
                  ))}
              </ComboBoxListBox>
            </ComboBoxPopover>
          </ComboBox>
        )}
      />
    );
  }

  return (
    <ComboBox allowsEmptyCollection>
      <Label requiredHint>Design Type</Label>
      <ComboBoxGroup>
        <ComboBoxInput placeholder="Loading..." />
        <ComboBoxButton />
      </ComboBoxGroup>
    </ComboBox>
  );
}
