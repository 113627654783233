import type { RequestCategory } from "@nlc/shared-types";

import { Link, useSearch } from "@tanstack/react-router";
import { SearchIcon } from "lucide-react";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

import { Button } from "@/components/button";

import type { DesignType, SubcategoryWithIcon } from "../types";

import boostModalImage from "../assets/boost-modal.png";
import LayersIcon from "../assets/layers.svg?react";
import LockIcon from "../assets/lock.svg?react";
import { DesignTypesCarousel } from "./DesignTypesCarousel";
import { SearchButton } from "./SearchButton";
import { UpgradeModal } from "./UpgradeModal";

type DesignTypeCardProps = {
  designType: DesignType;
};

function DesignTypeCard({ designType }: DesignTypeCardProps) {
  const { ["design-type"]: selectedDesignType } = useSearch({
    from: "/_auth/_app-shell/requests/pick-type",
  });

  return (
    <Link
      className={twMerge(
        "relative h-[214px] w-[222px] shrink-0 cursor-pointer overflow-hidden rounded-sm border border-border-secondary transition-all duration-150 ease-in-out hover:border-utility-brand-400",
        selectedDesignType === designType.id &&
          "outline outline-2 outline-utility-brand-600",
        "focus-visible:outline focus-visible:outline-4 focus-visible:outline-utility-brand-600/20 focus-visible:border-utility-brand-400",
        "active:outline active:outline-2 active:outline-utility-brand-600",
      )}
      search={(prev) => ({
        designType: designType.id,
        ["project-id"]: prev["project-id"],
        ["request-type"]: designType.requestType,
      })}
      to="/requests/create"
    >
      <div className="relative flex h-full flex-col items-center gap-sm">
        <div className="size-full bg-bg-type-card-background">
          <img src={designType.placeholderImageUrl ?? undefined} />
        </div>
        {designType.description && (
          <div className="absolute bottom-[53px] rounded-full border border-utility-gray-200 bg-utility-gray-50 px-md py-xxs text-xs font-medium text-utility-gray-700">
            {designType.description}
          </div>
        )}
        <div className="h-5xl bg-bg-primary text-md font-medium text-text-secondary">
          {designType.name}
        </div>
      </div>
    </Link>
  );
}

type SubcategoryPanelProps = {
  designTypes: DesignType[];
  subcategory: SubcategoryWithIcon;
};

function SubcategoryPanel({ designTypes, subcategory }: SubcategoryPanelProps) {
  const subcategoryDesignTypes = designTypes.filter(
    (type) => type.subcategoryId === subcategory.id,
  );

  return (
    <div className="flex h-[calc(100vh-300px)] flex-col gap-lg overflow-y-auto px-3xl pt-xs">
      <div className="flex flex-col gap-lg">
        <div className="flex h-5xl items-center">
          <span className="text-lg font-semibold text-text-primary">
            {subcategory.name}
          </span>
        </div>
        <div className="flex flex-wrap gap-[10px]">
          {subcategoryDesignTypes.map((type) => (
            <DesignTypeCard designType={type} key={type.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

type SubcategoryContentProps = {
  categoryId: RequestCategory;
  designTypes: DesignType[];
  onClearSearch: () => void;
  searchQuery: string;
  subcategories: SubcategoryWithIcon[];
};

function SubcategoryContent({
  categoryId,
  designTypes,
  onClearSearch,
  searchQuery,
  subcategories,
}: SubcategoryContentProps) {
  const categorySubcategories = subcategories.filter(
    (sub) => sub.category === categoryId,
  );

  const filteredTypes = designTypes.filter(
    (type) =>
      type.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      categorySubcategories.some((sub) => sub.id === type.subcategoryId),
  );

  const filteredSubcategories = categorySubcategories.filter((subcategory) => {
    const types = filteredTypes.filter(
      (type) => type.subcategoryId === subcategory.id,
    );

    return types.length > 0;
  });

  if (searchQuery && filteredTypes.length === 0) {
    return (
      <div className="flex h-[calc(100vh-265px)] items-center justify-center">
        <div className="flex flex-col items-center gap-3xl">
          <div className="flex flex-col items-center text-center">
            <div className="mb-xl flex rounded-lg border border-featured-icon-modern-border p-lg">
              <SearchIcon size={24} />
            </div>
            <span className="mb-xs text-md font-semibold text-text-primary">
              No type request found
            </span>
            <span className="text-sm text-text-tertiary">
              Your search did not match any type request.
            </span>
            <span className="text-sm text-text-tertiary">
              Please try again.
            </span>
          </div>
          <Button onPress={onClearSearch} size="lg">
            Clear search
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex h-[calc(100vh-268px)] flex-col gap-lg overflow-y-auto overflow-x-hidden pb-2xl pt-xs">
        {filteredSubcategories.map((subcategory) => {
          const subcategoryDesignTypes = filteredTypes.filter(
            (type) => type.subcategoryId === subcategory.id,
          );

          if (subcategoryDesignTypes.length === 0) return null;

          return (
            <DesignTypesCarousel
              designTypes={subcategoryDesignTypes}
              key={subcategory.id}
              subcategoryName={subcategory.name}
            />
          );
        })}
      </div>
    </>
  );
}

type SubcategoryTabsProps = {
  categoryId: RequestCategory;
  designTypes: DesignType[];
  onSubcategoryChange: (key: string) => void;
  subcategories: SubcategoryWithIcon[];
  subcategory: string;
};

export function SubcategoryTabs({
  categoryId,
  designTypes,
  onSubcategoryChange,
  subcategories,
  subcategory,
}: SubcategoryTabsProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchQuery(value);
    if (value) {
      onSubcategoryChange("0");
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const categorySubcategories = subcategories.filter(
    (sub) => sub.category === categoryId,
  );

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex max-w-[960px] flex-wrap gap-sm px-3xl pt-lg">
          <Button
            color={subcategory === "0" ? "color" : "gray"}
            onPress={() => onSubcategoryChange("0")}
            size="sm"
            variant="secondary"
          >
            <LayersIcon />
            All
          </Button>
          {categorySubcategories.map((sub) => {
            if (sub.isBlocked) {
              return (
                <UpgradeModal
                  bodyText="Take your plan to the next level by unlocking additional capabilities.  Activate these new features right away at a prorated cost, or wait until your next renewal."
                  headerText="Ready to boost your plan with new features?"
                  heroImage={boostModalImage}
                  key={sub.id}
                  onConfirm={() => {
                    window.open("https://nlc.com/manage-my-account/", "_blank");
                  }}
                >
                  <Button
                    color={subcategory === sub.id.toString() ? "color" : "gray"}
                    key={sub.id}
                    size="sm"
                    variant="secondary"
                  >
                    <LockIcon />
                    {sub.name}
                  </Button>
                </UpgradeModal>
              );
            }

            return (
              <Button
                color={subcategory === sub.id.toString() ? "color" : "gray"}
                key={sub.id}
                onPress={() => onSubcategoryChange(sub.id.toString())}
                size="sm"
                variant="secondary"
              >
                {sub.icon && <sub.icon className="size-2xl" />}
                {sub.name}
              </Button>
            );
          })}
        </div>
        <div className="flex self-start pr-3xl pt-lg">
          <SearchButton onSearch={handleSearchChange} value={searchQuery} />
        </div>
      </div>
      {subcategory === "0" ? (
        <SubcategoryContent
          categoryId={categoryId}
          designTypes={designTypes}
          onClearSearch={handleClearSearch}
          searchQuery={searchQuery}
          subcategories={subcategories}
        />
      ) : (
        categorySubcategories.map(
          (sub) =>
            subcategory === sub.id.toString() && (
              <SubcategoryPanel
                designTypes={designTypes}
                key={sub.id}
                subcategory={sub}
              />
            ),
        )
      )}
    </div>
  );
}
