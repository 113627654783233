import { Link, useSearch } from "@tanstack/react-router";
import useEmblaCarousel from "embla-carousel-react";
import { twMerge } from "tailwind-merge";

import type { DesignType } from "../types";

import ArrowIcon from "../assets/arrow.svg?react";
import { usePrevNextButtons } from "../hooks/usePrevNextButtons";

type DesignTypesCarouselProps = {
  designTypes: DesignType[];
  subcategoryName: string;
};

export function DesignTypesCarousel({
  designTypes,
  subcategoryName,
}: DesignTypesCarouselProps) {
  const { ["design-type"]: designType } = useSearch({
    from: "/_auth/_app-shell/requests/pick-type",
  });

  const [emblaRef, emblaApi] = useEmblaCarousel();

  const {
    nextBtnDisabled,
    onNextButtonClick,
    onPrevButtonClick,
    prevBtnDisabled,
  } = usePrevNextButtons(emblaApi);

  return (
    <div className="flex flex-col gap-lg">
      <div className="flex h-5xl items-center justify-between px-3xl">
        <span className="text-lg font-semibold text-text-primary">
          {subcategoryName}
        </span>
        <div className="flex divide-x divide-border-primary overflow-hidden rounded-md border border-border-primary">
          <button
            className="flex cursor-pointer items-center px-lg py-md text-sm font-semibold text-text-secondary outline-none hover:bg-button-secondary-border-hover/15 disabled:cursor-default disabled:text-fg-disabled disabled:hover:bg-bg-primary"
            disabled={prevBtnDisabled}
            onClick={onPrevButtonClick}
          >
            <ArrowIcon className="rotate-180" />
          </button>
          <button
            className="flex cursor-pointer items-center px-lg py-md text-sm font-semibold text-text-secondary outline-none hover:bg-button-secondary-border-hover/15 disabled:cursor-default disabled:text-fg-disabled disabled:hover:bg-bg-primary"
            disabled={nextBtnDisabled}
            onClick={onNextButtonClick}
          >
            <ArrowIcon />
          </button>
        </div>
      </div>
      <div ref={emblaRef}>
        <div className="flex  pl-3xl">
          {designTypes.map((type) => (
            <Link
              aria-selected={designType === type.id}
              className={twMerge(
                "relative h-[214px] shrink-0 cursor-pointer overflow-hidden rounded-sm border border-border-secondary transition-all duration-150 ease-in-out hover:border-utility-brand-400 mr-[10px]",
                designType === type.id &&
                  "outline outline-2 outline-utility-brand-600",
                "focus-visible:outline focus-visible:outline-4 focus-visible:outline-utility-brand-600/20 focus-visible:border-utility-brand-400",
                "active:outline active:outline-2 active:outline-utility-brand-600",
              )}
              key={type.id}
              search={(prev) => ({
                designType: type.id,
                ["project-id"]: prev["project-id"],
                ["request-type"]: type.requestType,
              })}
              to="/requests/create"
            >
              <div className="relative flex h-full flex-col items-center gap-sm">
                <div className="size-full bg-bg-type-card-background">
                  <img
                    className="w-[233px]"
                    src={type.placeholderImageUrl ?? undefined}
                  />
                </div>
                {type.description && (
                  <div className="absolute bottom-[53px] rounded-full border border-utility-gray-200 bg-utility-gray-50 px-md py-xxs text-xs font-medium text-utility-gray-700">
                    {type.description}
                  </div>
                )}
                <div className="h-5xl bg-bg-primary text-md font-medium text-text-secondary">
                  {type.name}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
