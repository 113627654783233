import type { InputProps, SearchFieldProps } from "react-aria-components";

import { SearchIcon } from "lucide-react";
import { Group, SearchField as RACSearchField } from "react-aria-components";

import { Input } from "@/components/field";
import {
  composeTailwindRenderProps,
  inputFieldStyle,
} from "@/components/utils";

export function SearchField(props: SearchFieldProps) {
  return (
    <RACSearchField
      {...props}
      className={composeTailwindRenderProps(props.className, inputFieldStyle)}
    ></RACSearchField>
  );
}

export function SearchInput({ ...props }: InputProps) {
  return (
    <Group
      className={[
        "isolate",
        "grid",
        "grid-cols-[calc(theme(size.5)+20px)_1fr_calc(theme(size.5)+20px)]",
        "sm:grid-cols-[calc(theme(size.4)+20px)_1fr_calc(theme(size.4)+20px)]",
      ].join(" ")}
      data-ui="control"
    >
      <SearchIcon
        className="z-10 col-start-1 row-start-1 place-self-center text-fg-quaternary"
        size={15}
      />

      <Input
        {...props}
        className={composeTailwindRenderProps(props.className, [
          "[&::-webkit-search-cancel-button]:hidden",
          "col-span-full row-start-1 pe-10 ps-10 sm:pe-9 sm:ps-8",
        ])}
      />
    </Group>
  );
}
