import type { DuplicateRequestResponse } from "@nlc/shared-types";
import type { DefaultValues, UseFormReset } from "react-hook-form";

import { Delta } from "quill";

import type { CreateVideoRequestFormData } from "../components/useCreateVideoRequestForm";

import {
  convertDesignBriefToDeltaForDuplicate,
  mapAttachmentsByCategory,
  useDuplicateRequest,
} from "../../shared/useDuplicateRequest";

function mapDuplicateResponseToFormData(
  data: DuplicateRequestResponse,
): DefaultValues<CreateVideoRequestFormData> {
  const briefCreatorType = data.briefText ? "detailed" : "single";

  return {
    assets: mapAttachmentsByCategory(data.attachments, "asset"),
    brand: data.brandId || undefined,
    briefCreatorDetailed: {
      additionalGuidelines:
        data.magicalBriefCreator?.additionalGuidelines || "",
      brandGuidelines: data.magicalBriefCreator?.brandGuidelines || "",
      creativeGoal: data.magicalBriefCreator?.creativeGoals || "",
      finalBrief: data.briefText
        ? convertDesignBriefToDeltaForDuplicate(data.briefText)
        : new Delta(),
      finalBriefHTML: data.briefText || "",
      scope: data.magicalBriefCreator?.scope || "",
      script: data.magicalBriefCreator?.script || "",
    },
    briefCreatorSingle: {
      requestDescription: data.description
        ? convertDesignBriefToDeltaForDuplicate(data.description)
        : new Delta(),
      requestDescriptionHTML: data.description || "",
      textsForDesign: data.textForDesign
        ? convertDesignBriefToDeltaForDuplicate(data.textForDesign)
        : new Delta(),
      textsForDesignHTML: data.textForDesign || "",
    },
    briefCreatorType,
    collaborators: data.collaboratorIds?.map(String) || [],
    creativeDirection: data.creativeDirection || undefined,
    designComplexity: data.complexityId || undefined,
    fileTypes: data.preferredFileType || "",
    inspirations: mapAttachmentsByCategory(data.attachments, "example"),
    lengthOrPlatform: data.lengthOrPlatform || "",
    musicGenre: data.musicGenre || "",
    preferredDesigners: data.preferredDesignerIds?.map(String) || [],
    requestName: data.name || "",
    sizes: data.sizesNeeded || "",
    style: data.styleOfVideo || "",
    videoType: undefined,
  };
}

interface UseDuplicateVideoRequestProps {
  reset: UseFormReset<CreateVideoRequestFormData>;
}

export function useDuplicateVideoRequest({
  reset,
}: UseDuplicateVideoRequestProps) {
  return useDuplicateRequest<CreateVideoRequestFormData>({
    mapDuplicateResponseToFormData,
    reset,
  });
}
