import { Select } from "react-aria-components";

import {
  SelectButton,
  SelectListBox,
  SelectListItem,
  SelectPopover,
} from "@/components/select";

export function Selector({
  onChange,
  value,
}: {
  onChange: (key: number) => void;
  value: number;
}) {
  return (
    <Select
      className="w-[159px] border-none bg-bg-primary pb-4 pt-3"
      onSelectionChange={(e) => onChange(Number(e))}
      selectedKey={value}
    >
      <SelectButton className="cursor-pointer text-sm text-button-secondary-fg">Show items:&nbsp;</SelectButton>
      <SelectPopover>
        <SelectListBox>
          <SelectListItem className="text-button-secondary-fg" id={10}>10</SelectListItem>
          <SelectListItem className="text-button-secondary-fg" id={25}>25</SelectListItem>
          <SelectListItem className="text-button-secondary-fg" id={50}>50</SelectListItem>
          <SelectListItem className="text-button-secondary-fg" id={100}>100</SelectListItem>
        </SelectListBox>
      </SelectPopover>
    </Select>
  );
}
