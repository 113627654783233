import type { Row, Table } from "@tanstack/react-table";

import type { FileItem } from "../../types";

import { AssetGrid, FolderGrid, GroupHeader } from "./components";

type FilesGridViewProps = {
  table: Table<FileItem>;
};

function getGroupSelectionState(groupRow: Row<FileItem>) {
  const allSelected = groupRow.subRows.every((row) => row.getIsSelected());
  const someSelected =
    groupRow.subRows.some((row) => row.getIsSelected()) && !allSelected;

  const toggleGroupSelection = () => {
    // If all selected or some selected, deselect all. Otherwise, select all.
    if (allSelected || someSelected) {
      for (const row of groupRow.subRows) row.toggleSelected(false);
    } else {
      for (const row of groupRow.subRows) row.toggleSelected(true);
    }
  };

  return { allSelected, someSelected, toggleGroupSelection };
}

export function FilesGridView({ table }: FilesGridViewProps) {
  return (
    <div className="flex flex-col gap-md">
      {table.getGroupedRowModel().rows.map((groupRow) => {
        // Get the group name (type)
        const groupType = groupRow.getValue("type") as "file" | "folder";
        const groupTitle = groupType === "folder" ? "Folders" : "Assets";
        const { allSelected, someSelected, toggleGroupSelection } =
          getGroupSelectionState(groupRow);

        return (
          <div className="flex flex-col gap-md" key={groupRow.id}>
            <GroupHeader
              allSelected={allSelected}
              groupTitle={groupTitle}
              onToggleSelection={toggleGroupSelection}
              someSelected={someSelected}
            />

            {groupType === "folder" ? (
              <FolderGrid rows={groupRow.subRows} />
            ) : (
              <AssetGrid rows={groupRow.subRows} />
            )}
          </div>
        );
      })}
    </div>
  );
}
