import { useMutation } from "@tanstack/react-query";
import { Delta } from "quill";
import { HtmlToDelta } from "quill-delta-from-html";
import { useRef } from "react";

import { useCapturePosthogEvent } from "@/hooks/posthog/useCapturePosthogEvent";

import type { DetailedFormData } from "../schemas";

import { useCreateVideoRequestFormContext } from "../../useCreateVideoRequestForm";
import { createOutputEnhancement } from "../api/createOutputEnhancement";

const deltaConverter = new HtmlToDelta([], undefined, ["design_brief"]);

function useAIStream() {
  const capture = useCapturePosthogEvent();
  const methods = useCreateVideoRequestFormContext();
  const abortControllerRef = useRef<AbortController | null>(null);

  const { error, isError, isPending, mutate } = useMutation({
    mutationFn: async ({ data }: { data: DetailedFormData }) => {
      methods.resetField("briefCreatorDetailed.finalBriefHTML");
      methods.resetField("briefCreatorDetailed.finalBrief");
      abortControllerRef.current = new AbortController();

      const response = await createOutputEnhancement(
        data,
        abortControllerRef.current,
        methods.getValues("brand"),
      );

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      if (reader) {
        try {
          let accumulatedHTML = "";

          while (true) {
            const { done, value } = await reader.read();

            if (done) break;
            const chunk = decoder.decode(value, { stream: true });

            accumulatedHTML += chunk;

            try {
              const wrappedHTML = `<div>${accumulatedHTML}</div>`;

              const delta = deltaConverter.convert(wrappedHTML);

              methods.setValue(
                "briefCreatorDetailed.finalBriefHTML",
                accumulatedHTML,
                { shouldDirty: true },
              );

              methods.setValue("briefCreatorDetailed.finalBrief", delta, {
                shouldDirty: true,
              });
            } catch (conversionError) {
              console.error("Error converting HTML to Delta:", conversionError);
              methods.setValue(
                "briefCreatorDetailed.finalBriefHTML",
                accumulatedHTML,
                { shouldDirty: true },
              );
            }
          }
        } catch (streamError) {
          console.error("Error processing stream:", streamError);
          throw streamError;
        } finally {
          reader.releaseLock();
          capture("magic_brief_generated_completed");
        }
      }

      return response;
    },
    mutationKey: ["createOutputEnhancement", "video"],
  });

  function resetStreamedResponse() {
    methods.setValue("briefCreatorDetailed.finalBriefHTML", "");
    methods.setValue("briefCreatorDetailed.finalBrief", new Delta());
  }

  function sendPrompt(data: DetailedFormData) {
    resetStreamedResponse();
    mutate({ data });
  }

  function abortStream() {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }

  return {
    abortStream,
    error,
    isError,
    isPending,
    resetStreamedResponse,
    sendPrompt,
    streamedResponse: methods.watch("briefCreatorDetailed.finalBriefHTML"),
  };
}

export default useAIStream;
