import type {
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from "react-hook-form";

import apiClient from "@/lib/api/client";

import type { Asset, FileAsset } from "../types";

import { isFileAsset } from "../utils/uploadUtils";

export function useFileDelete<TFormValues extends FieldValues>(
  field: Path<TFormValues>,
  assets: Asset[],
  setError: (error: null | string) => void,
  methods: UseFormReturn<TFormValues>,
): {
  handleFileDelete: (attachmentId: number) => Promise<void>;
} {
  const handleFileDelete = async (attachmentId: number) => {
    setError(null);

    const fileAsset = assets.find(
      (asset): asset is FileAsset =>
        "attachmentId" in asset && asset.attachmentId === attachmentId,
    );

    if (!fileAsset || !fileAsset.id) {
      console.error(`File not found`);

      return;
    }

    try {
      const assetsWithUploadingStatus = assets.map((asset) =>
        isFileAsset(asset) && asset.attachmentId === attachmentId
          ? { ...asset, status: "uploading" as const }
          : asset,
      );

      methods.setValue(
        field,
        assetsWithUploadingStatus as PathValue<TFormValues, Path<TFormValues>>,
      );

      if (fileAsset.attachmentId) {
        await apiClient
          .delete(`/attachments/${encodeURIComponent(fileAsset.attachmentId)}`)
          .res();
      }

      const currentAssets = methods.getValues(field);
      const filteredAssets = currentAssets.filter(
        (asset: PathValue<TFormValues, Path<TFormValues>>) =>
          !isFileAsset(asset) || asset.attachmentId !== attachmentId,
      );

      methods.setValue(
        field,
        filteredAssets as PathValue<TFormValues, Path<TFormValues>>,
      );
    } catch (error) {
      console.error(`Error deleting file ${attachmentId}:`, error);

      const updatedFilesWithError = assets.map((asset) =>
        isFileAsset(asset) && asset.attachmentId === attachmentId
          ? { ...asset, status: "error" as const }
          : asset,
      );

      methods.setValue(
        field,
        updatedFilesWithError as PathValue<TFormValues, Path<TFormValues>>,
      );
      setError(
        `Failed to delete file ${fileAsset.file.name}. Please try again.`,
      );
    }
  };

  return { handleFileDelete };
}
