import type { DuplicateRequestResponse } from "@nlc/shared-types";
import type { DefaultValues, UseFormReset } from "react-hook-form";

import { Delta } from "quill";

import type { CreateWebsiteDesignRequestFormData } from "../components/useCreateWebsiteDesignRequestForm";

import {
  convertDesignBriefToDeltaForDuplicate,
  mapAttachmentsByCategory,
  useDuplicateRequest,
} from "../../shared/useDuplicateRequest";

function mapDuplicateResponseToFormData(
  data: DuplicateRequestResponse,
): DefaultValues<CreateWebsiteDesignRequestFormData> {
  const briefCreatorType = data.briefText ? "detailed" : "single";

  return {
    assets: mapAttachmentsByCategory(data.attachments, "asset"),
    brandId: data.brandId || undefined,
    briefCreatorDetailed: {
      additionalGuidelines:
        data.magicalBriefCreator?.additionalGuidelines || "",
      brandGuidelines: data.magicalBriefCreator?.brandGuidelines || "",
      creativeGoal: data.magicalBriefCreator?.creativeGoals || "",
      finalBrief: data.briefText
        ? convertDesignBriefToDeltaForDuplicate(data.briefText)
        : new Delta(),
      finalBriefHTML: data.briefText || "",
      scope: data.magicalBriefCreator?.scope || "",
      textsForDesign: data.magicalBriefCreator?.textForDesign || "",
    },
    briefCreatorSingle: {
      requestDescription: data.description
        ? convertDesignBriefToDeltaForDuplicate(data.description)
        : new Delta(),
      requestDescriptionHTML: data.description || "",
      textsForDesign: data.textForDesign
        ? convertDesignBriefToDeltaForDuplicate(data.textForDesign)
        : new Delta(),
      textsForDesignHTML: data.textForDesign || "",
    },
    briefCreatorType,
    collaboratorsIds: data.collaboratorIds?.map(String) || [],
    creativeDirection: data.creativeDirection || undefined,
    designComplexityId: data.complexityId || undefined,
    designTypeId: undefined,
    inspirations: mapAttachmentsByCategory(data.attachments, "example"),
    preferredDesignersIds: data.preferredDesignerIds?.map(String) || [],
    preferredFileTypes: data.preferredFileType || "",
    requestName: data.name || "",
    sizesNeeded: data.sizesNeeded || "",
  };
}

interface UseDuplicateWebsiteDesignRequestProps {
  reset: UseFormReset<CreateWebsiteDesignRequestFormData>;
}

export function useDuplicateWebsiteDesignRequest({
  reset,
}: UseDuplicateWebsiteDesignRequestProps) {
  return useDuplicateRequest<CreateWebsiteDesignRequestFormData>({
    mapDuplicateResponseToFormData,
    reset,
  });
}
