import type { DuplicateRequestResponse } from "@nlc/shared-types";
import type { Delta } from "quill";
import type { DefaultValues, FieldValues, UseFormReset } from "react-hook-form";

import { useMutation } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { HtmlToDelta } from "quill-delta-from-html";
import { useEffect } from "react";

import { toast } from "@/components/toast/toast-queue";
import { useUser } from "@/hooks/useUser";
import apiClient from "@/lib/api/client";

type Attachment = NonNullable<DuplicateRequestResponse["attachments"]>[number];
type FileAttachment = {
  attachmentId: number;
  awsDownloadUrl: null | string | undefined;
  file: File;
  id: string;
  status: "success";
};
type LinkAttachment = { attachmentId: number; id: string; url: string };

interface UseDuplicateRequestProps<T extends FieldValues> {
  mapDuplicateResponseToFormData: (
    data: DuplicateRequestResponse,
  ) => DefaultValues<T>;
  reset: UseFormReset<T>;
}

function createFileAttachment(att: Attachment): FileAttachment {
  return {
    attachmentId: att.id,
    awsDownloadUrl: att.url,
    file: new File([], att.name),
    id: att.id.toString(),
    status: "success",
  };
}

function createLinkAttachment(att: Attachment): LinkAttachment {
  return {
    attachmentId: att.id,
    id: att.id.toString(),
    url: att.url,
  };
}

export function mapAttachmentsByCategory(
  attachments: DuplicateRequestResponse["attachments"],
  category: string,
) {
  if (!attachments) return [];

  return attachments
    .filter((att) => att.category === category)
    .map((att) =>
      att.type === "link"
        ? createLinkAttachment(att)
        : createFileAttachment(att),
    );
}

export const deltaConverter = new HtmlToDelta([], undefined, ["design_brief"]);

/**
 * Converts a design brief HTML string to a Quill Delta object
 * with proper newline handling
 *
 * @param htmlContent The HTML string to convert
 * @returns A Quill Delta object
 */
export function convertDesignBriefToDeltaForDuplicate(
  htmlContent: string,
): Delta {
  /* eslint-disable unicorn/prefer-string-replace-all */
  const cleanedHtml = htmlContent
    .replace(/<design_brief>\s*/g, "")
    .replace(/\s*<\/design_brief>/g, "");

  const processedHtml = cleanedHtml
    .replace(/<\/p>\s*<p><strong>/g, "</p><p><br></p><p><strong>")
    .replace(/<strong>([^<]+):<\/strong>/g, "<strong>$1:</strong><br>")
    .replace(/<\/ul>\s*<p>/g, "</ul><p><br></p><p>")
    .replace(/<\/li>\s*<li>/g, "</li>\n<li>");

  const converter = new HtmlToDelta();

  return converter.convert(processedHtml);
}

export function useDuplicateRequest<T extends FieldValues>({
  mapDuplicateResponseToFormData,
  reset,
}: UseDuplicateRequestProps<T>) {
  const navigate = useNavigate({ from: "/requests/create" });
  const { data: user } = useUser();
  const search = useSearch({ from: "/_auth/_app-shell/requests/create" });

  const {
    duplicate_count: duplicateCount,
    duplicate_from: duplicateFrom,
    include_attachments: includeAttachments,
    include_collaborators: includeCollaborators,
    include_leadership_notes: includeLeadershipNotes,
  } = search;

  function handleSuccess(data: DuplicateRequestResponse) {
    // Handle multiple duplicates
    if (duplicateCount && duplicateCount > 1) {
      const isPrivilegedUser =
        user &&
        ["admin", "collaborator", "owner"].includes(user.user.roleName || "");

      window.location.href = isPrivilegedUser
        ? "/requests?queued=1"
        : "/requests";

      return;
    }

    // Handle single duplicate
    navigate({
      search: (prev) => ({ ...prev, request_id: data.id }),
    });

    const mappedRequest = mapDuplicateResponseToFormData(data);

    reset(mappedRequest, {
      keepDefaultValues: true,
      keepDirty: false,
      keepErrors: false,
      keepIsSubmitted: false,
      keepIsValid: false,
      keepSubmitCount: false,
      keepTouched: false,
    });
  }

  const { isError, isPending, isSuccess, mutate } = useMutation({
    mutationFn: () =>
      apiClient
        .url(`/requests/${duplicateFrom}/duplicate`)
        .post({
          includeAttachments: includeAttachments === 1,
          includeCollaborators: includeCollaborators === 1,
          includeInternalNotes: includeLeadershipNotes === 1,
          numberOfDuplicates: duplicateCount,
        })
        .res((response) => {
          // Handle the case where the response is a JSON object (single duplicate)
          if (response.status === 200) {
            return response.json();
          }

          // Handle the case where the response is a void response (multiple duplicates)
          return {
            id: duplicateFrom,
            status: response.status,
            success: response.ok,
          };
        }),
    mutationKey: ["duplicate-request", duplicateFrom],
    onError: (error) => {
      console.error(error);
      toast.add({
        description: error.message,
        title: "Error duplicating request",
        type: "error",
      });
    },
    onSuccess: handleSuccess,
    retry: 1,
  });

  useEffect(() => {
    if (duplicateFrom && !isSuccess && !isPending && !isError) {
      mutate();
    }
  }, [duplicateFrom, isSuccess, isPending, mutate, isError]);

  return {
    isInDuplicateMode: duplicateFrom !== undefined,
    isPending,
  };
}
