import { useUser } from "@/hooks/useUser";

import { CollaboratorsSelect } from "./CollaboratorsSelect";
import { ComplexitySelect } from "./ComplexitySelect";
import { PreferredDesignersSelect } from "./PreferredDesignersSelect";

export function TechnicalDetailsSection() {
  const { data: user } = useUser();

  const showComplexityField = [
    "account-manager",
    "super-admin",
    "team-leader",
  ].includes(user?.user.roleName || "");

  return (
    <>
      <div className="rounded-xl bg-bg-secondary p-3xl">
        <div className="grid grid-cols-12 gap-x-3xl gap-y-lg">
          <span className="col-span-12 text-lg font-semibold text-text-primary">
            Technical Details
          </span>

          <PreferredDesignersSelect
            className={showComplexityField ? "col-span-6" : "col-span-12"}
          />
          {showComplexityField && <ComplexitySelect />}
        </div>
      </div>
      <div className="w-1/2 rounded-xl bg-bg-secondary p-3xl">
        <CollaboratorsSelect />
      </div>
    </>
  );
}
