import type { SubmitRequestUnion } from "@nlc/shared-types";
import type { UseFormReturn } from "react-hook-form";

import { RequestTypeEnum, submitRequestUnionZod } from "@nlc/shared-types";
import { useMutation } from "@tanstack/react-query";

import { toast } from "@/components/toast/toast-queue";
import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";
import { useUser } from "@/hooks/useUser";
import authenticatedApi from "@/lib/api/client";

import type { CreateWebsiteImplementationRequestFormData } from "../components/useCreateWebsiteImplementationRequestForm";

import { getValidAttachmentIds } from "../../shared/utils";

type UseSubmitMutationProps = {
  formMethods: UseFormReturn<CreateWebsiteImplementationRequestFormData>;
  latestRequestId: { requestId: number };
};

export function useSubmitMutation({
  formMethods,
  latestRequestId,
}: UseSubmitMutationProps) {
  const { data: user } = useUser();
  const resetFormDirty = useIsRequestFormDirty((state) => state.reset);

  return useMutation({
    mutationFn: async (
      formData: CreateWebsiteImplementationRequestFormData,
    ) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const body: SubmitRequestUnion = {
        attachmentIds: [
          ...getValidAttachmentIds(formData.assets),
          ...getValidAttachmentIds(formData.inspirations),
        ],
        brandId: formData.brandId,
        collaboratorIds: formData.collaboratorsIds.map(Number),
        complexityId: formData.designComplexityId,
        description: formData.requestDescriptionHTML,
        designTypeId: formData.designTypeId,
        name: formData.requestName,
        preferredDesignerIds: formData.preferredDesignersIds.map(Number),
        requestType: RequestTypeEnum.WEBSITE_IMPLEMENTATION,
      };
      const parsed = submitRequestUnionZod.parse(body);

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}/submit`)
        .post(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error: { message: string; statusCode: number }) => {
      console.error("Failed to submit request:", error);

      if (error.message.includes("Request name already exists")) {
        formMethods.setError("requestName", {
          message: "Request name already exists",
        });
        formMethods.setFocus("requestName");
      }

      if (error.statusCode === 400) {
        const errorMessage = error.message;

        if (
          [
            "No credits available",
            "Subscription ended",
            "Subscription is cancelled",
          ].includes(errorMessage)
        ) {
          window.location.href = "/requests";

          return;
        }
      }

      toast.add(
        {
          description: error.message,
          title: "Error submitting request",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onMutate: () => {
      formMethods.reset(formMethods.getValues());
      resetFormDirty();
    },
    onSuccess: () => {
      window.location.href =
        user &&
        ["admin", "collaborator", "owner"].includes(user.user.roleName || "")
          ? "/requests?queued=1"
          : "/requests";
    },
  });
}
