import type { DuplicateRequestResponse } from "@nlc/shared-types";
import type { DefaultValues, UseFormReset } from "react-hook-form";

import { Delta } from "quill";

import type { CreateGraphicRequestFormData } from "../components/useCreateGraphicRequestForm";

import {
  convertDesignBriefToDeltaForDuplicate,
  mapAttachmentsByCategory,
  useDuplicateRequest,
} from "../../shared/useDuplicateRequest";

function mapDuplicateResponseToFormData(
  data: DuplicateRequestResponse,
): DefaultValues<CreateGraphicRequestFormData> {
  const briefCreatorType = data.briefText ? "detailed" : "single";

  return {
    assets: mapAttachmentsByCategory(data.attachments, "asset"),
    brand: data.brandId || undefined,
    briefCreatorDetailed: {
      additionalGuidelines:
        data.magicalBriefCreator?.additionalGuidelines || "",
      brandGuidelines: data.magicalBriefCreator?.brandGuidelines || "",
      creativeGoal: data.magicalBriefCreator?.creativeGoals || "",
      finalBrief: data.briefText
        ? convertDesignBriefToDeltaForDuplicate(data.briefText)
        : new Delta(),
      finalBriefHTML: data.briefText || "",
      keyMessageAndCta: data.magicalBriefCreator?.keyMessageAndCta || "",
      scope: data.magicalBriefCreator?.scope || "",
    },
    briefCreatorSingle: {
      requestDescription: data.description
        ? convertDesignBriefToDeltaForDuplicate(data.description)
        : new Delta(),
      requestDescriptionHTML: data.description || "",
      textsForDesign: data.textForDesign
        ? convertDesignBriefToDeltaForDuplicate(data.textForDesign)
        : new Delta(),
      textsForDesignHTML: data.textForDesign || "",
    },
    briefCreatorType,
    collaborators: data.collaboratorIds?.map(String) || [],
    creativeDirection: data.creativeDirection || undefined,
    designComplexity: data.complexityId || undefined,
    designType: undefined,
    fileTypes: data.preferredFileType || "",
    inspirations: mapAttachmentsByCategory(data.attachments, "example"),
    preferredDesigners: data.preferredDesignerIds?.map(String) || [],
    requestName: data.name || "",
    sizes: data.sizesNeeded || "",
  };
}

interface UseDuplicateGraphicRequestProps {
  reset: UseFormReset<CreateGraphicRequestFormData>;
}

export function useDuplicateGraphicRequest({
  reset,
}: UseDuplicateGraphicRequestProps) {
  return useDuplicateRequest<CreateGraphicRequestFormData>({
    mapDuplicateResponseToFormData,
    reset,
  });
}
