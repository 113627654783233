import {
  type Cell,
  flexRender,
  type Header,
  type Row,
} from "@tanstack/react-table";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import prettyBytes from "pretty-bytes";
import { twMerge } from "tailwind-merge";

import AssetFolder from "@/features/my-files/assets/asset-folder.svg?react";

import type { FileItem } from "../../types";

import { FILE_ICONS } from "../../assets/file-icons";
import { ActionMenu } from "../action-menu/action-menu";

const getColumnStyles = (columnId: string, size?: number) => ({
  flex: columnId === "name" ? "1" : "none",
  minWidth: columnId === "name" ? "200px" : undefined,
  width: columnId === "name" ? "auto" : size ? `${size}px` : undefined,
});

function SortingIndicator({ header }: { header: Header<FileItem, unknown> }) {
  if (!header.column.getCanSort()) return null;

  const sortDirection = header.column.getIsSorted() as string;

  if (!sortDirection) return null;

  return (
    <span>
      {sortDirection === "asc" ? (
        <ArrowDownIcon className="text-fg-tertiary" size={16} />
      ) : (
        <ArrowUpIcon className="text-fg-tertiary" size={16} />
      )}
    </span>
  );
}

function FileIcon({ fileItem }: { fileItem: FileItem }) {
  if (
    fileItem.type === "file" &&
    "extension" in fileItem &&
    fileItem.extension in FILE_ICONS
  ) {
    const IconComponent =
      FILE_ICONS[fileItem.extension as keyof typeof FILE_ICONS];

    return <IconComponent className="size-3xl shrink-0" />;
  }

  return <AssetFolder className="shrink-0" />;
}

function CellContent({
  cell,
  row,
}: {
  cell: Cell<FileItem, unknown>;
  row: Row<FileItem>;
}) {
  if (cell.column.id === "name") {
    return (
      <div className="flex items-center gap-lg">
        <FileIcon fileItem={row.original} />
        <span className="truncate text-sm text-text-primary">
          {row.original.name}
        </span>
      </div>
    );
  }

  if (cell.column.id === "size") {
    return (
      <span className="text-sm text-text-secondary">
        {prettyBytes(row.original.size)}
      </span>
    );
  }

  return (
    <span className="text-sm text-text-secondary">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </span>
  );
}

export function TableRow({ row }: { row: Row<FileItem> }) {
  return (
    <tr
      className={twMerge([
        "border-b border-border-secondary",
        row.getIsSelected() && "bg-bg-brand-primary",
      ])}
      key={row.id}
    >
      {row.getVisibleCells().map((cell) => (
        <td
          className="px-xl py-lg"
          key={cell.id}
          style={getColumnStyles(cell.column.id, cell.column.getSize())}
        >
          <CellContent cell={cell} row={row} />
        </td>
      ))}
      <td className="px-xl py-lg" style={{ width: "40px" }}>
        <ActionMenu />
      </td>
    </tr>
  );
}

export function TableHeader({ header }: { header: Header<FileItem, unknown> }) {
  return (
    <th
      className="px-xl py-md text-left"
      key={header.id}
      onClick={header.column.getToggleSortingHandler()}
      style={{
        cursor: header.column.getCanSort() ? "pointer" : "default",
        ...getColumnStyles(header.id, header.column.getSize()),
      }}
    >
      <div className="flex items-center gap-1">
        {!header.isPlaceholder &&
          flexRender(header.column.columnDef.header, header.getContext())}
        <SortingIndicator header={header} />
      </div>
    </th>
  );
}
