import type {
  LinkProps,
  BreadcrumbProps as RACBreadcrumbProps,
  BreadcrumbsProps as RACBreadcrumbsProps,
} from "react-aria-components";

import {
  composeRenderProps,
  Breadcrumb as RACBreadcrumb,
  Breadcrumbs as RACBreadcrumbs,
} from "react-aria-components";
import { Link as RACLink } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { ChevronRightIcon } from "../icons";

export function Breadcrumbs<T extends object>({
  className,
  ...props
}: RACBreadcrumbsProps<T>) {
  return (
    <RACBreadcrumbs {...props} className={twMerge("flex gap-1", className)} />
  );
}

type BreadcrumbProps = LinkProps & RACBreadcrumbProps;

export function Breadcrumb(props: BreadcrumbProps) {
  return (
    <RACBreadcrumb
      {...props}
      className={composeRenderProps(
        props.className as RACBreadcrumbProps["className"],
        (className) => twMerge("flex items-center gap-1", className),
      )}
    >
      <RACLink
        {...props}
        className={({ isCurrent, isHovered }) =>
          twMerge(
            "px-md py-xs rounded-sm text-breadcrumb-fg gap-[8px] text-sm font-medium",
            isCurrent && "font-semibold text-breadcrumb-fg ",
            isHovered && "bg-bg-secondary",
          )
        }
      />
      {props.href && <ChevronRightIcon className="size-4 text-fg-senary" />}
    </RACBreadcrumb>
  );
}
