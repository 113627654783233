import type { QueryClient } from "@tanstack/react-query";

import { createFileRoute } from "@tanstack/react-router";
import { Suspense } from "react";
import { z } from "zod";

import { ErrorBoundary } from "@/components/error-boundary";
import { PickType } from "@/features/request/pick-type";
import { redirectOnInvalidSubscription } from "@/features/subscription/redirect-on-invalid-subscription";

const pickTypeSearchSchema = z.object({
  category: z.enum(["graphic", "video", "webui"]).default("graphic"),
  ["design-type"]: z.coerce.number().optional(),
  ["project-id"]: z.coerce.number().optional(),
  subcategory: z.coerce.number().optional(),
});

export const Route = createFileRoute("/_auth/_app-shell/requests/pick-type")({
  component: () => (
    <Suspense fallback={<PickTypeWrapper />}>
      <PickTypeWrapper>
        <ErrorBoundary>
          <PickType />
        </ErrorBoundary>
      </PickTypeWrapper>
    </Suspense>
  ),
  loader: async ({
    context: { queryClient },
    deps: { projectId },
  }: {
    context: { queryClient: QueryClient };
    deps: { projectId?: number };
  }) => {
    await redirectOnInvalidSubscription({
      projectId,
      queryClient,
    });
  },
  loaderDeps: ({ search: { "project-id": projectId } }) => ({
    projectId,
  }),
  validateSearch: pickTypeSearchSchema,
});

function PickTypeWrapper({ children }: { children?: React.ReactNode }) {
  return (
    <div className="w-full bg-bg-tertiary p-4 @container">
      <div className="mx-auto h-[calc(100vh-104px)] w-full max-w-[1500px] rounded-xl border border-border-secondary bg-bg-primary">
        {children}
      </div>
    </div>
  );
}
