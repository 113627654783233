import { queryOptions } from "@tanstack/react-query";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

import apiClient from "@/lib/api/client";

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ context, location }) => {
    const user = await context.queryClient.ensureQueryData(
      queryOptions({
        queryFn: () => apiClient.url("/users/me").get().json(),
        queryKey: ["user"],
        staleTime: Infinity,
      }),
    );

    if (!user) {
      throw redirect({ search: { redirect: location.href }, to: "/login" });
    }
  },
  component: () => <Outlet />,
});
