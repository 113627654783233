export const COLUMN_WIDTHS = {
    ACCOUNT_MANAGER: 200,
    ACTIONS: 135,
    CREATED_ON: 135,
    CUSTOMER_NAME_MIN_WIDTH: 200,
    DESIGNATION: 180,

    EMAIL: 300,
    EMPLOYEES_NAME_MIN_WIDTH: 170,

    GOOD_RATINGS: 140,
    IMPERSONATE: 150,
    MAX_WIDTH: 3000,
    MIN_WIDTH: 200,
    NAME: 250,

    NEUTRAL_RATINGS: 150,

    POOR_RATINGS: 140,
    ROLE_DISPLAY_NAME: 130,
}; 