import type {
  ListBoxItemProps,
  SectionProps as RACSectionProps,
  SelectProps as RACSelectProps,
} from "react-aria-components";

import { CheckIcon } from "lucide-react";
import {
  Button,
  Collection,
  composeRenderProps,
  Header,
  ListBoxItem as RACListBoxItem,
  Select as RACSelect,
  Section,
  SelectValue,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { ListBoxProps } from "../list-box";
import type { PopoverProps } from "../popover";

import { ListBox } from "../list-box";
import { Popover } from "../popover";
import { Small } from "../text";
import {
  composeTailwindRenderProps,
  focusRingStyle,
  inputFieldStyle,
  selectBoxIndicator,
} from "../utils";

export function Select<T extends object>(props: RACSelectProps<T>) {
  return (
    <RACSelect
      {...props}
      className={composeTailwindRenderProps(props.className, [
        "w-full text-text-primary",
        ...inputFieldStyle,
      ])}
      data-ui="select"
    />
  );
}

export function SelectButton(props: {
  children?: React.ReactNode;
  className?: string;
  plain?: boolean;
}) {
  return (
    <Button
      className={composeRenderProps(
        props.className,
        (className, { isFocusVisible }) =>
          twMerge(
            "bg-bg-primary relative flex w-full cursor-default items-center rounded-md border-border-primary border outline-none transition",
            "px-[14px] py-[10px]",
            "group-invalid:border-border-error",
            "group:disabled:cursor-not-allowed",
            "text-md",
            "[&>*:has(+[data-ui=select-value])>svg]:size-4",
            "[&>*:has(+[data-ui=select-value])>svg]:text-muted",
            isFocusVisible ? focusRingStyle : "",
            "ring-offset-0",
            selectBoxIndicator,
            className,
          ),
      )}
      data-ui="control"
    >
      {!!props.children && (
        <span className="flex items-center gap-x-2">{props.children}</span>
      )}
      <SelectValue
        className={twMerge([
          "flex-1 truncate  data-[placeholder]:text-start data-[placeholder]:text-text-placeholder data-[placeholder]:font-normal",
          "max-w-[calc(100%-16px)]",
          // Selected Item style
          "[&>[data-ui=item]]:flex",
          "[&>[data-ui=item]]:items-center",
          "[&>[data-ui=item]]:gap-x-2",
          "[&>[data-ui=item]_[data-ui=description]]:sr-only",
          "[&>[data-ui=item]_[data-ui=icon]:not([class*=size-])]:size-5",
          "[&>[data-ui=item]_[role=img]]:size-6",
          "sm:[&>[data-ui=item]_[data-ui=icon]:not([class*=size-])]:size-4",
          "sm:[&>[data-ui=item]_[role=img]]:size-5",
        ])}
        data-ui="select-value"
      />
    </Button>
  );
}

export function SelectPopover({
  className,
  placement = "bottom",
  ...props
}: PopoverProps) {
  return (
    <Popover
      offset={4}
      {...props}
      className={composeTailwindRenderProps(className, ["w-[--trigger-width]"])}
      placement={placement}
    />
  );
}

export function SelectListBox<T extends object>(props: ListBoxProps<T>) {
  return (
    <ListBox
      {...props}
      className={composeTailwindRenderProps(props.className, [
        "flex max-h-[inherit] flex-col overflow-auto py-xs px-sm outline-none ",
      ])}
    />
  );
}

export interface SectionProps<T> extends RACSectionProps<T> {
  title?: string;
}

export function SelectSection<T extends object>(props: SectionProps<T>) {
  return (
    <Section className={twMerge(props.className)}>
      <Header
        className={twMerge(
          "text-text-tertiary font-semibold",
          "py-[10px] pr-[10px] pl-md",
        )}
      >
        {props.title}
      </Header>
      <Collection items={props.items}>{props.children}</Collection>
    </Section>
  );
}

export function SelectListItem({
  destructive,
  ...props
}: { destructive?: true } & ListBoxItemProps) {
  const textValue =
    props.textValue ||
    (typeof props.children === "string" ? props.children : undefined);

  return (
    <RACListBoxItem
      {...props}
      className={composeRenderProps(
        props.className,
        (className, { isDisabled, isFocused, isSelected }) =>
          twMerge([
            "group flex justify-between cursor-default select-none items-center gap-x-2 rounded-md outline-none",
            "py-[10px] pr-[10px] pl-md",
            "text-md font-normal text-text-primary",
            isDisabled && "opacity-50",
            isSelected && "bg-bg-secondary",
            isFocused && "bg-bg-secondary",
            destructive && "text-text-error",
            "section > & pl-xl", // Add extra padding left when wrapped with <section>
            className,
          ]),
      )}
      textValue={textValue}
    >
      {composeRenderProps(props.children, (children, { isSelected }) => (
        <>
          <div data-ui="item">{children}</div>

          <CheckIcon
            className={twMerge(
              "mt-1 size-4 shrink-0 self-start [[data-ui=select-value]_&]:hidden text-fg-brand-primary",
              isSelected ? "visible" : "invisible",
            )}
          />
        </>
      ))}
    </RACListBoxItem>
  );
}

export function SelectListItemLabel({
  className,
  ...props
}: JSX.IntrinsicElements["span"]) {
  return (
    <span
      className={twMerge("mb-0 truncate", className)}
      data-ui="label"
      slot="label"
      {...props}
    />
  );
}

export function SelectListItemDescription({
  className,
  ...props
}: JSX.IntrinsicElements["span"]) {
  return (
    <Small
      className={className}
      data-ui="description"
      slot="description"
      {...props}
    />
  );
}
