import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

import { routeTree } from "@/app/routeTree.gen";
import { FullPageLoader } from "@/components/fullpage-loader";
import { GlobalToastRegion } from "@/components/toast/toast-region";
import { env } from "@/config/env";
import { initIntercom, updateIntercomRoute } from "@/lib/intercom";

const queryClient = new QueryClient();

// Set up a Router instance
const router = createRouter({
  context: {
    queryClient,
  },
  defaultPendingComponent: () => <FullPageLoader />,
  defaultPreload: "intent",
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  // Since we're using React Query, we don't want loader calls to ever be stale
  routeTree,
});

// Register things for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  environment: env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  // Capture 100% of sessions where errors occur
  replaysOnErrorSampleRate: 1,
  // Capture 10% of all sessions for performance monitoring
  replaysSessionSampleRate: 0.1,
});

const posthogOptions = {
  api_host: env.VITE_PUBLIC_POSTHOG_HOST,
  capture_pageview: false,
  ui_host: "https://us.posthog.com",
};

function AppRouter() {
  useEffect(() => {
    // Initialize Intercom
    initIntercom();

    // Subscribe to route changes
    router.subscribe("onBeforeLoad", () => {
      updateIntercomRoute();
    });
  }, []);

  return (
    <PostHogProvider
      apiKey={env.VITE_PUBLIC_POSTHOG_KEY}
      options={posthogOptions}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools buttonPosition="bottom-right" />
        <GlobalToastRegion aria-label="notification" />
      </QueryClientProvider>
    </PostHogProvider>
  );
}

export function App() {
  return <AppRouter />;
}
