import type { ReactNode } from "react";

import { Button } from "@/components/button";

import FaceFrownIcon from "./face-frown.svg?react";

type PaywallLayoutProps = {
  actionText: string;
  actionUrl: string;
  message: ReactNode;
};

export function PaywallLayout({
  actionText,
  actionUrl,
  message,
}: PaywallLayoutProps) {
  return (
    <div className="w-full bg-bg-tertiary p-4 @container">
      <div className="mx-auto h-[calc(100vh-104px)] w-full max-w-[1500px] rounded-xl border border-border-secondary bg-bg-primary">
        <div className="flex h-full flex-col items-center justify-center gap-3xl">
          <FaceFrownIcon />
          <span className="text-center text-[30px] font-semibold leading-[38px] text-text-primary">
            {message}
          </span>
          <Button asChild size="lg">
            <a href={actionUrl}>{actionText}</a>
          </Button>
        </div>
      </div>
    </div>
  );
}
