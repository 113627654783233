import { HelpCircle } from "lucide-react";
import { Button, TooltipTrigger } from "react-aria-components";

import { Label } from "@/components/field";
import { Tooltip } from "@/components/tooltip";
import { ControlledQuillEditor } from "@/components/ui/controlled/ControlledQuillEditor";

import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";

export function SingleInputForm() {
  const methods = useCreateVideoRequestFormContext();

  return (
    <>
      <div />
      <div className="h-[530px]">
        <div className="mb-xs flex items-center justify-between">
          <Label requiredHint>📄 Request Description</Label>
          <TooltipTrigger delay={0}>
            <Button
              aria-label="Show tooltip"
              className="cursor-default outline-none"
            >
              <HelpCircle className="size-xl text-fg-quinary transition-all duration-300 hover:text-fg-quinary" />
            </Button>
            <Tooltip offset={0}>
              Describe the overall purpose and specific requirements of your
              design request. Include any critical project details that will
              guide the creation process.
            </Tooltip>
          </TooltipTrigger>
        </div>

        <ControlledQuillEditor
          control={methods.control}
          name="briefCreatorSingle.requestDescription"
          onChangeHTML={(html) => {
            methods.setValue("briefCreatorSingle.requestDescriptionHTML", html);
          }}
          placeholder="Tell us about your request. The more specific you are, the better the end result(s) will be. "
        />
      </div>

      <div className="h-[530px]">
        <div className="mb-xs flex items-center justify-between">
          <Label requiredHint>💬 Text for design</Label>
          <TooltipTrigger delay={0}>
            <Button
              aria-label="Show tooltip"
              className="cursor-default outline-none"
            >
              <HelpCircle className="size-xl text-fg-quinary transition-all duration-300 hover:text-fg-quinary" />
            </Button>
            <Tooltip offset={0}>
              Enter the exact text, key messages, and calls to action that
              should be featured on the design. Specify any slogans or phrases
              to ensure they align with your communication goals.
            </Tooltip>
          </TooltipTrigger>
        </div>

        <ControlledQuillEditor
          control={methods.control}
          name="briefCreatorSingle.textsForDesign"
          onChangeHTML={(html) => {
            methods.setValue("briefCreatorSingle.textsForDesignHTML", html);
          }}
          placeholder="Tell us what text should be displayed on your design."
        />
      </div>
    </>
  );
}
