import type {
  GroupProps,
  Key,
  ListBoxItemProps,
  ComboBoxProps as RACComboBoxProps,
} from "react-aria-components";
import type { ListData } from "react-stately";

import React, { useState } from "react";
import { useFilter } from "react-aria";
import {
  ComboBox,
  composeRenderProps,
  Group,
  LabelContext,
} from "react-aria-components";
import { useListData } from "react-stately";
import { twMerge } from "tailwind-merge";

import { Button } from "../button";
import {
  DescriptionContext,
  DescriptionProvider,
  Input,
  LabeledGroup,
} from "../field";
import { ListBox, ListBoxItem } from "../list-box";
import { Popover } from "../popover";
import { TagGroup, TagList } from "../tag-group";
import { composeTailwindRenderProps, inputFieldStyle } from "../utils";

export interface MultiSelectProps<T extends object>
  extends Omit<
    RACComboBoxProps<T>,
    | "allowsEmptyCollection"
    | "children"
    | "className"
    | "inputValue"
    | "onInputChange"
    | "onSelectionChange"
    | "selectedKey"
    | "validate"
    | "value"
  > {
  children: ((item: T) => React.ReactNode) | React.ReactNode;
  className?: string;
  items: Array<T>;
  onItemAdd?: (key: Key) => void;
  onItemRemove?: (key: Key) => void;
  placeholder?: string;
  renderEmptyState: (inputValue: string) => React.ReactNode;
  selectedList: ListData<T>;
  tag: (item: T) => React.ReactNode;
}

export function MultiSelectField({
  children,
  className,
}: { children: React.ReactNode } & GroupProps) {
  return (
    <LabeledGroup className={className as string}>
      <Group className={composeTailwindRenderProps(className, inputFieldStyle)}>
        <DescriptionProvider>{children}</DescriptionProvider>
      </Group>
    </LabeledGroup>
  );
}

export function MultiSelect<
  T extends {
    id: Key;
    textValue: string;
  },
>({
  children,
  className,
  items,
  name,
  onItemAdd,
  onItemRemove,
  placeholder,
  renderEmptyState,
  selectedList,
  ...props
}: MultiSelectProps<T>) {
  const { contains } = useFilter({ sensitivity: "base" });

  const selectedKeys = selectedList.items.map((i) => i.id);

  const filter = React.useCallback(
    (item: T, filterText: string) =>
      !selectedKeys.includes(item.id) && contains(item.textValue, filterText),
    [contains, selectedKeys],
  );

  const availableList = useListData({
    filter,
    initialItems: items,
  });

  const [fieldState, setFieldState] = useState<{
    inputValue: string;
    selectedKey: Key | null;
  }>({
    inputValue: "",
    selectedKey: null,
  });

  const onRemove = React.useCallback(
    (keys: Set<Key>) => {
      const key = keys.values().next().value;

      selectedList.remove(key);
      setFieldState({
        inputValue: "",
        selectedKey: null,
      });
      onItemRemove?.(key);
    },
    [selectedList, onItemRemove],
  );

  const onSelectionChange = (id: Key | null) => {
    if (!id) {
      return;
    }

    const item = availableList.getItem(id);

    if (!item) {
      return;
    }

    if (!selectedKeys.includes(id)) {
      selectedList.append(item);
      setFieldState({
        inputValue: "",
        selectedKey: id,
      });
      onItemAdd?.(id);
    }

    availableList.setFilterText("");
  };

  const onInputChange = (value: string) => {
    setFieldState((prevState) => ({
      inputValue: value,
      selectedKey: value === "" ? null : prevState.selectedKey,
    }));

    availableList.setFilterText(value);
  };

  const deleteLast = React.useCallback(() => {
    if (selectedList.items.length === 0) {
      return;
    }

    const lastKey = selectedList.items.at(-1);

    if (lastKey !== null && lastKey !== undefined) {
      selectedList.remove(lastKey.id);
      onItemRemove?.(lastKey.id);
    }

    setFieldState({
      inputValue: "",
      selectedKey: null,
    });
  }, [selectedList, onItemRemove]);

  const onKeyDownCapture = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" && fieldState.inputValue === "") {
        deleteLast();
      }
    },
    [deleteLast, fieldState.inputValue],
  );

  const tagGroupId = React.useId();
  const triggerRef = React.useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    const trigger = triggerRef.current;

    if (!trigger) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setWidth(entry.target.clientWidth);
      }
    });

    observer.observe(trigger);

    return () => {
      observer.unobserve(trigger);
    };
  }, [triggerRef]);

  const triggerButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const labelContext = (React.useContext(LabelContext) ?? {}) as {
    id?: string;
  };
  const descriptionContext = React.useContext(DescriptionContext);

  return (
    <>
      <div
        className={twMerge(
          "relative bg-bg-primary",
          "pe-2",
          "flex min-h-9 w-full flex-row flex-wrap items-center rounded-md",
          "border border-border-primary has-[input[data-focused=true]]:border-border-brand",
          "has-[input[data-invalid=true][data-focused=true]]:border-border-brand has-[input[data-invalid=true]]:border-red-400",
          "has-[input[data-focused=true]]:ring-4 has-[input[data-focused=true]]:ring-border-brand/25",
          "pl-[14px] pr-[25px] py-[10px] gap-[4px]",
          className,
        )}
        data-ui="control"
        ref={triggerRef}
      >
        <TagGroup
          aria-labelledby={labelContext.id}
          className="contents"
          id={tagGroupId}
          onRemove={onRemove}
        >
          <TagList
            className={twMerge("outline-none")}
            items={selectedList.items}
          >
            {props.tag}
          </TagList>
        </TagGroup>
        <ComboBox
          {...props}
          allowsEmptyCollection
          aria-labelledby={labelContext.id}
          className={twMerge("group flex flex-1", className)}
          inputValue={fieldState.inputValue}
          items={availableList.items}
          onInputChange={onInputChange}
          onSelectionChange={onSelectionChange}
          selectedKey={fieldState.selectedKey}
        >
          <div className="inline-flex flex-1 flex-wrap items-center gap-1">
            <Input
              aria-describedby={[
                tagGroupId,
                descriptionContext?.["aria-describedby"] ?? "",
              ].join(" ")}
              className="flex-1 border-0 px-0.5 py-0 shadow-none ring-0"
              onBlur={() => {
                setFieldState({
                  inputValue: "",
                  selectedKey: null,
                });
                availableList.setFilterText("");
              }}
              onKeyDownCapture={onKeyDownCapture}
              placeholder={selectedKeys.length === 0 ? placeholder : undefined}
            />

            <div aria-hidden className="sr-only">
              <Button color="gray" ref={triggerButtonRef} variant="primary">
                <svg
                  className="size-4"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </Button>
            </div>
          </div>
          <Popover
            className="max-w-none bg-bg-primary duration-0"
            offset={4}
            style={{ width: `${width}px` }}
            triggerRef={triggerRef}
          >
            <ListBox<T>
              className="flex max-h-[inherit] flex-col gap-1.5 overflow-auto p-1.5 outline-none has-[header]:pt-0 sm:gap-0"
              renderEmptyState={() => renderEmptyState(fieldState.inputValue)}
              selectionMode="multiple"
            >
              {children}
            </ListBox>
          </Popover>
        </ComboBox>
        <Button asChild color="gray" variant="primary">
          <div
            aria-hidden
            className="absolute end-0.5 me-1 size-6 rounded p-0.5 text-text-placeholder"
          >
            {/* React Aria Button does not allow tabIndex */}
            <button
              onClick={() => triggerButtonRef.current?.click()}
              tabIndex={-1}
              type="button"
            >
              <svg
                className="size-4"
                fill="none"
                height="24"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m6 9 6 6 6-6" />
              </svg>
            </button>
          </div>
        </Button>
      </div>

      {name && (
        <input hidden name={name} readOnly value={selectedKeys.join(",")} />
      )}
    </>
  );
}

export function MultiSelectItem(props: ListBoxItemProps) {
  return (
    <ListBoxItem
      {...props}
      className={composeRenderProps(
        props.className,
        (className, { isFocused }) =>
          twMerge([
            "group flex justify-between cursor-default select-none items-center gap-x-2 rounded-md outline-none ring-0 focus-visible:outline-none",
            "py-[10px] pr-[10px] pl-md",
            "text-md font-normal text-text-primary",
            isFocused && "bg-bg-primary-hover",
            className,
          ]),
      )}
    >
      {props.children}
    </ListBoxItem>
  );
}
