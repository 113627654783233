import type { Table } from "@tanstack/react-table";

import type { FileItem } from "../../types";

import { TableHeader, TableRow } from "./components";

type FilesListViewProps = {
  table: Table<FileItem>;
};

export function FilesListView({ table }: FilesListViewProps) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="border-b border-border-secondary text-xs font-medium text-text-tertiary">
            {table
              .getHeaderGroups()
              .map((headerGroup) =>
                headerGroup.headers.map((header) => (
                  <TableHeader header={header} key={header.id} />
                )),
              )}
            <th className="px-xl py-md" style={{ width: "40px" }}></th>
            {/* Fixed width for action menu */}
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
