import { createFileRoute } from "@tanstack/react-router";

import { PaywallLayout } from "@/features/subscription/components/paywall-layout";

export const Route = createFileRoute(
  "/_auth/_app-shell/paywall/expired-subscription",
)({
  component: ExpiredSubscriptionPaywall,
});

function ExpiredSubscriptionPaywall() {
  return (
    <PaywallLayout
      actionText="Reactivate my account"
      actionUrl="https://nlc.com/manage-my-account/"
      message={
        <span>
          Please reactivate your subscription to
          <br />
          submit new design requests.
        </span>
      }
    />
  );
}
