import { AccessibleIcon } from "@/components/accessible-icon";
import { Button } from "@/components/button";
import { PlusIcon } from "@/components/icons.tsx";
import { Link } from "@/components/link";

export function Header() {
  return (
    <div className="flex items-center justify-between gap-2xl rounded-t-xl bg-bg-primary px-2xl pb-2xl pt-xl">
      <span className="line-clamp-1 text-display-xs font-semibold text-text-primary">
        Users
      </span>
      <Link className="hover:no-underline" href="users/create">
        <Button>
          <AccessibleIcon>
            <PlusIcon />
          </AccessibleIcon>
          New user
        </Button>
      </Link>
    </div>
  );
}
