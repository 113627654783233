import { useNavigate } from "@tanstack/react-router";
import { DialogTrigger } from "react-aria-components";
import { FormProvider } from "react-hook-form";

import { Button } from "@/components/button";
import {
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogFooter,
} from "@/components/dialog";
import { Modal } from "@/components/modal";
import { useUser } from "@/hooks/useUser";

import { DuplicatesCounter } from "./components/duplicates-counter";
import { ParamsCheckboxGroup } from "./components/params-checkbox-group";
import { RequestSelect } from "./components/request-select";
import {
  handleDuplicatePastRequest,
  useDuplicatePastRequestForm,
} from "./useDuplicatePastRequestForm";

type DuplicatePastRequestModalProps = {
  children: React.ReactNode;
};

const DUPLICATE_COUNTER_ROLES = new Set([
  "super-admin",
  "account-manager",
  "team-leader",
]);

export function DuplicatePastRequestModal({
  children,
}: DuplicatePastRequestModalProps) {
  const navigate = useNavigate({ from: "/requests/pick-type" });
  const form = useDuplicatePastRequestForm();
  const { data: user } = useUser();
  const hasExtraFields = DUPLICATE_COUNTER_ROLES.has(user?.user.roleName || "");

  return (
    <DialogTrigger
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          form.reset();
        }
      }}
    >
      {children}
      <Modal classNames={{ modal: "w-[486px]" }} isDismissable>
        <Dialog>
          {({ close }) => (
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((data) => {
                  handleDuplicatePastRequest(data, navigate);
                })}
              >
                <DialogCloseButton />
                <DialogBody className="gap-0">
                  <div className="flex flex-col gap-xs pb-2xl">
                    <span className="text-lg font-semibold text-text-primary">
                      Duplicate Past Request
                    </span>
                    <span className="text-sm text-text-tertiary">
                      Choose a previous request you want to import
                      <br /> and customize to save time
                    </span>
                  </div>
                  <hr className="-mx-3xl text-border-secondary" />
                  <div className="flex flex-col gap-2xl py-2xl">
                    <RequestSelect />
                    <ParamsCheckboxGroup hasExtraFields={hasExtraFields} />
                    {hasExtraFields && <DuplicatesCounter />}
                  </div>
                </DialogBody>

                <DialogFooter className="pt-4xl">
                  <div className="flex w-full gap-lg">
                    <Button
                      className="w-full"
                      color="gray"
                      onPress={close}
                      slot="close"
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <Button className="w-full" type="submit">
                      Confirm
                    </Button>
                  </div>
                </DialogFooter>
              </form>
            </FormProvider>
          )}
        </Dialog>
      </Modal>
    </DialogTrigger>
  );
}
