import type { SubmitRequestUnion } from "@nlc/shared-types";
import type { UseFormReturn } from "react-hook-form";

import { RequestTypeEnum, updateRequestUnionType } from "@nlc/shared-types";
import { useMutation } from "@tanstack/react-query";

import { toast } from "@/components/toast/toast-queue";
import { useCapturePosthogEvent } from "@/hooks/posthog/useCapturePosthogEvent";
import authenticatedApi from "@/lib/api/client";

import type { CreateWebsiteDesignRequestFormData } from "../components/useCreateWebsiteDesignRequestForm";

import { getValidAttachmentIds } from "../../shared/utils";

type UseSaveDraftMutationProps = {
  formMethods: UseFormReturn<CreateWebsiteDesignRequestFormData>;
  latestRequestId: { requestId: number };
};

export function useSaveDraftMutation({
  formMethods,
  latestRequestId,
}: UseSaveDraftMutationProps) {
  const capture = useCapturePosthogEvent();

  return useMutation({
    mutationFn: async (formData: CreateWebsiteDesignRequestFormData) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const commonFields: SubmitRequestUnion = {
        attachmentIds: [
          ...getValidAttachmentIds(formData.assets),
          ...getValidAttachmentIds(formData.inspirations),
        ],
        brandId: formData.brandId,
        collaboratorIds: formData.collaboratorsIds.map(Number),
        complexityId: formData.designComplexityId,
        creativeDirection: formData.creativeDirection,
        designTypeId: formData.designTypeId,
        name: formData.requestName,
        preferredDesignerIds: formData.preferredDesignersIds.map(Number),
        preferredFileType: formData.preferredFileTypes,
        requestType: RequestTypeEnum.WEBSITE_DESIGN,
        sizesNeeded: formData.sizesNeeded,
      };

      const mbcFields = formData.briefCreatorDetailed;

      const briefTypeFields =
        formData.briefCreatorType === "detailed"
          ? {
              briefText: mbcFields.finalBriefHTML,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              textForDesign: mbcFields.textsForDesign,
            }
          : {
              briefText: undefined,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              textForDesign: formData.briefCreatorSingle.textsForDesignHTML,
            };

      const magicalBriefCreatorFields =
        formData.briefCreatorType === "detailed"
          ? {
              magicalBriefCreator: {
                additionalGuidelines: mbcFields.additionalGuidelines,
                brandGuidelines: mbcFields.brandGuidelines,
                creativeGoals: mbcFields.creativeGoal,
                scope: mbcFields.scope,
                textForDesign: mbcFields.textsForDesign,
              },
            }
          : {};

      const parsed = updateRequestUnionType.parse({
        ...commonFields,
        ...briefTypeFields,
        ...magicalBriefCreatorFields,
      });

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}`)
        .patch(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error) => {
      console.error("Failed to save draft:", error);

      if (error.message.includes("Request name already exists")) {
        formMethods.setError("requestName", {
          message: "Request name already exists",
        });
        formMethods.setFocus("requestName");
      }

      toast.add(
        {
          description: error.message,
          title: "Error saving draft",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onSuccess: () => {
      capture("brief_draft_saved", {
        draft_id: latestRequestId.requestId,
        form_version: formMethods.getValues("briefCreatorType"),
      });

      toast.add(
        {
          title: "Draft saved successfully",
          type: "success",
        },
        {
          timeout: 5000,
        },
      );
    },
  });
}
