import { Button } from "@/components/button";
import { Text } from "@/components/text";
import { Selector } from "@/features/users/shared/components/ItemsPerPageSelect.tsx";

export function TableFooter({
  itemsPerPage,
  page,
  setItemsPerPage,
  setPage,
  totalItems,
}: {
  itemsPerPage: number;
  page: number;
  setItemsPerPage: (itemsPerPage: number) => void;
  setPage: (page: number) => void;
  totalItems: number;
}) {
  const firstItem = (page - 1) * itemsPerPage + 1;
  const lastItem = Math.min(page * itemsPerPage, totalItems);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="flex w-full justify-between rounded-b-xl bg-bg-primary px-2xl">
      <Selector
        onChange={(e) => {
          setPage(1);
          setItemsPerPage(e);
        }}
        value={itemsPerPage}
      />
      <div className="flex items-center gap-4">
        <Text className="text-sm font-normal text-text-tertiary">
          {firstItem} - {lastItem} of {totalItems}
        </Text>
        <Button
          color="gray"
          onPress={() => setPage(page > 1 ? page - 1 : page)}
          variant="secondary"
        >
          Previous
        </Button>
        <Button
          color="gray"
          onPress={() => setPage(page < totalPages ? page + 1 : page)}
          variant="secondary"
        >
          Next
        </Button>
      </div>
    </div>
  );
}
