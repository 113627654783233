import type { GetUsersParams } from "@nlc/shared-types";

export const brandsKeys = {
  all: ["brands"] as const,
  brandsByProjectId: (projectId?: number) =>
    [...brandsKeys.all, projectId] as const,
};

export const preferredDesignersKeys = {
  all: ["preferred-designers"] as const,
  preferredDesignersByProjectId: (projectId?: number) =>
    [...preferredDesignersKeys.all, projectId] as const,
};

export const collaboratorsKeys = {
  all: ["collaborators"] as const,
  collaboratorsByProjectId: (projectId?: number) =>
    [...collaboratorsKeys.all, projectId] as const,
};

export const designTypesKeys = {
  all: ["design-types"] as const,
  designTypesByProjectId: (projectId?: number) =>
    [...designTypesKeys.all, projectId] as const,
};

export const requestListKeys = {
  all: ["request-list"] as const,
  requestListByProjectId: (projectId: number, category?: string[]) =>
    [...requestListKeys.all, projectId, category] as const,
};

export const usersKeys = {
  all: ["users"] as const,
  usersBySearchParams: (searchParams: GetUsersParams) =>
    [...usersKeys.all, searchParams] as const,
};
