import type { GetSuperAdminUser } from "@nlc/shared-types";
import type { SortDescriptor } from "react-stately";

import { TableBody } from "react-aria-components";

import {
  Cell,
  Column,
  Row,
  Table,
  TableHeader,
  UserCell,
} from "@/components/table/table.tsx";
import { COLUMN_WIDTHS } from "@/features/users/shared/columnWidths.ts";
import { ImpersonateButton } from "@/features/users/shared/components/ImpersonateButton.tsx";
import { dateFormatter } from "@/utils/dateFormatter.ts";

export function CustomerSuccessTable({
  rows,
  setSortDescriptor,
  sortDescriptor,
}: {
  rows: GetSuperAdminUser[];
  setSortDescriptor: (sortDescriptor: SortDescriptor) => void;
  sortDescriptor: SortDescriptor;
}) {
  return (
    <Table
      aria-label="Customer Success table"
      onSortChange={setSortDescriptor}
      sortDescriptor={sortDescriptor}
    >
      <TableHeader>
        <Column allowsSorting id="name" isRowHeader maxWidth={COLUMN_WIDTHS.MAX_WIDTH} minWidth={COLUMN_WIDTHS.EMPLOYEES_NAME_MIN_WIDTH}>
          Name
        </Column>
        <Column allowsSorting id="email" isRowHeader maxWidth={COLUMN_WIDTHS.MAX_WIDTH} minWidth={COLUMN_WIDTHS.MIN_WIDTH}>
          Email
        </Column>
        <Column allowsSorting id="createdOn" isRowHeader width={COLUMN_WIDTHS.CREATED_ON}>
          Created on
        </Column>
        <Column id="actions" isRowHeader width={COLUMN_WIDTHS.ACTIONS}>
          Action
        </Column>
      </TableHeader>
      <TableBody items={rows}>
        {(row) => (
          <Row>
            <Cell>
              <UserCell
                name={row?.name}
                profilePic={row?.avatar}
                userId={row?.id}
              />
            </Cell>
            <Cell>{row?.email}</Cell>
            <Cell>{dateFormatter(row?.createdOn)}</Cell>
            <Cell>
              <ImpersonateButton id={row?.id} />
            </Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  );
}
