import type {
  GetRequestsQuerystring,
  RequestCategory,
} from "@nlc/shared-types";

import { RequestTypeEnum } from "@nlc/shared-types";
import { useSearch } from "@tanstack/react-router";
import { Controller, useFormContext } from "react-hook-form";

import {
  ComboBox,
  ComboBoxButton,
  ComboBoxGroup,
  ComboBoxInput,
  ComboBoxListBox,
  ComboBoxListItem,
  ComboBoxPopover,
} from "@/components/combobox";
import { FieldError, Label } from "@/components/field";
import { Text } from "@/components/text";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";

import type { DuplicatePastRequestFormData } from "../useDuplicatePastRequestForm";

import { useGetRequestList } from "../useGetRequestList";

function getCategory(
  RequestCategory: RequestCategory,
): GetRequestsQuerystring["category"] {
  switch (RequestCategory) {
    case "graphic": {
      return [RequestTypeEnum.GRAPHIC];
    }
    case "video": {
      return [RequestTypeEnum.VIDEO];
    }
    case "webui": {
      return [
        RequestTypeEnum.WEBSITE_DESIGN,
        RequestTypeEnum.WEBSITE_IMPLEMENTATION,
      ];
    }
    default: {
      return [];
    }
  }
}

export function RequestSelect() {
  const { control, setValue } = useFormContext<DuplicatePastRequestFormData>();
  const projectId = useGetCurrentProjectId();
  const { category } = useSearch({
    from: "/_auth/_app-shell/requests/pick-type",
  });

  const { data, isLoading } = useGetRequestList({
    category: getCategory(category),
    fields: ["id", "name"],
    projectId,
  });

  const placeholder = isLoading
    ? "Loading..."
    : data?.requests?.length === 0
      ? "No results"
      : "Select a request";

  return (
    <Controller
      control={control}
      name="requestId"
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <ComboBox
          allowsEmptyCollection
          isInvalid={invalid}
          menuTrigger="input"
          onSelectionChange={(key) => {
            const request = data!.requests.find(
              (request) => request.id === key,
            );

            if (!request) {
              return;
            }

            setValue("requestType", request.requestType);
            onChange(key);
          }}
          selectedKey={value}
        >
          <Label requiredHint>Request Name</Label>
          <ComboBoxGroup>
            <ComboBoxInput placeholder={placeholder} />
            <ComboBoxButton />
          </ComboBoxGroup>
          <FieldError>{error?.message}</FieldError>

          <ComboBoxPopover className="w-[calc(var(--trigger-width)-25px)]">
            <ComboBoxListBox
              renderEmptyState={() => (
                <Text className="p-2">No results found</Text>
              )}
            >
              {data?.requests?.map((request) => (
                <ComboBoxListItem id={request.id} key={request.id}>
                  {request.name}
                </ComboBoxListItem>
              ))}
            </ComboBoxListBox>
          </ComboBoxPopover>
        </ComboBox>
      )}
    />
  );
}
