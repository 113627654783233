import type { Dispatch, SetStateAction } from "react";
import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { convert } from "html-to-text";
import { Delta } from "quill";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import * as z from "zod";

import { useIsMBCSubmitted } from "@/features/request/create/shared/useIsMBCSubmitted";
import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";
import { useUser } from "@/hooks/useUser";

import { fileAssetSchema, linkAssetSchema } from "../../shared/utils";

const createWebsiteDesignRequestFormSchema = (userRole: string) =>
  z.object({
    /* eslint-disable perfectionist/sort-objects */
    requestName: z
      .string()
      .min(1, { message: "Request name is required" })
      .max(255, { message: "Request name is too long" }),
    designTypeId: z.number({
      required_error: "Design type is required",
    }),
    brandId: z.coerce.number().optional(),
    // Brief creator
    briefCreatorType: z.enum(["detailed", "single"]),
    briefCreatorDetailed: z.object({
      additionalGuidelines: z.string(),
      brandGuidelines: z.string(),
      creativeGoal: z.string(),
      textsForDesign: z.string(),
      scope: z.string(),
      finalBriefHTML: z
        .string()
        .refine((value) => convert(value).length < 5000, {
          // 5000 characters of text (html tags removed)
          message: "Final brief text is too long",
        }),
      finalBrief: z.instanceof(Delta),
    }),
    briefCreatorSingle: z.object({
      requestDescriptionHTML: z
        .string()
        .refine((value) => convert(value).length < 5000, {
          // 5000 characters of text (html tags removed)
          message: "Request description text is too long",
        }),
      textsForDesignHTML: z
        .string()
        .refine((value) => convert(value).length < 5000, {
          // 5000 characters of text (html tags removed)
          message: "Text for design text is too long",
        }),
      requestDescription: z.instanceof(Delta),
      textsForDesign: z.instanceof(Delta),
    }),
    // Assets and inspirations
    assets: z.array(z.union([fileAssetSchema, linkAssetSchema])),
    inspirations: z.array(z.union([fileAssetSchema, linkAssetSchema])),
    // Technical details
    creativeDirection: z
      .union([
        z.literal("Designer has creative freedom"),
        z.literal("Follow brief exactly"),
      ])
      .optional(),
    sizesNeeded: z.string().min(1, { message: "Sizes are required" }).max(800, {
      message: "Sizes are too long",
    }),
    preferredFileTypes: z
      .string()
      .min(1, { message: "File types are required" })
      .max(250, {
        message: "File types are too long",
      }),
    preferredDesignersIds: z.array(z.string()),
    designComplexityId: [
      "account-manager",
      "super-admin",
      "team-leader",
    ].includes(userRole)
      ? z.number({ required_error: "Design complexity is required" })
      : z.any().optional(),
    collaboratorsIds: z.array(z.string()),
    /* eslint-enable perfectionist/sort-objects */
  });

export type CreateWebsiteDesignRequestFormData = z.infer<
  ReturnType<typeof createWebsiteDesignRequestFormSchema>
>;

export function useCreateWebsiteDesignRequestForm(): {
  setShowWarningModal: Dispatch<SetStateAction<boolean>>;
  showWarningModal: boolean;
} & UseFormReturn<CreateWebsiteDesignRequestFormData> {
  const { data: user } = useUser();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const setIsDirty = useIsRequestFormDirty((state) => state.setIsDirty);
  const isMBCSubmitted = useIsMBCSubmitted((state) => state.isSubmitted);

  const form = useForm<CreateWebsiteDesignRequestFormData>({
    defaultValues: {
      /* eslint-disable perfectionist/sort-objects */
      requestName: "",
      designTypeId: undefined,
      brandId: undefined,
      briefCreatorType: "detailed",
      briefCreatorDetailed: {
        additionalGuidelines: "",
        brandGuidelines: "",
        creativeGoal: "",
        textsForDesign: "",
        scope: "",
        finalBriefHTML: "",
        finalBrief: new Delta(),
      },
      briefCreatorSingle: {
        requestDescriptionHTML: "",
        requestDescription: new Delta(),
        textsForDesignHTML: "",
        textsForDesign: new Delta(),
      },
      assets: [],
      inspirations: [],
      creativeDirection: undefined,
      sizesNeeded: "",
      preferredFileTypes: "",
      preferredDesignersIds: [],
      designComplexityId: undefined,
      collaboratorsIds: [],
      /* eslint-enable perfectionist/sort-objects */
    },
    resolver: zodResolver(
      createWebsiteDesignRequestFormSchema(
        user?.user.roleName || "",
      ).superRefine((data, ctx) => {
        switch (data.briefCreatorType) {
          case "detailed": {
            const currentValues = form.getValues();

            // Don't validate if there is final brief text
            if (
              convert(currentValues.briefCreatorDetailed.finalBriefHTML).trim()
                .length > 0
            ) {
              return;
            }
            // if AI generated - validate input field
            if (
              isMBCSubmitted &&
              convert(currentValues.briefCreatorDetailed.finalBriefHTML).trim()
                .length === 0
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Request description is required",
                path: ["briefCreatorDetailed", "finalBrief"],
              });
            }

            break;
          }
          case "single": {
            const currentValues = form.getValues();

            // Check for requestDescription length
            if (
              convert(currentValues.briefCreatorSingle.requestDescriptionHTML)
                .length >= 5000
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Request description text is too long",
                path: ["briefCreatorSingle", "requestDescription"],
              });
            }
            // Validate single brief fields
            if (
              convert(
                currentValues.briefCreatorSingle.requestDescriptionHTML,
              ).trim().length === 0
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Request description is required",
                path: ["briefCreatorSingle", "requestDescription"],
              });
            }

            break;
          }
        }
      }),
    ),
  });

  // Handle beforeunload event to prevent unsaved changes from being lost
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";

        return event.returnValue;
      }
    },
    [form.formState.isDirty],
  );

  useEffect(() => {
    setIsDirty(form.formState.isDirty);
  }, [form.formState.isDirty, setIsDirty]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  useEffect(() => {
    const unsubscribe = useIsRequestFormDirty.subscribe(
      (state) => state.isDirty,
      (isDirty) => {
        if (!isDirty) {
          form.reset(form.getValues());
        }
      },
    );

    return () => unsubscribe();
  }, [form]);

  return {
    ...form,
    setShowWarningModal,
    showWarningModal,
  };
}

export function useCreateWebsiteDesignRequestFormContext() {
  return useFormContext<CreateWebsiteDesignRequestFormData>();
}
