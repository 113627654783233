import type { Path, UseFormReturn } from "react-hook-form";

import type { RequestFormData } from "./types";

import { AssetsForm } from "./components/AssetsForm";

type AssetsSectionProps<TFormValues extends RequestFormData> = {
  formMethods: UseFormReturn<TFormValues>;
};

export function AssetsSection<TFormValues extends RequestFormData>({
  formMethods,
}: AssetsSectionProps<TFormValues>) {
  return (
    <div className="flex flex-col gap-3xl">
      <span className="col-span-2 text-lg font-semibold text-text-primary">
        Assets & Inspirations
      </span>

      <div className="grid grid-cols-2 gap-2xl">
        <AssetsForm
          field={"assets" as Path<TFormValues>}
          formMethods={formMethods}
          title="Assets"
        />
        <AssetsForm
          field={"inspirations" as Path<TFormValues>}
          formMethods={formMethods}
          title="Inspirations"
        />
      </div>
    </div>
  );
}
