import { AlertCircle } from "lucide-react";

export function SizeAlert() {
  return (
    <div className="flex gap-xl rounded-xl border border-border-primary bg-bg-brand-primary-alt p-xl">
      <div className="grid size-4xl shrink-0 place-items-center rounded-full bg-bg-brand-secondary">
        <AlertCircle className="text-featured-icon-light-fg-brand" size={16} />
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-semibold text-text-secondary">
          We only allow up to 25 files to be uploaded at once.
        </span>
        <span className="text-sm font-normal text-text-tertiary">
          For more uploads, use Google Drive, Dropbox, or similar services.
        </span>
      </div>
    </div>
  );
}
