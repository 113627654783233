import { Controller, useFormContext } from "react-hook-form";

import { Checkbox } from "@/components/checkbox";
import { Label } from "@/components/field";

import type { DuplicatePastRequestFormData } from "../useDuplicatePastRequestForm";

type ParamsCheckboxGroupProps = {
  hasExtraFields: boolean;
};

export function ParamsCheckboxGroup({
  hasExtraFields,
}: ParamsCheckboxGroupProps) {
  const { control } = useFormContext<DuplicatePastRequestFormData>();

  return (
    <div className="flex flex-col gap-lg">
      <Label>Do you want to include:</Label>
      <Controller
        control={control}
        name="includeAttachments"
        render={({ field: { onChange, value } }) => (
          <Checkbox isSelected={value} onChange={onChange} value="attachments">
            Attachments
          </Checkbox>
        )}
      />
      <Controller
        control={control}
        name="includeCollaborators"
        render={({ field: { onChange, value } }) => (
          <Checkbox
            isSelected={value}
            onChange={onChange}
            value="collaborators"
          >
            Collaborators
          </Checkbox>
        )}
      />
      {hasExtraFields && (
        <Controller
          control={control}
          name="includeLeadershipNotes"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isSelected={value}
              onChange={onChange}
              value="leadershipNotes"
            >
              Leadership notes
            </Checkbox>
          )}
        />
      )}
    </div>
  );
}
