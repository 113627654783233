import type { FileAsset, LinkAsset } from "../types";

export const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB
export const MAX_FILES = 25;
export const ALLOWED_EXTENSIONS = [
  ".jpg",
  ".png",
  ".jpeg",
  ".pdf",
  ".heic",
  ".svg",
  ".eps",
  ".webp",
  ".tiff",
  ".gif",
  ".mp4",
  ".m4v",
  ".psd",
  ".ai",
  ".indd",
  ".prproj",
  ".aep",
  ".fig",
  ".mp3",
  ".wav",
  ".bmp",
  ".xd",
  ".html",
  ".doc",
  ".docx",
  ".txt",
  ".ppt",
  ".key",
  ".numbers",
  ".pages",
  ".cdr",
  ".raw",
  ".mid",
  ".midi",
  ".m4a",
  ".ogg",
  ".avi",
  ".mpg",
  ".mpeg",
  ".m4v",
  ".rar",
  ".zip",
  ".xls",
  ".xlsx",
  ".csv",
  ".mov",
  ".ttf",
  ".otf",
  ".pptx",
];

export const isFileAsset = (asset: FileAsset | LinkAsset): asset is FileAsset =>
  "file" in asset;

export type AssetField = "assets" | "inspirations";
export const LINK_TYPE = {
  assets: "asset",
  inspirations: "example",
} as const;

export function hasDuplicateFileName(
  newFileName: string,
  existingAssets: (FileAsset | LinkAsset)[],
): boolean {
  return existingAssets
    .filter((asset): asset is FileAsset => "file" in asset)
    .some((asset) => asset.file.name === newFileName);
}
