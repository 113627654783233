import type { UseNavigateResult } from "@tanstack/react-router";

import { zodResolver } from "@hookform/resolvers/zod";
import { RequestTypeEnum } from "@nlc/shared-types";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  includeAttachments: z.boolean(),
  includeCollaborators: z.boolean(),
  includeLeadershipNotes: z.boolean(),
  numberOfDuplicates: z.number().min(1, "Number of duplicates is required"),
  requestId: z.number().min(1, "Request name is required"),
  requestType: z.nativeEnum(RequestTypeEnum),
});

export type DuplicatePastRequestFormData = z.infer<typeof schema>;

export function useDuplicatePastRequestForm() {
  return useForm<DuplicatePastRequestFormData>({
    defaultValues: {
      includeAttachments: true,
      includeCollaborators: true,
      includeLeadershipNotes: false,
      numberOfDuplicates: 1,
      requestId: 0,
      requestType: RequestTypeEnum.GRAPHIC,
    },
    resolver: zodResolver(schema),
  });
}

export function handleDuplicatePastRequest(
  data: DuplicatePastRequestFormData,
  navigate: UseNavigateResult<string>,
) {
  navigate({
    search: (prev) => ({
      duplicate_count: data.numberOfDuplicates,
      duplicate_from: Number(data.requestId),
      include_attachments: data.includeAttachments ? 1 : 0,
      include_collaborators: data.includeCollaborators ? 1 : 0,
      include_leadership_notes: data.includeLeadershipNotes ? 1 : 0,
      "project-id": prev["project-id"],
      "request-type": data.requestType,
    }),
    to: "/requests/create",
  });
}
