export const userRoles = [
  { id: 1, name: "super-admin" },
  { id: 2, name: "owner" },
  { id: 3, name: "admin" },
  { id: 4, name: "collaborator" },
  { id: 5, name: "asana-user" },
  { id: 6, name: "team-leader" },
  { id: 7, name: "designer" },
  { id: 8, name: "account-manager" },
  { id: 9, name: "support" },
  { id: 10, name: "account-support" },
  { id: 11, name: "organization-admin" },
] as const;

export type UserRole = (typeof userRoles)[number]["name"];

export const featureRoleMap: Record<string, UserRole[]> = {};
