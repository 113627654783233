import type { Delta } from "quill";
import type Quill from "quill";
import type {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
} from "react-hook-form";

import { forwardRef, useImperativeHandle, useRef } from "react";
import { Controller } from "react-hook-form";

import Editor from "@/components/quill-editor";

interface ControlledQuillEditorProps<T extends FieldValues> {
  className?: string;
  control: Control<T>;
  name: FieldPath<T>;
  onChangeHTML?: (html: string) => void;
  onSelectionChange?: Parameters<typeof Editor>[0]["onSelectionChange"];
  onTextChange?: Parameters<typeof Editor>[0]["onTextChange"];
  placeholder?: string;
  readOnly?: boolean;
}

const QuillEditorWithRef = forwardRef<
  Quill,
  {
    className?: string;
    error: FieldError | undefined;
    field: FieldValues;
    lastValueRef: React.MutableRefObject<Delta | null>;
    onChangeHTML?: (html: string) => void;
    onSelectionChange: Parameters<typeof Editor>[0]["onSelectionChange"];
    onTextChange: Parameters<typeof Editor>[0]["onTextChange"];
    placeholder?: string;
    readOnly: boolean;
  }
>(
  (
    {
      className,
      error,
      field,
      lastValueRef,
      onChangeHTML,
      onSelectionChange,
      onTextChange,
      placeholder,
      readOnly,
    },
    ref,
  ) => {
    const quillRef = useRef<null | Quill>(null);

    useImperativeHandle(ref, () => quillRef.current as Quill);

    if (
      field.value &&
      JSON.stringify(lastValueRef.current) !== JSON.stringify(field.value)
    ) {
      lastValueRef.current = field.value as Delta;
    }

    return (
      <>
        <Editor
          className={className}
          isError={!!error}
          onChange={(delta) => {
            field.onChange(delta);
            lastValueRef.current = delta;

            if (quillRef.current && onChangeHTML) {
              const html = quillRef.current.root.innerHTML;

              onChangeHTML(html);
            }
          }}
          onSelectionChange={onSelectionChange}
          onTextChange={(delta, oldContents, source) => {
            onTextChange(delta, oldContents, source);
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={quillRef}
          value={field.value as Delta}
        />
        <span className="mt-sm block text-sm font-normal text-text-error-primary">
          {error?.message}
        </span>
      </>
    );
  },
);

QuillEditorWithRef.displayName = "QuillEditorWithRef";

export function ControlledQuillEditor<T extends FieldValues>({
  className,
  control,
  name,
  onChangeHTML,
  onSelectionChange = () => {},
  onTextChange = () => {},
  placeholder,
  readOnly = false,
}: ControlledQuillEditorProps<T>) {
  const lastValueRef = useRef<Delta | null>(null);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <QuillEditorWithRef
          className={className}
          error={error}
          field={field}
          lastValueRef={lastValueRef}
          onChangeHTML={onChangeHTML}
          onSelectionChange={onSelectionChange}
          onTextChange={onTextChange}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={field.ref}
        />
      )}
    />
  );
}
