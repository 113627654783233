import { useMutationState } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";

export function useGetLatestRequestId(): {
  requestId: number;
} {
  // Gets the latest draft request id
  const data = useMutationState({
    filters: { mutationKey: ["draftId"] },
    select: (mutation) => mutation.state.data,
  });
  const requestIdFromDraft = data.at(-1) as { requestId: number };

  // Gets the request id from the duplicate request (no draft is created)
  const { ["request_id"]: requestId } = useSearch({
    from: "/_auth/_app-shell/requests/create",
  });
  const requestIdFromDuplicate = {
    requestId,
  };

  return requestIdFromDraft || requestIdFromDuplicate;
}
