import type { ReactNode } from "react";

import type { UserRole } from "./config";

import { useRoleAccess } from "./useRoleAccess";

type RoleGuardProps = {
  allowedRoles: UserRole[];
  children: ReactNode;
  fallback?: ReactNode;
};

export function RoleGuard({
  allowedRoles,
  children,
  fallback = null,
}: RoleGuardProps): ReactNode {
  const { hasRole } = useRoleAccess();

  return hasRole(allowedRoles) ? <>{children}</> : <>{fallback}</>;
}
