import type { GetDesignTypesResponse } from "@nlc/shared-types";

import { useSuspenseQuery } from "@tanstack/react-query";

import { designTypesKeys } from "@/hooks/queryKeys";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";
import apiClient from "@/lib/api/client";

import type { DesignPickerData } from "../types";

import {
  CATEGORY_ICONS_MAPPING,
  SUBCATEGORY_ICONS_MAPPING,
} from "../icons-mappings";

export function useGetDesignTypes() {
  const projectId = useGetCurrentProjectId();

  return useSuspenseQuery<GetDesignTypesResponse, Error, DesignPickerData>({
    queryFn: async () =>
      await apiClient.url(`/design-types/picker/${projectId}`).get().json(),
    queryKey: designTypesKeys.designTypesByProjectId(projectId),
    retry: 2,
    select: (data: GetDesignTypesResponse) => ({
      categories: data.designTypes.categories.map((category) => ({
        ...category,
        icon: CATEGORY_ICONS_MAPPING.find((icon) => icon.id === category.id)
          ?.icon,
      })),
      designTypes: data.designTypes.types.filter((type) => !type.isBlocked),
      subcategories: data.designTypes.subcategories.map((subcategory) => ({
        ...subcategory,
        icon: SUBCATEGORY_ICONS_MAPPING.find(
          (icon) => icon.id === subcategory.id,
        )?.icon,
      })),
    }),
  });
}
