import { BrandsSelect } from "./BrandsSelect";
import { RequestNameInput } from "./RequestNameInput";
import { SelectDesignType } from "./SelectDesignType";

export function IntroSection() {
  return (
    <div className="rounded-xl bg-bg-secondary p-3xl">
      <div className="grid grid-cols-4 gap-x-3xl">
        <RequestNameInput />
        <SelectDesignType />
        <BrandsSelect />
      </div>
    </div>
  );
}
