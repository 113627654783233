import { createFileRoute } from "@tanstack/react-router";

import { CustomerUsers } from "@/features/users/customer";
import { SuperAdminUsers } from "@/features/users/super-admin";
import { TeamLeaderUsers } from "@/features/users/team-leader";
import { useUser } from "@/hooks/useUser.tsx";
export const Route = createFileRoute("/_auth/_app-shell/users/")({
  component: UsersView,
});

function UsersView() {
  const { data: user } = useUser();

  const role = user?.user.roleName;

  return (
    <div className="mx-auto min-h-screen bg-bg-tertiary py-4xl">
      <div className="mx-auto flex min-w-[872px] max-w-[1500px] flex-col px-4xl @container">
        {(() => {
          switch (role) {
            case "admin":
            case "owner": {
              return <CustomerUsers />;
            }
            case "team-leader": {
              return <TeamLeaderUsers />;
            }
            case "super-admin": {
              return <SuperAdminUsers />;
            }
            default: {
              return null;
            }
          }
        })()}
      </div>
    </div>
  );
}
