import { Controller } from "react-hook-form";

import { FieldError, Input, Label, TextField } from "@/components/field";

import { useCreateWebsiteDesignRequestFormContext } from "../useCreateWebsiteDesignRequestForm";

export function RequestNameInput() {
  const { control } = useCreateWebsiteDesignRequestFormContext();

  return (
    <Controller
      control={control}
      name="requestName"
      render={({
        field: { name, onBlur, onChange, ref, value },
        fieldState: { error, invalid },
      }) => (
        <TextField
          className="col-span-2"
          isInvalid={invalid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          validationBehavior="aria"
          value={value}
        >
          <Label requiredHint>Request name</Label>
          <Input
            placeholder="Create a unique name for your request"
            ref={ref}
          />
          <FieldError>{error?.message}</FieldError>
        </TextField>
      )}
    />
  );
}
