import type { GetMeUserResponse } from "@nlc/shared-types";

import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import apiClient from "@/lib/api/client";
import { updateIntercomUser } from "@/lib/intercom";

export function useUser() {
  const query = useQuery<GetMeUserResponse>({
    queryFn: () => apiClient.url("/users/me").get().json(),
    queryKey: ["user"],
  });

  const currentProject = query.data?.projects.find(
    (project) => project.id === query.data.currentProjectId,
  );

  useEffect(() => {
    if (query.data) {
      updateIntercomUser(query.data);
    }
  }, [query.data]);

  return {
    ...query,
    currentProject,
    websiteUrl: query.data?.websiteUrl,
  };
}
