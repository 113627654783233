import type {
  CellProps,
  ColumnProps,
  RowProps,
  TableHeaderProps,
  TableProps,
} from "react-aria-components";

import { ArrowUp, ChevronsUpDown } from "lucide-react";
import { ResizableTableContainer } from "react-aria-components";
import {
  Cell as AriaCell,
  Column as AriaColumn,
  Row as AriaRow,
  Table as AriaTable,
  TableHeader as AriaTableHeader,
  Button,
  Collection,
  composeRenderProps,
  Group,
  useTableOptions,
} from "react-aria-components";

import { Avatar } from "@/components/avatar";
import { Checkbox } from "@/components/checkbox";
import { Link } from "@/components/link";
import { composeTailwindRenderProps } from "@/components/utils.ts";

export function Table(props: TableProps) {
  return (
    <div className="relative overflow-auto">
      <ResizableTableContainer>
        <AriaTable {...props} className="outline-none" />
      </ResizableTableContainer>
     </div>
  );
}

export function Column(props: ColumnProps) {
  return (
    <AriaColumn
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "px-3xl text-xs font-semibold text-text-tertiary bg-bg-secondary cursor-default outline-none",
      )}
    >
      {composeRenderProps(
        props.children,
        (children, { allowsSorting, sortDirection }) => (
          <div className="flex items-center">
            <Group
              className="flex h-5 flex-1 cursor-pointer items-center gap-1 overflow-hidden outline-none"
              role="presentation"
              tabIndex={-1}
            >
              <span className="truncate">{children}</span>
              {allowsSorting && (
                <span
                  className={`flex size-4 items-center justify-center outline-none transition ${
                    sortDirection === "descending" ? "rotate-180" : ""
                  }`}
                >
                  {sortDirection ? (
                    <ArrowUp
                      aria-hidden
                      className="text-xs font-semibold text-text-tertiary"
                    />
                  ) : (
                    <ChevronsUpDown />
                  )}
                </span>
              )}
            </Group>
          </div>
        ),
      )}
    </AriaColumn>
  );
}

export function TableHeader<T extends object>(props: TableHeaderProps<T>) {
  const { allowsDragging, selectionBehavior, selectionMode } =
    useTableOptions();

  return (
    <AriaTableHeader
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "sticky h-11  top-0 z-10 backdrop-blur-md outline-none",
      )}
    >
      {allowsDragging && <Column />}
      {selectionBehavior === "toggle" && (
        <AriaColumn
          className="cursor-default text-start text-sm font-semibold outline-none"
          minWidth={36}
          width={36}
        >
          {selectionMode === "multiple" && <Checkbox slot="selection" />}
        </AriaColumn>
      )}
      <Collection items={props.columns}>{props.children}</Collection>
    </AriaTableHeader>
  );
}

export function Row<T extends object>({
  children,
  columns,
  id,
  ...otherProps
}: RowProps<T>) {
  const { allowsDragging, selectionBehavior } = useTableOptions();

  return (
    <AriaRow
      id={id}
      {...otherProps}
      className="group/row h-11 bg-bg-primary outline-none"
    >
      {allowsDragging && (
        <Cell>
          <Button slot="drag">≡</Button>
        </Cell>
      )}
      {selectionBehavior === "toggle" && (
        <Cell>
          <Checkbox slot="selection" />
        </Cell>
      )}
      <Collection items={columns}>{children}</Collection>
    </AriaRow>
  );
}

export function Cell(props: CellProps) {
  return (
    <AriaCell
      {...props}
      className="border-b border-b-border-secondary px-3xl text-sm font-normal text-text-tertiary outline-none"
    />
  );
}

export function UserCell({
  name,
  profilePic,
  userId,
}: {
  name: string;
  profilePic?: string;
  userId: string;
}) {
  return (
    <Link className="hover:no-underline" href={"users/" + userId + "/edit"}>
      <div className="flex items-center  py-4">
        <Avatar
          alt={name}
          className="size-10 rounded-full border  border-border-secondary object-cover"
          src={profilePic}
        />
        <p className="ml-4 text-sm font-medium text-text-primary">{name}</p>
      </div>
    </Link>
  );
}
