import * as z from "zod";

export const fileStatusSchema = z.enum(["error", "success", "uploading"]);

export const fileSchema = z.instanceof(File);

export const fileAssetSchema = z.object({
  attachmentId: z.number().nullable(),
  awsDownloadUrl: z.string().nullable().optional(),
  file: fileSchema,
  id: z.string(),
  status: fileStatusSchema,
});

export const linkAssetSchema = z.object({
  attachmentId: z.number(),
  id: z.string(),
  url: z.string().url(),
});

export type FileStatus = z.infer<typeof fileStatusSchema>;
export type FileAsset = z.infer<typeof fileAssetSchema>;
export type LinkAsset = z.infer<typeof linkAssetSchema>;

export function getValidAttachmentIds(
  items: (FileAsset | LinkAsset)[],
): number[] {
  return items
    .filter(
      (item) =>
        "url" in item
          ? item.attachmentId !== null // LinkAsset
          : item.attachmentId !== null && item.status === "success", // FileAsset
    )
    .map((item) => item.attachmentId!);
}
