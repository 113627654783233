import { useUser } from "@/hooks/useUser";

import type { UserRole } from "./config";

import { featureRoleMap } from "./config";

export function useRoleAccess(): {
  canAccess: (featureName: string) => boolean;
  hasRole: (allowedRoles: UserRole[]) => boolean;
} {
  const { data: userData } = useUser();

  if (!userData) return { canAccess: () => false, hasRole: () => false };

  const hasRole = (allowedRoles: UserRole[]): boolean => {
    const { user } = userData;

    if (!user || !user.roleName) return false;

    return allowedRoles.includes(user.roleName as UserRole);
  };

  const canAccess = (featureName: string): boolean => {
    const allowedRoles = featureRoleMap[featureName];

    if (!allowedRoles) return false;

    return hasRole(allowedRoles);
  };

  return { canAccess, hasRole };
}
