import type { GetMeUserResponse } from "@nlc/shared-types";
import type { QueryClient } from "@tanstack/react-query";

import { redirect } from "@tanstack/react-router";

import apiClient from "@/lib/api/client";

const PAYWALL_ROUTES = {
  NO_CREDITS: "/paywall/no-credits",
  SUBSCRIPTION_CANCELLED: "/paywall/expired-subscription",
  SUBSCRIPTION_EXPIRED: "/paywall/expired-subscription",
} as const;

const DEFAULT_REDIRECT_ROUTE = PAYWALL_ROUTES.SUBSCRIPTION_EXPIRED;

type PaywallType = keyof typeof PAYWALL_ROUTES;

type SubscriptionStatusResponse = {
  creditable: boolean;
  paywallType?: PaywallType;
  subscriptionActive: boolean;
};

function handleInactiveSubscription(paywallType?: null | PaywallType): void {
  const redirectRoute = paywallType
    ? PAYWALL_ROUTES[paywallType]
    : DEFAULT_REDIRECT_ROUTE;

  throw redirect({
    to: redirectRoute,
  });
}

export async function redirectOnInvalidSubscription({
  projectId,
  queryClient,
}: {
  projectId?: number;
  queryClient: QueryClient;
}): Promise<void> {
  const userData = queryClient.getQueryData<GetMeUserResponse>(["user"]);

  if (!userData) return;

  const user = userData;
  const currentProject = user.projects.find(
    (p) => p.id === user.currentProjectId,
  );

  // Check current project's subscription status
  if (currentProject) {
    if (!currentProject.subscriptionStatus.subscriptionActive) {
      handleInactiveSubscription(currentProject.subscriptionStatus.paywallType);
    }

    return;
  }

  // If no current project but projectId is provided, check via API
  if (projectId) {
    const subscriptionStatus = await fetchSubscriptionStatus(projectId);

    if (!subscriptionStatus.subscriptionActive) {
      handleInactiveSubscription(subscriptionStatus.paywallType);
    }
  }
}

async function fetchSubscriptionStatus(
  projectId: number,
): Promise<SubscriptionStatusResponse> {
  return apiClient.url(`/projects/${projectId}/subscription`).get().json();
}
