import type { Path, UseFormReturn } from "react-hook-form";

import { DropZone } from "react-aria-components";
import { FileTrigger } from "react-aria-components";
import { Button } from "react-aria-components";

import { Label } from "@/components/field";

import type { Asset, RequestFormData } from "../types";

import { SizeAlert } from "../../components/SizeAlert";
import { useAssetsSection } from "../hooks/useAssetsSection";
import { useLinkForm } from "../useLinkForm";
import { ALLOWED_EXTENSIONS, isFileAsset } from "../utils/uploadUtils";
import { InsertLinkForm } from "./InsertLinkForm";
import { UploadAssetsInput } from "./UploadAssetsInput";
import { UploadedAsset } from "./UploadedAsset";
import { UploadedLink } from "./UploadedLink";

type AssetsFormProps<TFormValues extends RequestFormData> = {
  field: Path<TFormValues>;
  formMethods: UseFormReturn<TFormValues>;
  title: string;
};

export function AssetsForm<TFormValues extends RequestFormData>({
  field,
  formMethods,
  title,
}: AssetsFormProps<TFormValues>) {
  const assets = formMethods.watch(field);

  const {
    clearErrors,
    control,
    handleSubmit,
    setError: setLinkFormError,
  } = useLinkForm(assets);

  const {
    error,
    handleFileDelete,
    handleFileDrop,
    handleFileSelect,
    handleLinkAdd,
    handleLinkDelete,
  } = useAssetsSection<TFormValues>(
    field,
    assets,
    setLinkFormError,
    formMethods,
  );

  return (
    <div className="rounded-md bg-bg-secondary px-3xl py-2xl">
      <div className="flex flex-col gap-sm text-text-secondary">
        <span>{title}</span>
        <div className="flex flex-col gap-xl">
          <DropZone
            className={({ isDropTarget }) =>
              `group relative gap-xs rounded-xl border ${
                isDropTarget
                  ? "border-2 border-border-brand"
                  : "border-border-secondary"
              } bg-bg-primary px-3xl py-xl hover:bg-bg-primary-hover`
            }
            onDrop={handleFileDrop}
          >
            <FileTrigger
              acceptedFileTypes={ALLOWED_EXTENSIONS}
              allowsMultiple
              onSelect={handleFileSelect}
            >
              <Button className="outline-none">
                <UploadAssetsInput />
              </Button>
            </FileTrigger>
          </DropZone>

          {error && (
            <span className="text-sm text-text-error-primary">{error}</span>
          )}
          <SizeAlert />
          <InsertLinkForm
            clearErrors={clearErrors}
            control={control}
            onSubmit={handleSubmit(handleLinkAdd)}
          />
          {assets.length > 0 ? (
            <div className="flex flex-col gap-sm">
              <Label>Uploaded Links & Files</Label>
              <div className="flex flex-col gap-lg">
                {assets.map((asset: Asset) =>
                  isFileAsset(asset) ? (
                    <UploadedAsset
                      file={asset}
                      key={asset.id}
                      onDelete={handleFileDelete}
                    />
                  ) : (
                    <UploadedLink
                      key={asset.id}
                      link={asset}
                      onDelete={handleLinkDelete}
                    />
                  ),
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
