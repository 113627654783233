import BagIcon from "./assets/bag.svg?react";
import BrushIcon from "./assets/brush.svg?react";
import CodeIcon from "./assets/code.svg?react";
import ColorsIcon from "./assets/colors.svg?react";
import EditIcon from "./assets/edit.svg?react";
import FileIcon from "./assets/file.svg?react";
import FilmIcon from "./assets/film.svg?react";
import IntersectIcon from "./assets/intersect.svg?react";
import LaptopIcon from "./assets/laptop.svg?react";
import LayoutAltIcon from "./assets/layout-alt.svg?react";
import LayoutTop from "./assets/layout-top.svg?react";
import MailIcon from "./assets/mail.svg?react";
import MessageIcon from "./assets/message.svg?react";
import PackageIcon from "./assets/package.svg?react";
import PlayIcon from "./assets/play.svg?react";
import PodcastIcon from "./assets/podcast.svg?react";
import PresentationIcon from "./assets/presentation.svg?react";
import SaleIcon from "./assets/sale.svg?react";

export const SUBCATEGORY_ICONS_MAPPING = [
  { category: "graphic", icon: SaleIcon, id: 1, name: "Digital Ads" },
  { category: "graphic", icon: MessageIcon, id: 2, name: "Social Content" },
  { category: "graphic", icon: BagIcon, id: 3, name: "Amazon/Ecom" },
  { category: "graphic", icon: MailIcon, id: 4, name: "Email Graphics" },
  { category: "graphic", icon: LayoutAltIcon, id: 5, name: "Web Design" },
  { category: "graphic", icon: EditIcon, id: 6, name: "Vector Illustrations" },
  { category: "graphic", icon: PackageIcon, id: 7, name: "Packaging" },
  { category: "graphic", icon: ColorsIcon, id: 8, name: "Logo & Branding" },
  { category: "graphic", icon: FileIcon, id: 9, name: "Print Media" },
  {
    category: "graphic",
    icon: PresentationIcon,
    id: 10,
    name: "PDF & Presentations",
  },
  { category: "video", icon: PlayIcon, id: 11, name: "Motion Graphic Ads" },
  {
    category: "video",
    icon: PodcastIcon,
    id: 12,
    name: "Advanced Motion Graphics",
  },
  { category: "video", icon: BagIcon, id: 13, name: "Amazon/Ecom" },
  { category: "video", icon: FilmIcon, id: 14, name: "Video Editing" },
  { category: "webui", icon: LaptopIcon, id: 15, name: "Web Design" },
  { category: "webui", icon: IntersectIcon, id: 16, name: "UI Design" },
  { category: "webui", icon: CodeIcon, id: 17, name: "Implementation" },
] as const;

export const CATEGORY_ICONS_MAPPING = [
  {
    icon: BrushIcon,
    id: "graphic",
  },
  {
    icon: FilmIcon,
    id: "video",
  },
  {
    icon: LayoutTop,
    id: "webui",
  },
] as const;
