import type { ButtonProps as RACButtonProps } from "react-aria-components";

import { DialogTrigger } from "react-aria-components";

import { Button } from "@/components/button";
import {
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogFooter,
} from "@/components/dialog";
import { Modal } from "@/components/modal";

interface UpgradeModalProps {
  bodyText: string;
  children: React.ReactNode;
  headerText: string;
  heroImage: string;
  onClose?: RACButtonProps["onPress"];
  onConfirm?: RACButtonProps["onPress"];
}

export function UpgradeModal({
  bodyText,
  children,
  headerText,
  heroImage,
  onConfirm,
}: UpgradeModalProps) {
  return (
    <DialogTrigger>
      {children}
      <Modal isDismissable>
        <Dialog>
          {({ close }) => (
            <>
              <DialogBody className="gap-3xl">
                <img alt="" src={heroImage} />
                <div className="relative flex gap-xl text-center">
                  <DialogCloseButton className="absolute right-[-14px] top-[-14px] text-text-primary" />
                  <div className="mx-auto flex w-[464px] flex-col">
                    <span className="text-lg font-semibold text-text-primary">
                      {headerText}
                    </span>
                    <span className="text-sm text-text-tertiary">
                      {bodyText}
                    </span>
                  </div>
                </div>
              </DialogBody>

              <DialogFooter className="pt-4xl">
                <div className="flex w-full gap-lg">
                  <Button
                    className="w-full"
                    color="gray"
                    onPress={close}
                    variant="secondary"
                  >
                    Not Today
                  </Button>
                  <Button className="w-full" onPress={onConfirm}>
                    Upgrade Plan
                  </Button>
                </div>
              </DialogFooter>
            </>
          )}
        </Dialog>
      </Modal>
    </DialogTrigger>
  );
}
