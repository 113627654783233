import { Download, File, FileWarning, Loader, Trash2 } from "lucide-react";

import { Button } from "@/components/button";

import type { FileAsset } from "../types";

function handleDownload(file: File) {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");

  a.href = url;
  a.download = file.name;
  document.body.append(a);
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
}

type UploadedAssetProps = {
  file: FileAsset;
  onDelete: (attachmentId: number) => void;
};

function UploadingButtons() {
  return (
    <Button color="gray" isIconOnly size="sm" variant="tertiary">
      <Loader className="animate-spin text-border-brand-solid" />
    </Button>
  );
}

function SuccessButtons({ file, onDelete }: UploadedAssetProps) {
  return (
    <>
      <Button
        color="gray"
        isIconOnly
        onPress={() => onDelete(file.attachmentId as number)}
        size="sm"
        variant="tertiary"
      >
        <Trash2 className="text-button-tertiary-fg" />
      </Button>
      <Button
        color="gray"
        isIconOnly
        onPress={() => {
          if (file.awsDownloadUrl) {
            window.open(file.awsDownloadUrl, "_blank");
          } else {
            handleDownload(file.file);
          }
        }}
        size="sm"
        variant="tertiary"
      >
        <Download className="text-button-tertiary-color-fg" />
      </Button>
    </>
  );
}

function ErrorButtons({ file, onDelete }: UploadedAssetProps) {
  return (
    <>
      <Button
        color="gray"
        isIconOnly
        onPress={() => onDelete(file.attachmentId as number)}
        size="sm"
        variant="tertiary"
      >
        <Trash2 className="text-button-tertiary-fg" />
      </Button>
      <Button
        color="gray"
        isDisabled
        isIconOnly
        onPress={() => handleDownload(file.file)}
        size="sm"
        variant="tertiary"
      >
        <FileWarning className="text-text-error-primary" />
      </Button>
    </>
  );
}

export function UploadedAsset({ file, onDelete }: UploadedAssetProps) {
  return (
    <div className="flex items-center justify-between gap-xs rounded-xl border border-border-secondary bg-bg-primary px-xl py-md">
      <div className="flex items-center gap-md overflow-hidden">
        <File className="shrink-0 text-fg-quinary" size={16} />
        <span
          className="truncate text-sm font-medium text-text-secondary"
          title={file.file.name}
        >
          {file.file.name}
        </span>
      </div>
      <div className="flex shrink-0 gap-xs">
        {file.status === "uploading" && <UploadingButtons />}
        {file.status === "success" && (
          <SuccessButtons file={file} onDelete={onDelete} />
        )}
        {file.status === "error" && (
          <ErrorButtons file={file} onDelete={onDelete} />
        )}
      </div>
    </div>
  );
}
