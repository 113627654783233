import { createFileRoute, Outlet } from "@tanstack/react-router";
import { twMerge } from "tailwind-merge";

import { Navbar } from "@/components/navbar";
import { Sidebar } from "@/components/sidebar";
import { useSidebarCollapsed } from "@/hooks/useSidebarCollapsed";

export function LayoutComponent() {
  const { handleToggleCollapse, isCollapsed } = useSidebarCollapsed();

  return (
    <div className="flex min-h-screen">
      <Sidebar
        isCollapsed={isCollapsed}
        onToggleCollapse={handleToggleCollapse}
      />
      <div
        className={twMerge(["flex-1", isCollapsed ? "ml-8xl" : "ml-[220px]"])}
      >
        <Navbar />
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_auth/_app-shell")({
  component: LayoutComponent,
});
