import type { DuplicateRequestResponse } from "@nlc/shared-types";
import type { DefaultValues, UseFormReset } from "react-hook-form";

import { Delta } from "quill";

import type { CreateWebsiteImplementationRequestFormData } from "../components/useCreateWebsiteImplementationRequestForm";

import {
  convertDesignBriefToDeltaForDuplicate,
  mapAttachmentsByCategory,
  useDuplicateRequest,
} from "../../shared/useDuplicateRequest";

function mapDuplicateResponseToFormData(
  data: DuplicateRequestResponse,
): DefaultValues<CreateWebsiteImplementationRequestFormData> {
  return {
    assets: mapAttachmentsByCategory(data.attachments, "asset"),
    brandId: data.brandId || undefined,
    collaboratorsIds: data.collaboratorIds?.map(String) || [],
    designComplexityId: data.complexityId || undefined,
    designTypeId: undefined,
    inspirations: mapAttachmentsByCategory(data.attachments, "example"),
    preferredDesignersIds: data.preferredDesignerIds?.map(String) || [],
    requestDescription: data.description
      ? convertDesignBriefToDeltaForDuplicate(data.description)
      : new Delta(),
    requestDescriptionHTML: data.description || "",
    requestName: data.name || "",
  };
}

interface UseDuplicateWebsiteImplementationRequestProps {
  reset: UseFormReset<CreateWebsiteImplementationRequestFormData>;
}

export function useDuplicateWebsiteImplementationRequest({
  reset,
}: UseDuplicateWebsiteImplementationRequestProps) {
  return useDuplicateRequest<CreateWebsiteImplementationRequestFormData>({
    mapDuplicateResponseToFormData,
    reset,
  });
}
