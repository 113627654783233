import { SearchField, SearchInput } from "@/components/search-field";

export function SearchBar({
  inputValue,
  setInputValue,
}: {
  inputValue: string;
  setInputValue: (value: string) => void;
}) {
  return (
    <div className="bg-bg-primary py-3">
      <SearchField className="px-xl">
        <SearchInput
          onChange={(e) => setInputValue(e.target.value)}
          onClear={() => setInputValue("")}
          placeholder="Search"
          value={inputValue}
        />
      </SearchField>
    </div>
  );
}
