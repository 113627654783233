import type {
  GroupingState,
  RowSelectionState,
  SortingState,
  Updater,
} from "@tanstack/react-table";

import {
  type ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useMemo } from "react";

import { Checkbox } from "@/components/checkbox";

import type { FileItem, View } from "./types";

const FILE_ITEMS: FileItem[] = [
  {
    createdAt: "Jan 2, 2025",
    id: 1,
    name: "Archive",
    size: 125,
    type: "folder",
  },
  {
    createdAt: "Jan 2, 2025",
    id: 2,
    name: "Source files",
    size: 12_500,
    type: "folder",
  },
  {
    createdAt: "Jan 2, 2025",
    extension: "jpg",
    id: 3,
    name: "1.01 - 1080x1080 - Account Name - DesignerInitials - Request ID.jpg",
    size: 125_000,
    type: "file",
  },
  {
    createdAt: "Jan 2, 2025",
    extension: "png",
    id: 4,
    name: "3.01 - 1080x1080 - Account Name - DesignerInitials - Request ID.png",
    size: 125_000_000,
    type: "file",
  },
];

const columnHelper = createColumnHelper<FileItem>();

type UseMyFilesTableProps = {
  grouping: GroupingState;
  rowSelection: RowSelectionState;
  setGrouping: (grouping: Updater<GroupingState>) => void;
  setRowSelection: (rowSelection: Updater<RowSelectionState>) => void;
  view: View;
};

export function useMyFilesTable({
  grouping,
  rowSelection,
  setGrouping,
  setRowSelection,
  view,
}: UseMyFilesTableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo(
    () =>
      [
        // Selection column
        columnHelper.display({
          cell: ({ row }) => (
            <Checkbox
              aria-label={`Select ${row.original.name}`}
              isIndeterminate={row.getIsSomeSelected()}
              isSelected={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
              size="md"
            />
          ),
          enableSorting: false,
          header: ({ table }) => (
            <Checkbox
              aria-label="Select all"
              isIndeterminate={table.getIsSomeRowsSelected()}
              isSelected={table.getIsAllRowsSelected()}
              onChange={table.toggleAllRowsSelected}
              size="md"
            />
          ),
          id: "select",
          maxSize: 20,
          size: 20,
        }),
        // Type column (hidden, used for grouping in grid view)
        columnHelper.accessor("type", {
          enableColumnFilter: false,
          enableSorting: true,
          header: "Type",
          id: "type",
        }),
        // Name column
        columnHelper.accessor("name", {
          cell: (info) => info.getValue(),
          enableSorting: true,
          header: "Name",
        }),
        // Last modified column
        columnHelper.accessor("createdAt", {
          cell: (info) => info.getValue(),
          enableSorting: true,
          header: "Last modified",
          maxSize: 146,
          size: 146,
        }),
        // File type column
        columnHelper.accessor(
          (row) =>
            row.type === "file" ? row.extension?.toUpperCase() || "" : "Folder",
          {
            cell: (info) => info.getValue(),
            header: "File Type",
            id: "fileType",
            maxSize: 100,
            size: 100,
          },
        ),
        // File size column
        columnHelper.accessor("size", {
          cell: (info) => info.getValue(),
          header: "File Size",
          maxSize: 96,
          size: 96,
        }),
      ] as ColumnDef<FileItem>[],
    [],
  );

  return useReactTable({
    columns,
    data: FILE_ITEMS,
    enableExpanding: view === "grid",
    enableRowSelection: true,
    enableSubRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    groupedColumnMode: view === "grid" ? "reorder" : false,
    onGroupingChange: setGrouping,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    state: {
      columnVisibility: {
        type: false, // This is a technical column used for grouping in grid view
      },
      grouping,
      rowSelection,
      sorting,
    },
  });
}
