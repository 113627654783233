import { createFileRoute } from "@tanstack/react-router";

import { PaywallLayout } from "@/features/subscription/components/paywall-layout";

export const Route = createFileRoute("/_auth/_app-shell/paywall/no-credits")({
  component: NoCreditsPaywall,
});

function NoCreditsPaywall() {
  return (
    <PaywallLayout
      actionText="Upgrade now for unlimited creativity"
      actionUrl="https://nlc.com/manage-my-account/"
      message={
        <span>
          Whoops! You&apos;re out of design credits.
          <br />
          Please upgrade your plan or contact support for assistance.
        </span>
      }
    />
  );
}
