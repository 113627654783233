import type { SubmitRequestUnion } from "@nlc/shared-types";
import type { UseFormReturn } from "react-hook-form";

import { RequestTypeEnum } from "@nlc/shared-types";
import { submitRequestUnionZod } from "@nlc/shared-types";
import { useMutation } from "@tanstack/react-query";

import { toast } from "@/components/toast/toast-queue";
import { countNonEmptyFields } from "@/hooks/posthog/countNonEmptyFields";
import {
  formTypeMap,
  useCapturePosthogEvent,
} from "@/hooks/posthog/useCapturePosthogEvent";
import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";
import { useUser } from "@/hooks/useUser";
import authenticatedApi from "@/lib/api/client";

import type { CreateVideoRequestFormData } from "../components/useCreateVideoRequestForm";

import { useIsMBCSubmitted } from "../../shared/useIsMBCSubmitted";
import { getValidAttachmentIds } from "../../shared/utils";

type UseSubmitMutationProps = {
  formMethods: UseFormReturn<CreateVideoRequestFormData>;
  latestRequestId: { requestId: number };
};

export function useSubmitMutation({
  formMethods,
  latestRequestId,
}: UseSubmitMutationProps) {
  const { data: user } = useUser();
  const resetFormDirty = useIsRequestFormDirty((state) => state.reset);
  const setIsMBCSubmitted = useIsMBCSubmitted((state) => state.setIsSubmitted);
  const capture = useCapturePosthogEvent();

  return useMutation({
    mutationFn: async (formData: CreateVideoRequestFormData) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const commonFields: SubmitRequestUnion = {
        attachmentIds: [
          ...getValidAttachmentIds(formData.assets),
          ...getValidAttachmentIds(formData.inspirations),
        ],
        brandId: formData.brand,
        collaboratorIds: formData.collaborators.map(Number),
        complexityId: formData.designComplexity,
        creativeDirection: formData.creativeDirection,
        designTypeId: formData.videoType,
        lengthOrPlatform: formData.lengthOrPlatform,
        musicGenre: formData.musicGenre,
        name: formData.requestName,
        preferredDesignerIds: formData.preferredDesigners.map(Number),
        preferredFileType: formData.fileTypes,
        requestType: RequestTypeEnum.VIDEO,
        sizesNeeded: formData.sizes,
        styleOfVideo: formData.style,
      };

      const mbcFields = formData.briefCreatorDetailed;

      const briefTypeFields =
        formData.briefCreatorType === "detailed"
          ? {
              briefText: mbcFields.finalBriefHTML,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              textForDesign: mbcFields.script,
            }
          : {
              briefText: undefined,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              textForDesign: formData.briefCreatorSingle.textsForDesignHTML,
            };

      const magicalBriefCreatorFields =
        formData.briefCreatorType === "detailed"
          ? {
              magicalBriefCreator: {
                additionalGuidelines: mbcFields.additionalGuidelines,
                brandGuidelines: mbcFields.brandGuidelines,
                creativeGoals: mbcFields.creativeGoal,
                scope: mbcFields.scope,
                script: mbcFields.script,
              },
            }
          : {};

      const parsed = submitRequestUnionZod.parse({
        ...commonFields,
        ...briefTypeFields,
        ...magicalBriefCreatorFields,
      });

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}/submit`)
        .post(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error: { message: string; statusCode: number }) => {
      console.error("Failed to submit request:", error);

      if (error.message.includes("Request name already exists")) {
        formMethods.setError("requestName", {
          message: "Request name already exists",
        });
        formMethods.setFocus("requestName");
      }

      if (error.statusCode === 400) {
        const errorMessage = error.message;

        if (
          [
            "No credits available",
            "Subscription ended",
            "Subscription is cancelled",
          ].includes(errorMessage)
        ) {
          window.location.href = "/requests";

          return;
        }
      }

      toast.add(
        {
          description: error.message,
          title: "Error submitting request",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onMutate: () => {
      formMethods.reset(formMethods.getValues());
      resetFormDirty();
    },
    onSuccess: () => {
      const { briefCreatorType: _briefCreatorType, ...formValues } =
        formMethods.getValues();

      capture("submit_request", {
        ai_usage_flag: formMethods.getValues("briefCreatorType") === "detailed",
        brief_type: "video",
        form_version: formTypeMap[formMethods.getValues("briefCreatorType")],
        input_fields_completed: countNonEmptyFields(formValues),
        submission_success: true,
      });

      setIsMBCSubmitted(false); // reset the MBC submission flag

      window.location.href =
        user &&
        ["admin", "collaborator", "owner"].includes(user.user.roleName || "")
          ? "/requests?queued=1"
          : "/requests";
    },
  });
}
