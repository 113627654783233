import type { GetUsersParams, GetUsersResponse } from "@nlc/shared-types";
import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";

import { usersKeys } from "@/hooks/queryKeys.ts";
import apiClient from "@/lib/api/client.ts";

export function useGetUsers(searchParams: GetUsersParams) {
  return useQuery<GetUsersResponse, DefaultError>({
    queryFn: async () =>
      await apiClient.url(`/users`).query(searchParams).get().json(),
    queryKey: usersKeys.usersBySearchParams(searchParams),
    refetchOnWindowFocus: false,
    staleTime: 30_000,
  });
}
