import type { ReactNode } from "react";

import { DownloadIcon, PencilLineIcon, Trash2Icon } from "lucide-react";

import type { UserRole } from "@/features/auth/permissions/config";

type RegularMenuItem = {
  destructive?: true;
  icon: ReactNode;
  label: string;
  onAction: () => void;
};

type SeparatorMenuItem = {
  separator: true;
};

type MenuItemType = RegularMenuItem | SeparatorMenuItem;

export const MENU_ITEMS: Partial<Record<UserRole, MenuItemType[]>> = {
  admin: [
    {
      icon: <DownloadIcon />,
      label: "Download",
      onAction: () => alert("open"),
    },
  ],
  designer: [
    {
      icon: <PencilLineIcon />,
      label: "Rename file",
      onAction: () => alert("open"),
    },
    {
      icon: <DownloadIcon />,
      label: "Download",
      onAction: () => alert("open"),
    },
  ],
  owner: [
    {
      icon: <DownloadIcon />,
      label: "Download",
      onAction: () => alert("open"),
    },
  ],
  "super-admin": [
    {
      icon: <PencilLineIcon />,
      label: "Rename file",
      onAction: () => alert("open"),
    },
    {
      icon: <DownloadIcon />,
      label: "Download",
      onAction: () => alert("open"),
    },
    {
      separator: true,
    },
    {
      destructive: true,
      icon: <Trash2Icon />,
      label: "Delete",
      onAction: () => alert("open"),
    },
  ],
};
