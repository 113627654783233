import type { QueryClient } from "@tanstack/react-query";

import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { CreateGraphicRequestForm } from "@/features/request/create/graphic";
import { CreateVideoRequestForm } from "@/features/request/create/video";
import { CreateWebsiteDesignRequestForm } from "@/features/request/create/website-design";
import { CreateWebsiteImplementationRequestForm } from "@/features/request/create/website-implementation";
import { redirectOnInvalidSubscription } from "@/features/subscription/redirect-on-invalid-subscription";

const requestCreateSchema = z.object({
  designType: z.number().optional(),
  ["duplicate_count"]: z.number().optional(),
  ["duplicate_from"]: z.number().optional(),
  ["include_attachments"]: z.number().optional(),
  ["include_collaborators"]: z.number().optional(),
  ["include_leadership_notes"]: z.number().optional(),
  ["project-id"]: z.number().optional(),
  ["request_id"]: z.number().optional(),
  ["request-type"]: z
    .enum(["video", "graphic", "website_design", "website_implementation"], {
      message: "Invalid request type",
    })
    .default("graphic"),
});

export const Route = createFileRoute("/_auth/_app-shell/requests/create")({
  component: Component,
  loader: async ({
    context: { queryClient },
    deps: { projectId },
  }: {
    context: { queryClient: QueryClient };
    deps: { projectId?: number };
  }) => {
    await redirectOnInvalidSubscription({
      projectId,
      queryClient,
    });
  },
  loaderDeps: ({ search: { "project-id": projectId } }) => ({
    projectId,
  }),
  validateSearch: requestCreateSchema,
});

function Component() {
  const { ["request-type"]: requestType } = Route.useSearch();

  return (
    <>
      <main className="min-h-screen bg-bg-tertiary py-4xl">
        {(() => {
          switch (requestType) {
            case "graphic": {
              return <CreateGraphicRequestForm />;
            }
            case "video": {
              return <CreateVideoRequestForm />;
            }
            case "website_design": {
              return <CreateWebsiteDesignRequestForm />;
            }
            case "website_implementation": {
              return <CreateWebsiteImplementationRequestForm />;
            }
            default: {
              return null;
            }
          }
        })()}
      </main>
    </>
  );
}
