import { HelpCircle } from "lucide-react";
import { Button, TooltipTrigger } from "react-aria-components";

import { Label } from "@/components/field";
import { Tooltip } from "@/components/tooltip";
import { ControlledQuillEditor } from "@/components/ui/controlled/ControlledQuillEditor";

import { useCreateWebsiteImplementationRequestFormContext } from "../useCreateWebsiteImplementationRequestForm";

export function BriefCreatorSection() {
  const methods = useCreateWebsiteImplementationRequestFormContext();

  return (
    <div className="rounded-xl bg-bg-secondary p-3xl">
      <div className="grid grid-cols-1 grid-rows-[min-content_min-content] gap-x-3xl gap-y-lg">
        <div className="h-[530px]">
          <div className="mb-xs flex items-center justify-between">
            <Label requiredHint>📄 Request Description</Label>
            <TooltipTrigger delay={0}>
              <Button
                aria-label="Show tooltip"
                className="cursor-default outline-none"
              >
                <HelpCircle className="size-xl text-fg-quinary transition-all duration-300 hover:text-fg-quinary" />
              </Button>
              <Tooltip offset={0}>
                Describe the overall purpose and specific requirements of your
                design request. Include any critical project details that will
                guide the creation process.
              </Tooltip>
            </TooltipTrigger>
          </div>

          <ControlledQuillEditor
            control={methods.control}
            name="requestDescription"
            onChangeHTML={(html) => {
              methods.setValue("requestDescriptionHTML", html);
            }}
            placeholder="e.g. Launch a social media campaign for eco-friendly skincare products to boost brand awareness and increase sign-ups."
          />
        </div>
      </div>
    </div>
  );
}
