import type { SuperAdminCategory } from "@nlc/shared-types";
import type { SortDescriptor } from "react-stately";

import { useState } from "react";

import { Loader } from "@/components/Loader";
import { Tab, TabList, TabPanel, Tabs } from "@/components/tabs";
import { Header } from "@/features/users/shared/components/Header";
import { SearchBar } from "@/features/users/shared/components/SearchBar.tsx";
import { TableFooter } from "@/features/users/shared/components/TableFooter.tsx";
import { useGetUsers } from "@/features/users/shared/hooks/useGetUsers.tsx";
import { ArchivedTable } from "@/features/users/super-admin/components/tables/ArchivedTable.tsx";
import { CustomersTable } from "@/features/users/super-admin/components/tables/CustomersTable.tsx";
import { CustomerSuccessTable } from "@/features/users/super-admin/components/tables/CustomerSuccessTable.tsx";
import { DesignersTable } from "@/features/users/super-admin/components/tables/DesignersTable.tsx";
import { SuperAdminsTable } from "@/features/users/super-admin/components/tables/SuperAdminsTable.tsx";
import { TeamLeadersTable } from "@/features/users/super-admin/components/tables/TeamLeadersTable.tsx";
import { useDebounce } from "@/hooks/useDebounce.ts";

export function SuperAdminUsers() {
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "createdOn",
    direction: "ascending",
  });
  const [category, setCategory] = useState<SuperAdminCategory>("customers");

  const debouncedQuery = useDebounce(inputValue, 500, ()=>setPage(1));

  const direction: "asc" | "desc" =
    sortDescriptor.direction === "descending" ? "asc" : "desc";

  const searchParams = {
    category,
    direction,
    itemsPerPage,
    page,
    search: debouncedQuery,
    sortBy: sortDescriptor.column as string,
  };

  const { data, isFetching } = useGetUsers(searchParams);

  const rows = data?.data.superAdmin || [];
  const { totalItems } = data?.metadata ?? { totalItems: 0 };

  return (
    <>
      <Header />
      <Tabs
        className="border-t border-t-border-secondary"
        onSelectionChange={(key) => {
          setSortDescriptor({ column: "createdOn", direction: "ascending" });
          setCategory(key as SuperAdminCategory);
          setPage(1);
        }}
      >
        <div className="px-6 py-3">
          <TabList aria-label="User Management" className="flex flex-wrap">
            <Tab id="customers">Customers</Tab>
            <Tab id="designers">Designers</Tab>
            <Tab id="team-leaders">Team Leaders</Tab>
            <Tab id="customer-success">Customer Success</Tab>
            <Tab id="super-admins">Super Admins</Tab>
            <Tab id="archived">Archived</Tab>
          </TabList>
        </div>
        <SearchBar
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        {isFetching ? (
          <Loader className="py-20" />
        ) : (
          <>
            <TabPanel id="customers" >
              <CustomersTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="designers">
              <DesignersTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="team-leaders">
              <TeamLeadersTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="customer-success">
              <CustomerSuccessTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="super-admins">
              <SuperAdminsTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="archived">
              <ArchivedTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
          </>
        )}
      </Tabs>

      <TableFooter
        itemsPerPage={itemsPerPage}
        page={page}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        totalItems={totalItems}
      />
    </>
  );
}
