import type { Dispatch, SetStateAction } from "react";
import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useSearch } from "@tanstack/react-router";
import { convert } from "html-to-text";
import { Delta } from "quill";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import * as z from "zod";

import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";
import { useUser } from "@/hooks/useUser";

import { fileAssetSchema, linkAssetSchema } from "../../shared/utils";

const createWebsiteImplementationRequestFormSchema = (userRole: string) =>
  z.object({
    /* eslint-disable perfectionist/sort-objects */
    requestName: z
      .string()
      .min(1, { message: "Request name is required" })
      .max(255, { message: "Request name is too long" }),
    designTypeId: z.number({
      required_error: "Design type is required",
    }),
    brandId: z.coerce.number().optional(),
    requestDescriptionHTML: z
      .string()
      .refine((value) => convert(value).trim().length > 0, {
        message: "Request description is required",
      })
      .refine((value) => convert(value).length < 64_000, {
        message: "Final brief text is too long",
      }),
    requestDescription: z.instanceof(Delta),
    // Assets and inspirations
    assets: z.array(z.union([fileAssetSchema, linkAssetSchema])),
    inspirations: z.array(z.union([fileAssetSchema, linkAssetSchema])),
    // Technical details
    preferredDesignersIds: z.array(z.string()),
    designComplexityId: [
      "account-manager",
      "super-admin",
      "team-leader",
    ].includes(userRole)
      ? z.number({ required_error: "Design complexity is required" })
      : z.any().optional(),
    collaboratorsIds: z.array(z.string()),
    /* eslint-enable perfectionist/sort-objects */
  });

export type CreateWebsiteImplementationRequestFormData = z.infer<
  ReturnType<typeof createWebsiteImplementationRequestFormSchema>
>;

export function useCreateWebsiteImplementationRequestForm(): {
  setShowWarningModal: Dispatch<SetStateAction<boolean>>;
  showWarningModal: boolean;
} & UseFormReturn<CreateWebsiteImplementationRequestFormData> {
  const { data: user } = useUser();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const setIsDirty = useIsRequestFormDirty((state) => state.setIsDirty);
  const { designType } = useSearch({
    from: "/_auth/_app-shell/requests/create",
  });

  const form = useForm<CreateWebsiteImplementationRequestFormData>({
    defaultValues: {
      /* eslint-disable perfectionist/sort-objects */
      requestName: "",
      designTypeId: designType,
      brandId: undefined,
      requestDescriptionHTML: "",
      requestDescription: new Delta(),
      assets: [],
      inspirations: [],
      preferredDesignersIds: [],
      designComplexityId: undefined,
      collaboratorsIds: [],
      /* eslint-enable perfectionist/sort-objects */
    },
    resolver: zodResolver(
      createWebsiteImplementationRequestFormSchema(
        user?.user.roleName || "",
      ).superRefine((data, ctx) => {
        if (convert(data.requestDescriptionHTML).length >= 5000) {
          // 5000 characters of text (html tags removed)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Request description text is too long",
            path: ["requestDescription"],
          });
        }

        if (convert(data.requestDescriptionHTML).trim().length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Request description is required",
            path: ["requestDescription"],
          });
        }
      }),
    ),
  });

  // Handle beforeunload event to prevent unsaved changes from being lost
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";

        return event.returnValue;
      }
    },
    [form.formState.isDirty],
  );

  useEffect(() => {
    setIsDirty(form.formState.isDirty);
  }, [form.formState.isDirty, setIsDirty]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  useEffect(() => {
    const unsubscribe = useIsRequestFormDirty.subscribe(
      (state) => state.isDirty,
      (isDirty) => {
        if (!isDirty) {
          form.reset(form.getValues());
        }
      },
    );

    return () => unsubscribe();
  }, [form]);

  return {
    ...form,
    setShowWarningModal,
    showWarningModal,
  };
}

export function useCreateWebsiteImplementationRequestFormContext() {
  return useFormContext<CreateWebsiteImplementationRequestFormData>();
}
