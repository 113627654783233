import type { Delta } from "quill";

import Quill from "quill";
import { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import "./styles.css";

type Range = { index: number; length: number } | null;
type Source = "api" | "silent" | "user";

// Definition for Quill's formats/link module
interface LinkFormatStatic {
  create(value: string): HTMLElement;
  formats(domNode: HTMLElement): boolean | string;
}

type QuillEditorProps = {
  className?: string;
  isError?: boolean;
  onChange?: (value: Delta) => void;
  onSelectionChange: (range: Range, source: Source, editor: Quill) => void;
  onTextChange: (delta: Delta, oldContents: Delta, source: Source) => void;
  placeholder?: string;
  readOnly: boolean;
  value?: Delta;
};

const defaultModules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "link",
      { list: "ordered" },
      { list: "bullet" },
    ],
  ],
};

const QuillEditor = forwardRef<Quill, QuillEditorProps>(
  (
    {
      className,
      isError,
      onChange,
      onSelectionChange,
      onTextChange,
      placeholder,
      readOnly,
      value,
    },
    forwardedRef,
  ) => {
    const containerRef = useRef<HTMLElement | null>(null);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const onChangeRef = useRef(onChange);
    const quillRef = useRef<null | Quill>(null);
    const isUserChange = useRef(false);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
      onChangeRef.current = onChange;
    });

    useEffect(() => {
      if (quillRef.current) {
        quillRef.current.enable(!readOnly);
      }
    }, [readOnly]);

    // Update editor content when value prop changes
    useEffect(() => {
      if (!quillRef.current || !value || isUserChange.current) {
        return;
      }

      const editor = quillRef.current;
      const currentContents = editor.getContents();
      const incomingDelta = value;

      const currentString = JSON.stringify(currentContents);
      const incomingString = JSON.stringify(incomingDelta);

      if (currentString !== incomingString) {
        editor.setContents(incomingDelta, "api");
      }
    }, [value]);

    useEffect(() => {
      const container = containerRef.current;

      if (!container) return;

      const editorContainer = document.createElement("article");

      container.append(editorContainer);

      // Customize the link handling - disable link tooltip for editing
      // We need to use type assertion here because Quill's typings don't fully define the formats
      const LinkModule = Quill.import(
        "formats/link",
      ) as unknown as LinkFormatStatic & (new () => object);

      // Override default link click behavior with a custom class
      class CustomLink extends LinkModule {
        static create(value: string) {
          const node = LinkModule.create.call(this, value);

          if (node) {
            node.setAttribute("target", "_blank");
            node.setAttribute("rel", "noopener noreferrer");
          }

          return node;
        }
      }

      // Register our custom link handler
      Quill.register("formats/link", CustomLink, true);

      const quill = new Quill(editorContainer, {
        bounds: container,
        modules: defaultModules,
        placeholder: placeholder ?? "Start writing...",
        theme: "snow",
      });

      quillRef.current = quill;
      if (typeof forwardedRef === "function") {
        forwardedRef(quill);
      } else if (forwardedRef) {
        forwardedRef.current = quill;
      }

      if (value) {
        quill.setContents(value, "api");
      }

      // Add event listeners for tooltip positioning - only for new link creation
      const updateTooltipPosition = () => {
        // Find only the link creation tooltip, not the editing one
        const tooltip = editorContainer.querySelector(
          ".ql-tooltip[data-mode='link']",
        );
        const editorElement = editorContainer.querySelector(".ql-editor");

        if (
          tooltip instanceof HTMLElement &&
          editorElement instanceof HTMLElement
        ) {
          // Get editor dimensions to calculate boundaries
          const editorRect = editorElement.getBoundingClientRect();

          // Fixed position at the top of visible area
          tooltip.style.position = "fixed";
          tooltip.style.top = `${editorRect.top + 10}px`;
          tooltip.style.margin = "0px";
          tooltip.style.left = `${editorRect.left + 10}px`;
          tooltip.style.width = "auto";
          tooltip.style.maxWidth = `${editorRect.width - 20}px`;

          // Add class for additional CSS styling
          tooltip.classList.add("quill-fixed-tooltip");
        }
      };

      // Setup MutationObserver to detect when tooltip appears
      const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            const tooltip = editorContainer.querySelector(
              ".ql-tooltip[data-mode='link']",
            );

            if (tooltip) {
              updateTooltipPosition();
            }
          }
        }
      });

      // Start observing the editor container for tooltip additions
      observer.observe(editorContainer, {
        childList: true,
        subtree: true,
      });

      // Block default link click behavior to prevent editing tooltips
      const preventLinkEdit = (e: Event) => {
        const target = e.target as HTMLElement;

        // If clicking on a link, prevent default Quill behavior
        if (target.tagName === "A" || target.closest("a")) {
          // Stop propagation to prevent Quill from showing edit tooltip
          e.stopPropagation();
          e.preventDefault();

          // Get the href and open in new tab if it's a real link
          const href =
            target.getAttribute("href") ||
            target.closest("a")?.getAttribute("href");

          if (href) {
            // If user wants to open the link, let them do so in a new tab
            window.open(href, "_blank", "noopener,noreferrer");
          }
        }
      };

      // Add click event listener to intercept link clicks
      const editorContent = editorContainer.querySelector(".ql-editor");

      if (editorContent) {
        editorContent.addEventListener("click", preventLinkEdit, true);
      }

      // Function to handle link toolbar button click
      const handleLinkToolbarClick = () => {
        setTimeout(() => {
          updateTooltipPosition();
        }, 0);
      };

      // Attach click handler to link button in toolbar
      const linkButton = editorContainer.querySelector("button.ql-link");

      if (linkButton) {
        linkButton.addEventListener("click", handleLinkToolbarClick);
      }

      // Add scroll event listener to reposition the tooltip
      const editorElement = editorContainer.querySelector(".ql-editor");

      if (editorElement) {
        editorElement.addEventListener("scroll", updateTooltipPosition);
      }

      // Add event listener for window resize to update tooltip position
      window.addEventListener("resize", updateTooltipPosition);

      // Add click event listener for quill-stories elements
      editorContainer.addEventListener("click", (e) => {
        const target = e.target as HTMLElement;

        if (target.id && target.id.includes("quill-stories")) {
          updateTooltipPosition();
        }
      });

      // Add event listeners for all link toolbar elements
      const setupLinkClickHandlers = () => {
        const links = document.querySelectorAll(".ql-link");

        for (const link of links) {
          link.addEventListener("click", updateTooltipPosition);
        }
      };

      // Set up initial link handlers
      setupLinkClickHandlers();

      // Re-attach link handlers when toolbar modifications might occur
      quill.on("text-change", setupLinkClickHandlers);

      quill.on(
        Quill.events.TEXT_CHANGE,
        (delta: Delta, oldContents: Delta, source: Source) => {
          if (source === "user") {
            isUserChange.current = true;
          }

          // Call the original onTextChange
          onTextChangeRef.current?.(delta, oldContents, source);

          // Update the controlled value through onChange
          if (source === "user") {
            const newContents = quill.getContents();

            onChangeRef.current?.(newContents);

            setTimeout(() => {
              isUserChange.current = false;
            }, 0);
          }
        },
      );

      quill.on(
        Quill.events.SELECTION_CHANGE,
        (range: Range, source: Source) => {
          onSelectionChangeRef.current?.(range, source, quill);
        },
      );

      return () => {
        // Clean up event listeners
        editorContainer
          .querySelector(".ql-editor")
          ?.removeEventListener("scroll", updateTooltipPosition);

        window.removeEventListener("resize", updateTooltipPosition);

        // Remove editor content click handler
        const editorContent = editorContainer.querySelector(".ql-editor");

        if (editorContent) {
          editorContent.removeEventListener("click", preventLinkEdit, true);
        }

        const linkButton = editorContainer.querySelector("button.ql-link");

        if (linkButton) {
          linkButton.removeEventListener("click", handleLinkToolbarClick);
        }

        // Disconnect observer
        observer.disconnect();

        quillRef.current = null;
        if (typeof forwardedRef === "function") {
          forwardedRef(null);
        } else if (forwardedRef) {
          forwardedRef.current = null;
        }
        container.innerHTML = "";
      };
    }, [forwardedRef]);

    return (
      <section
        className={twMerge(
          "quill-wrapper h-[calc(100%-30px)]",
          isError && "error",
          className,
        )}
        ref={containerRef}
      ></section>
    );
  },
);

QuillEditor.displayName = "Editor";

export default QuillEditor;
