import type { SortDescriptor } from "react-stately";

import { useState } from "react";

import { Loader } from "@/components/Loader";
import { Tab, TabList, TabPanel, Tabs } from "@/components/tabs";
import { Header } from "@/features/users/shared/components/Header";
import { SearchBar } from "@/features/users/shared/components/SearchBar.tsx";
import { TableFooter } from "@/features/users/shared/components/TableFooter.tsx";
import { useGetUsers } from "@/features/users/shared/hooks/useGetUsers.tsx";
import { ArchivedTable } from "@/features/users/team-leader/components/tables/ArchivedTable.tsx";
import { AssistantTeamLeaderTable } from "@/features/users/team-leader/components/tables/AssistantTeamLeaderTable.tsx";
import { CustomersTable } from "@/features/users/team-leader/components/tables/CustomersTable.tsx";
import { DesignersTable } from "@/features/users/team-leader/components/tables/DesignersTable.tsx";
import { useDebounce } from "@/hooks/useDebounce.ts";

export function TeamLeaderUsers() {
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "createdOn",
    direction: "ascending",
  });
  const [category, setCategory] = useState("customers");

  const debouncedQuery = useDebounce(inputValue, 500, () => setPage(1));

  const direction: "asc" | "desc" =
    sortDescriptor.direction === "descending" ? "asc" : "desc";

  const searchParams = {
    category,
    direction,
    itemsPerPage,
    page,
    search: debouncedQuery,
    sortBy: sortDescriptor.column as string,
  };

  const { data, isFetching } = useGetUsers(searchParams);

  const rows = data?.data.teamLeader || [];
  const { totalItems } = data?.metadata ?? { totalItems: 0 };

  return (
    <>
      <Header />
      <Tabs
        className="border-t border-t-border-secondary"
        onSelectionChange={(key) => {
          setSortDescriptor({ column: "createdOn", direction: "ascending" });
          setCategory(key as string);
          setPage(1);
        }}
      >
        <div className="px-6 py-3">
          <TabList aria-label="History of Ancient Rome">
            <Tab id="customers">Customers</Tab>
            <Tab id="designers">Designers</Tab>
            <Tab id="assistantTeamLeaders">Assistant Team Leaders</Tab>
            <Tab id="archived">Archived</Tab>
          </TabList>
        </div>
        <SearchBar
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        {isFetching ? (
          <Loader className="py-20" />
        ) : (
          <>
            <TabPanel id="customers">
              <CustomersTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="designers">
              <DesignersTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="assistantTeamLeaders">
              <AssistantTeamLeaderTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
            <TabPanel id="archived">
              <ArchivedTable
                rows={rows}
                setSortDescriptor={setSortDescriptor}
                sortDescriptor={sortDescriptor}
              />
            </TabPanel>
          </>
        )}
      </Tabs>

      <TableFooter
        itemsPerPage={itemsPerPage}
        page={page}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        totalItems={totalItems}
      />
    </>
  );
}
