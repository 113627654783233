import type { Notification } from "@nlc/shared-types";

import dayjs from "dayjs";
import { Separator } from "react-aria-components";

import { Avatar } from "@/components/avatar";
import { useUser } from "@/hooks/useUser";

import { useMarkNotificationAsRead } from "./hooks";

export function NotificationItem(notification: Notification) {
  const { data: user } = useUser();
  const { mutate: markAsRead } = useMarkNotificationAsRead();

  return (
    <>
      <a
        className="flex cursor-pointer gap-lg px-lg py-md hover:bg-utility-gray-200"
        href={notification.data.href}
        onClick={() => {
          if (notification.isNew) {
            markAsRead(notification.id);
          }
        }}
      >
        <Avatar alt={user?.user.name ?? ""} className="size-6xl rounded-full" />
        <div className="relative flex w-full flex-col">
          <div className="flex items-center gap-xs">
            <span className="text-sm font-medium text-text-secondary">
              {notification.data.title}
            </span>
            <span className="text-xs text-text-tertiary">
              {dayjs(notification.created_at).fromNow()}
            </span>
          </div>
          <div
            className="text-sm text-text-tertiary"
            dangerouslySetInnerHTML={{ __html: notification.data.message }}
          />
          <span className="text-xs text-text-tertiary">Reminder</span>
          {notification.isNew && (
            <div className="absolute right-0 top-0 size-2 rounded-full bg-fg-success-secondary" />
          )}
        </div>
      </a>
      <Separator className="my-px w-full self-center border-t border-border-secondary" />
    </>
  );
}
