import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import {
  ClipboardCheck,
  CreditCard,
  LogOut,
  Settings,
  User,
} from "lucide-react";

import { AccessibleIcon } from "@/components/accessible-icon";
import { Avatar } from "@/components/avatar";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemLabel,
  MenuPopover,
  MenuSeparator,
  MenuTrigger,
} from "@/components/menu";
import { Switch } from "@/components/switch";
import { env } from "@/config/env";
import { useTheme } from "@/hooks/useTheme";
import { useUser } from "@/hooks/useUser";
import apiClient from "@/lib/api/client";
import { shutdownIntercom } from "@/lib/intercom";

import { Notifications } from "./notifications";
import { ProjectsMenu } from "./projects-menu";
import { Search } from "./search";

export function Navbar() {
  const { isDark, toggleTheme } = useTheme();

  const { data: user } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function handleLogout() {
    try {
      shutdownIntercom();
      await apiClient.url("/auth/logout").post().res();
      queryClient.clear();
      navigate({ to: "/login" });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  return (
    <header className="sticky top-0 z-40 flex h-[72px] flex-row-reverse items-center justify-between border-b border-border-secondary bg-bg-primary px-4xl py-xl">
      <div className="flex gap-xl">
        <div className="flex gap-xs">
          <Search />
          <Notifications />
        </div>
        <MenuTrigger>
          <MenuButton noIndicator variant="unstyled">
            <Avatar alt={user?.user.name ?? ""} className="rounded-full" src={user?.user.avatar ?? ""} />
          </MenuButton>
          <MenuPopover offset={22}>
            <Menu>
              <ProjectsMenu />

              <MenuItem href={`${env.VITE_BASE_URL}/user/profile`}>
                <AccessibleIcon>
                  <User />
                </AccessibleIcon>
                <MenuItemLabel>Profile</MenuItemLabel>
              </MenuItem>
              {/* role_id = 2 is an owner */}
              {user?.user.roleId === 2 && (
                <MenuItem href={`${env.VITE_BASE_URL}/profile/billing`}>
                  <AccessibleIcon>
                    <CreditCard />
                  </AccessibleIcon>
                  <MenuItemLabel>Billing</MenuItemLabel>
                </MenuItem>
              )}
              <MenuItem
                href={`${env.VITE_BASE_URL}/profile/notification-settings`}
              >
                <AccessibleIcon>
                  <Settings />
                </AccessibleIcon>
                <MenuItemLabel>Notification Settings</MenuItemLabel>
              </MenuItem>
              <MenuSeparator />
              <MenuItem href={`${env.VITE_BASE_URL}/make-a-suggestion`}>
                <AccessibleIcon>
                  <ClipboardCheck />
                </AccessibleIcon>
                <MenuItemLabel>Make a suggestion</MenuItemLabel>
              </MenuItem>
              <MenuSeparator />
              <MenuItem
                // @ts-expect-error closeOnSelect is not supported according to the docs and is not in the types but it works somehow :D
                closeOnSelect={false}
                onAction={toggleTheme}
              >
                <div className="flex flex-row items-center gap-md">
                  <Switch isSelected={isDark}>Dark mode</Switch>
                  <span className="w-min rounded-full border border-utility-success-200 bg-utility-success-50 px-md py-xxs text-xs text-utility-success-700">
                    beta
                  </span>
                </div>
              </MenuItem>
              <MenuSeparator />
              <MenuItem onAction={handleLogout}>
                <AccessibleIcon>
                  <LogOut />
                </AccessibleIcon>
                <MenuItemLabel>Log out</MenuItemLabel>
              </MenuItem>
            </Menu>
          </MenuPopover>
        </MenuTrigger>
      </div>
    </header>
  );
}
