import type { UpdateRequestUnion } from "@nlc/shared-types";
import type { UseFormReturn } from "react-hook-form";

import { RequestTypeEnum, updateRequestUnionType } from "@nlc/shared-types";
import { useMutation } from "@tanstack/react-query";

import { toast } from "@/components/toast/toast-queue";
import { useCapturePosthogEvent } from "@/hooks/posthog/useCapturePosthogEvent";
import authenticatedApi from "@/lib/api/client";

import type { CreateWebsiteImplementationRequestFormData } from "../components/useCreateWebsiteImplementationRequestForm";

import { getValidAttachmentIds } from "../../shared/utils";

type UseSaveDraftMutationProps = {
  formMethods: UseFormReturn<CreateWebsiteImplementationRequestFormData>;
  latestRequestId: { requestId: number };
};

export function useSaveDraftMutation({
  formMethods,
  latestRequestId,
}: UseSaveDraftMutationProps) {
  const capture = useCapturePosthogEvent();

  return useMutation({
    mutationFn: async (
      formData: CreateWebsiteImplementationRequestFormData,
    ) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const body: UpdateRequestUnion = {
        attachmentIds: [
          ...getValidAttachmentIds(formData.assets),
          ...getValidAttachmentIds(formData.inspirations),
        ],
        brandId: formData.brandId,
        collaboratorIds: formData.collaboratorsIds.map(Number),
        complexityId: formData.designComplexityId,
        description: formData.requestDescriptionHTML,
        designTypeId: formData.designTypeId,
        name: formData.requestName,
        preferredDesignerIds: formData.preferredDesignersIds.map(Number),
        requestType: RequestTypeEnum.WEBSITE_IMPLEMENTATION,
      };
      const parsed = updateRequestUnionType.parse(body);

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}/submit`)
        .post(parsed)
        .json<{ message: string; request_data: unknown }>();

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}`)
        .patch(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error) => {
      console.error("Failed to save draft:", error);

      if (error.message.includes("Request name already exists")) {
        formMethods.setError("requestName", {
          message: "Request name already exists",
        });
        formMethods.setFocus("requestName");
      }

      toast.add(
        {
          description: error.message,
          title: "Error saving draft",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onSuccess: () => {
      capture("brief_draft_saved", {
        draft_id: latestRequestId.requestId,
      });

      toast.add(
        {
          title: "Draft saved successfully",
          type: "success",
        },
        {
          timeout: 5000,
        },
      );
    },
  });
}
