import type {
  GetRequestsQuerystring,
  GetRequestsResponse,
} from "@nlc/shared-types";

import { useQuery } from "@tanstack/react-query";

import { requestListKeys } from "@/hooks/queryKeys";
import apiClient from "@/lib/api/client";

type UseGetRequestListProps = GetRequestsQuerystring;

export function useGetRequestList({
  category,
  fields,
  projectId,
}: UseGetRequestListProps) {
  return useQuery({
    queryFn: () =>
      apiClient
        .url("/requests")
        .query({
          category: category,
          fields: fields,
          isDraft: false,
          projectId: projectId,
        })
        .get()
        .json<GetRequestsResponse>(),
    queryKey: requestListKeys.requestListByProjectId(projectId, category),
  });
}
