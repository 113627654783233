import type {
  Path,
  PathValue,
  UseFormReturn,
  UseFormSetError,
} from "react-hook-form";

import { nanoid } from "nanoid";

import authenticatedApi from "@/lib/api/client";

import type { Asset, RequestFormData } from "../types";
import type { LinkFormValues } from "../useLinkForm";

import { LINK_TYPE, MAX_FILES } from "../utils/uploadUtils";

export function useLinkManagement<TFormValues extends RequestFormData>(
  field: Path<TFormValues>,
  assets: Asset[],
  methods: UseFormReturn<TFormValues>,
  setError: (error: null | string) => void,
  setLinkFormError: UseFormSetError<LinkFormValues>,
): {
  handleLinkAdd: (url: { link: string }) => void;
  handleLinkDelete: (linkId: string) => void;
} {
  const handleLinkAdd = async (url: { link: string }) => {
    if (assets.length >= MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files.`);

      // Hack to set useLinkForm formState.isSubmitSuccessful to false and prevent it from resetting the form
      setLinkFormError("root", {
        type: "added-assets-limit-reached",
      });

      return;
    }

    const response = await authenticatedApi
      .url("/attachments/upload-link")
      .post({
        type: LINK_TYPE[field as keyof typeof LINK_TYPE],
        url: url.link,
      })
      .json<{ attachmentId: number }>();

    methods.setValue(field, [
      { attachmentId: response.attachmentId, id: nanoid(), url: url.link },
      ...assets,
    ] as PathValue<TFormValues, Path<TFormValues>>);
  };

  const handleLinkDelete = (linkId: string) => {
    setError(null);

    const filteredAssets = assets.filter((asset) => asset.id !== linkId);

    methods.setValue(
      field,
      filteredAssets as PathValue<TFormValues, Path<TFormValues>>,
    );
  };

  return { handleLinkAdd, handleLinkDelete };
}
