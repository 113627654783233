import type { RequestCategory } from "@nlc/shared-types";

import { useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";

export function usePickTypeState() {
  const {
    category = "graphic",
    ["project-id"]: projectIdFromSearch,
    subcategory = 0,
  } = useSearch({
    from: "/_auth/_app-shell/requests/pick-type",
  });
  const projectId = useGetCurrentProjectId();
  const navigate = useNavigate({ from: "/requests/pick-type" });

  useEffect(() => {
    // Reset search params when projectId changes
    if (!projectIdFromSearch) {
      navigate({
        search: {
          category: "graphic",
          subcategory: 0,
        },
      });
    }
  }, [projectIdFromSearch, projectId, navigate]);

  const handleCategoryChange = (newCategory: string) => {
    navigate({
      search: (prev) => ({
        ...prev,
        category: newCategory as RequestCategory,
        subcategory: 0,
      }),
    });
  };

  const handleSubcategoryChange = (newSubcategory: string) => {
    navigate({
      search: (prev) => ({
        ...prev,
        subcategory: Number(newSubcategory),
      }),
    });
  };

  return {
    category,
    handleCategoryChange,
    handleSubcategoryChange,
    subcategory: subcategory.toString(),
  };
}
