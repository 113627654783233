import { LayoutGridIcon, ListIcon } from "lucide-react";

import { ToggleButton, ToggleButtonGroup } from "@/components/button";

import type { View } from "../types";

type ToggleViewButtonsProps = {
  setGrouping: (grouping: string[]) => void;
  setView: (view: View) => void;
  view: View;
};

export function ToggleViewButtons({
  setGrouping,
  setView,
  view,
}: ToggleViewButtonsProps) {
  return (
    <ToggleButtonGroup className="">
      <ToggleButton
        className="border border-border-primary"
        color={view === "list" ? "color" : "gray"}
        onPress={() => {
          setView("list");
          setGrouping([]);
        }}
        variant={view === "list" ? "primary" : "secondary"}
      >
        <ListIcon />
      </ToggleButton>
      <ToggleButton
        className="border border-border-primary"
        color={view === "grid" ? "color" : "gray"}
        onPress={() => {
          setGrouping(["type"]);
          setView("grid");
        }}
        variant={view === "grid" ? "primary" : "secondary"}
      >
        <LayoutGridIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
