import type { Row } from "@tanstack/react-table";

import React from "react";
import { useHover } from "react-aria";
import { twMerge } from "tailwind-merge";

import type { UserRole } from "@/features/auth/permissions/config";

import { AccessibleIcon } from "@/components/accessible-icon";
import { ToggleButton, ToggleButtonGroup } from "@/components/button";
import { Checkbox } from "@/components/checkbox";
import { RoleGuard } from "@/features/auth/permissions/role-guard";
import AssetFolder from "@/features/my-files/assets/asset-folder.svg?react";

import type { FileItem } from "../../types";

import { FILE_ICONS } from "../../assets/file-icons";
import { ActionMenu } from "../action-menu/action-menu";
import { MENU_ITEMS } from "../action-menu/config";

function GridItemActionMenu() {
  return (
    <ToggleButtonGroup className="absolute right-[17px] top-xl z-20">
      {Object.entries(MENU_ITEMS).map(([role, items]) => (
        <RoleGuard allowedRoles={[role as UserRole]} key={role}>
          {items?.map((item) => {
            if ("separator" in item) {
              return null;
            }

            return (
              <ToggleButton
                className="size-4xl p-0"
                color={item.destructive ? "destructive" : "gray"}
                key={item.label}
                onPress={item.onAction}
                variant="secondary"
              >
                <AccessibleIcon className="size-2xl">
                  {item.icon}
                </AccessibleIcon>
              </ToggleButton>
            );
          })}
        </RoleGuard>
      ))}
    </ToggleButtonGroup>
  );
}

type AssetItemProps = {
  isSelected?: boolean;
  toggleSelected?: (event: unknown) => void;
};

function AssetItemGrid(props: AssetItemProps & FileItem) {
  const { isSelected = false, name, toggleSelected, type } = props;

  const { hoverProps, isHovered } = useHover({});

  if (type === "folder") {
    return (
      <div
        className={twMerge([
          "flex items-center gap-lg rounded-sm bg-bg-secondary px-xl py-lg",
          isSelected && "outline outline-border-brand bg-bg-brand-primary",
        ])}
      >
        <Checkbox
          className="bg-bg-primary"
          isSelected={isSelected}
          onChange={toggleSelected}
          size="md"
        />
        <AssetFolder className="shrink-0" />
        <span className="truncate text-sm text-text-primary">{name}</span>
        <ActionMenu />
      </div>
    );
  }

  if (type === "file") {
    return (
      <div
        {...hoverProps}
        className={twMerge([
          "relative size-full gap-md rounded-sm px-md pb-lg pt-md",
          "hover:bg-bg-secondary-hover",
          isSelected && "outline outline-border-brand bg-bg-brand-primary",
        ])}
      >
        {(isSelected || isHovered) && (
          <Checkbox
            className="absolute left-[17px] top-[22px] z-10"
            isSelected={isSelected}
            onChange={toggleSelected}
            size="md"
          />
        )}

        {isHovered && <GridItemActionMenu />}

        <div className="absolute inset-0 flex items-center justify-center">
          {props.extension in FILE_ICONS &&
            React.createElement(
              FILE_ICONS[props.extension as keyof typeof FILE_ICONS],
              { className: "shrink-0 size-[60px]" },
            )}
        </div>
        <span className="absolute inset-x-0 bottom-lg truncate px-md text-center text-xs text-text-primary">
          {name}
        </span>
      </div>
    );
  }
}

export function GroupHeader({
  allSelected,
  groupTitle,
  onToggleSelection,
  someSelected,
}: {
  allSelected: boolean;
  groupTitle: string;
  onToggleSelection: () => void;
  someSelected: boolean;
}) {
  return (
    <div className="flex items-center gap-lg px-xl py-lg">
      <Checkbox
        aria-label={`Select all ${groupTitle}`}
        isIndeterminate={someSelected}
        isSelected={allSelected}
        onChange={onToggleSelection}
        size="md"
      />
      <h3 className="text-xs font-medium text-text-tertiary">{groupTitle}</h3>
    </div>
  );
}

export function FolderGrid({ rows }: { rows: Row<FileItem>[] }) {
  return (
    <div className="grid grid-cols-4 gap-md">
      {rows.map((row) => (
        <AssetItemGrid
          key={row.id}
          {...row.original}
          isSelected={row.getIsSelected()}
          toggleSelected={row.getToggleSelectedHandler()}
        />
      ))}
    </div>
  );
}

export function AssetGrid({ rows }: { rows: Row<FileItem>[] }) {
  return (
    <div className="grid auto-rows-[minmax(117px,_210px)] grid-cols-[repeat(auto-fill,_minmax(120px,_215px))] gap-md">
      {rows.map((row) => (
        <AssetItemGrid
          key={row.id}
          {...row.original}
          isSelected={row.getIsSelected()}
          toggleSelected={row.getToggleSelectedHandler()}
        />
      ))}
    </div>
  );
}
