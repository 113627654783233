import { useIsMutating } from "@tanstack/react-query";

import { Button } from "@/components/button";

export function Footer({ handleSaveDraft }: { handleSaveDraft: () => void }) {
  const isMutating = useIsMutating();

  return (
    <div className="sticky bottom-0 flex items-center justify-end gap-lg rounded-b-xl border-t border-border-secondary bg-bg-primary px-3xl py-2xl">
      <Button
        color="gray"
        isDisabled={isMutating > 0}
        onPress={handleSaveDraft}
        size="md"
        variant="secondary"
      >
        Save to drafts
      </Button>
      <Button
        isDisabled={isMutating > 0}
        size="md"
        type="submit"
        variant="primary"
      >
        Create Request
      </Button>
    </div>
  );
}
