import type { GroupingState, RowSelectionState } from "@tanstack/react-table";

import { ExternalLinkIcon, TrashIcon } from "lucide-react";
import { useState } from "react";

import { Button } from "@/components/button";
import {
  SearchField,
  SearchInput,
} from "@/features/my-files/components/search-field";

import type { View } from "./types";

import { RoleGuard } from "../auth/permissions/role-guard";
import { Breadcrumbs } from "./components/breadcrumbs";
import { FilesGridView } from "./components/files-grid-view";
import { FilesListView } from "./components/files-list-view";
import { SelectionActions } from "./components/selection-actions";
import { ToggleViewButtons } from "./components/toggle-view-buttons";
import { useMyFilesTable } from "./useMyFilesTable";

export function MyFiles() {
  const [view, setView] = useState<View>("list");
  const [grouping, setGrouping] = useState<GroupingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useMyFilesTable({
    grouping,
    rowSelection,
    setGrouping,
    setRowSelection,
    view,
  });

  return (
    <div className="w-full bg-bg-secondary p-4xl @container">
      <div className="mx-auto w-full max-w-[1500px] rounded-xl bg-bg-primary">
        <div className="flex flex-col divide-y divide-border-secondary">
          <div className="flex items-center justify-between px-3xl py-2xl">
            <Breadcrumbs />
            <SearchField>
              <SearchInput placeholder="Search" />
            </SearchField>
          </div>

          <div className="flex justify-between px-3xl py-lg">
            <SelectionActions
              rowSelection={rowSelection}
              table={table}
              view={view}
            />
            <div className="flex items-center gap-lg">
              <RoleGuard allowedRoles={["super-admin", "team-leader"]}>
                <Button color="color" variant="secondary">
                  <TrashIcon />
                  Trash folder
                </Button>
              </RoleGuard>
              <Button color="gray" variant="secondary">
                <ExternalLinkIcon />
                View Request Details
                {/* TODO: add "View Brand details" */}
              </Button>
              <ToggleViewButtons
                setGrouping={setGrouping}
                setView={setView}
                view={view}
              />
            </div>
          </div>

          <div className="px-3xl py-2xl">
            {view === "grid" && <FilesGridView table={table} />}
            {view === "list" && <FilesListView table={table} />}
          </div>
        </div>
      </div>
    </div>
  );
}
