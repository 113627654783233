import { Controller } from "react-hook-form";

import { FieldError, Input, Label, TextField } from "@/components/field";
import {
  Select,
  SelectButton,
  SelectListBox,
  SelectListItem,
  SelectPopover,
} from "@/components/select";
import { useUser } from "@/hooks/useUser";

import { useCreateWebsiteDesignRequestFormContext } from "../useCreateWebsiteDesignRequestForm";
import { CollaboratorsSelect } from "./CollaboratorsSelect";
import { ComplexitySelect } from "./ComplexitySelect";
import { PreferredDesignersSelect } from "./PreferredDesignersSelect";

export function TechnicalDetailsSection() {
  const { data: user } = useUser();
  const methods = useCreateWebsiteDesignRequestFormContext();

  const showComplexityField = [
    "account-manager",
    "super-admin",
    "team-leader",
  ].includes(user?.user.roleName || "");

  return (
    <>
      <div className="rounded-xl bg-bg-secondary p-3xl">
        <div className="grid grid-cols-12 gap-x-3xl gap-y-lg">
          <span className="col-span-12 text-lg font-semibold text-text-primary">
            Technical Details
          </span>
          <Controller
            control={methods.control}
            name="creativeDirection"
            render={({ field: { name, onBlur, onChange, value } }) => (
              <Select
                className="col-span-6"
                name={name}
                onBlur={onBlur}
                onSelectionChange={onChange}
                placeholder="Select creative direction"
                selectedKey={value}
              >
                <Label>Creative direction</Label>
                <SelectButton />
                <SelectPopover>
                  <SelectListBox>
                    <SelectListItem id="Designer has creative freedom">
                      Designer has creative freedom
                    </SelectListItem>
                    <SelectListItem id="Follow brief exactly">
                      Follow brief exactly
                    </SelectListItem>
                  </SelectListBox>
                </SelectPopover>
              </Select>
            )}
          />
          <Controller
            control={methods.control}
            name="sizesNeeded"
            render={({
              field: { name, onBlur, onChange, ref, value },
              fieldState: { error, invalid },
            }) => (
              <TextField
                className="col-span-6"
                isInvalid={invalid}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                validationBehavior="aria"
                value={value}
              >
                <Label requiredHint>Sizes needed</Label>
                <Input placeholder="E.g. 1080x1080, 16x9, etc." ref={ref} />
                <FieldError>{error?.message}</FieldError>
              </TextField>
            )}
          />
          <Controller
            control={methods.control}
            name="preferredFileTypes"
            render={({
              field: { name, onBlur, onChange, ref, value },
              fieldState: { error, invalid },
            }) => (
              <TextField
                className={showComplexityField ? "col-span-4" : "col-span-6"}
                isInvalid={invalid}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                validationBehavior="aria"
                value={value}
              >
                <Label requiredHint>File types</Label>
                <Input placeholder="E.g. JPG, PNG, PDF, etc." ref={ref} />
                <FieldError>{error?.message}</FieldError>
              </TextField>
            )}
          />
          <PreferredDesignersSelect
            className={showComplexityField ? "col-span-4" : "col-span-6"}
          />
          {showComplexityField && <ComplexitySelect />}
        </div>
      </div>
      <div className="w-1/2 rounded-xl bg-bg-secondary p-3xl">
        <CollaboratorsSelect />
      </div>
    </>
  );
}
