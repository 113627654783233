import { Separator } from "react-aria-components";
import { FormProvider } from "react-hook-form";

import { FullPageLoader } from "@/components/fullpage-loader";

import { AssetsSection } from "../shared/assets-section";
import { Footer } from "../shared/components/Footer";
import { Header } from "../shared/components/Header";
import { SubmitWarningModal } from "../shared/components/SubmitWarningModal";
import { useCreateDraft } from "../shared/useCreateDraft";
import { useGetLatestRequestId } from "../shared/useGetLatestRequestId";
import { useIsMBCSubmitted } from "../shared/useIsMBCSubmitted";
import { BriefCreatorSection } from "./components/brief-creator-section";
import { IntroSection } from "./components/intro-section";
import { TechnicalDetailsSection } from "./components/technical-details-section";
import { useCreateWebsiteDesignRequestForm } from "./components/useCreateWebsiteDesignRequestForm";
import { useDuplicateWebsiteDesignRequest } from "./hooks/useDuplicateWebsiteDesignRequest";
import { useSaveDraftMutation } from "./hooks/useSaveDraftMutation";
import { useSubmitMutation } from "./hooks/useSubmitMutation";

export function CreateWebsiteDesignRequestForm() {
  const { setShowWarningModal, showWarningModal, ...formMethods } =
    useCreateWebsiteDesignRequestForm();

  const isMBCSubmitted = useIsMBCSubmitted((state) => state.isSubmitted);

  const { isPending: isCreateDraftPending } = useCreateDraft();
  const { isInDuplicateMode, isPending: isDuplicatePending } =
    useDuplicateWebsiteDesignRequest({
      reset: formMethods.reset,
    });

  const latestRequestId = useGetLatestRequestId();

  const submitMutation = useSubmitMutation({ formMethods, latestRequestId });
  const saveDraftMutation = useSaveDraftMutation({
    formMethods,
    latestRequestId,
  });

  async function onSubmit() {
    const formData = formMethods.getValues();

    // Handles regular flow
    if (
      !isMBCSubmitted &&
      !isInDuplicateMode &&
      formData.briefCreatorType === "detailed"
    ) {
      setShowWarningModal(true);

      return;
    }

    // Handles duplicate mode
    if (
      isInDuplicateMode &&
      !formData.briefCreatorDetailed.finalBriefHTML &&
      formData.briefCreatorType === "detailed"
    ) {
      setShowWarningModal(true);

      return;
    }

    submitMutation.mutate(formData);
  }

  async function onSaveDraft() {
    const formData = formMethods.getValues();

    saveDraftMutation.mutate(formData);
  }

  const isPending =
    isCreateDraftPending ||
    isDuplicatePending ||
    submitMutation.isPending ||
    saveDraftMutation.isPending;

  return (
    <>
      {isPending && <FullPageLoader />}
      <FormProvider {...formMethods}>
        <div className="relative flex px-4xl">
          <form
            className="mx-auto flex min-w-[820px] max-w-[1500px] flex-col rounded-xl bg-bg-primary"
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            <Header requestName={formMethods.watch("requestName")} />
            <div className="flex-1 overflow-y-auto">
              <div className="flex flex-col gap-3xl px-3xl py-2xl">
                <IntroSection />
                <Separator className="border-border-secondary" />
                <BriefCreatorSection />
                <Separator className="border-border-secondary" />
                <AssetsSection formMethods={formMethods} />
                <Separator className="border-border-secondary" />
                <TechnicalDetailsSection />
              </div>
            </div>
            <Footer handleSaveDraft={onSaveDraft} />
          </form>
        </div>
      </FormProvider>
      <SubmitWarningModal
        isOpen={showWarningModal}
        onOpenChange={setShowWarningModal}
      />
    </>
  );
}
