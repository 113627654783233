import { Component, type ReactNode } from "react";

type Props = {
  children: ReactNode;
  fallback?: ReactNode;
};

type State = {
  error?: Error;
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return { error, hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback ?? (
          <div className="flex size-full flex-col items-center justify-center gap-md bg-bg-tertiary p-lg text-center">
            <div className="rounded-xl border border-border-secondary bg-bg-primary p-2xl">
              <h2 className="mb-md text-xl font-semibold text-text-error-primary">
                Something went wrong
              </h2>
              <p className="text-text-secondary">
                Please try refreshing the page or contact support if the issue
                persists.
              </p>
              {this.state.error && (
                <pre className="mt-lg max-w-[600px] overflow-auto rounded-lg bg-bg-secondary p-md text-left text-sm text-text-secondary">
                  {this.state.error.message}
                </pre>
              )}
            </div>
          </div>
        )
      );
    }

    return this.props.children;
  }
}
