import { EllipsisVerticalIcon } from "lucide-react";
import { Button as RACButton } from "react-aria-components";

import type { UserRole } from "@/features/auth/permissions/config";

import { AccessibleIcon } from "@/components/accessible-icon";
import {
  Menu,
  MenuItem,
  MenuItemLabel,
  MenuSeparator,
  MenuTrigger,
} from "@/components/menu";
import { Popover } from "@/components/popover";
import { RoleGuard } from "@/features/auth/permissions/role-guard";

import { MENU_ITEMS } from "./config";

export function ActionMenu() {
  return (
    <MenuTrigger>
      <RACButton aria-label="Menu" className="ml-auto p-xs outline-none">
        <EllipsisVerticalIcon className="text-button-tertiary-fg" size={13} />
      </RACButton>
      <Popover>
        <Menu className="w-[192px]">
          {Object.entries(MENU_ITEMS).map(([role, items]) => (
            <RoleGuard allowedRoles={[role as UserRole]} key={role}>
              {items?.map((item) => {
                if ("separator" in item) {
                  return <MenuSeparator key="separator" />;
                }

                return (
                  <MenuItem
                    destructive={item.destructive}
                    key={item.label}
                    onAction={item.onAction}
                  >
                    <AccessibleIcon>{item.icon}</AccessibleIcon>
                    <MenuItemLabel>{item.label}</MenuItemLabel>
                  </MenuItem>
                );
              })}
            </RoleGuard>
          ))}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}
