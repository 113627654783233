import type { Dispatch, SetStateAction } from "react";
import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { convert } from "html-to-text";
import { Delta } from "quill";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import * as z from "zod";

import { useIsMBCSubmitted } from "@/features/request/create/shared/useIsMBCSubmitted";
import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";
import { useUser } from "@/hooks/useUser";

import { fileAssetSchema, linkAssetSchema } from "../../shared/utils";

const createVideoRequestFormSchema = (userRole: string) =>
  z.object({
    /* eslint-disable perfectionist/sort-objects */
    requestName: z
      .string()
      .min(1, { message: "Request name is required" })
      .max(255, { message: "Request name is too long" }),
    videoType: z.number({
      required_error: "Video type is required",
    }),
    brand: z.coerce.number().optional(),
    // Brief creator
    briefCreatorType: z.enum(["detailed", "single"]),
    briefCreatorDetailed: z.object({
      scope: z.string(),
      creativeGoal: z.string(),
      brandGuidelines: z.string(),
      additionalGuidelines: z.string(),
      script: z.string(),
      finalBriefHTML: z
        .string()
        .refine((value) => convert(value).length < 5000, {
          // 5000 characters of text (html tags removed)
          message: "Final brief text is too long",
        }),
      finalBrief: z.instanceof(Delta),
    }),
    briefCreatorSingle: z.object({
      requestDescriptionHTML: z
        .string()
        .refine((value) => convert(value).length < 5000, {
          // 5000 characters of text (html tags removed)
          message: "Request description text is too long",
        }),
      textsForDesignHTML: z
        .string()
        .refine((value) => convert(value).length < 5000, {
          // 5000 characters of text (html tags removed)
          message: "Text for design text is too long",
        }),
      requestDescription: z.instanceof(Delta),
      textsForDesign: z.instanceof(Delta),
    }),
    // Assets and inspirations
    assets: z.array(z.union([fileAssetSchema, linkAssetSchema])),
    inspirations: z.array(z.union([fileAssetSchema, linkAssetSchema])),
    // Technical details
    creativeDirection: z
      .union([
        z.literal("Designer has creative freedom"),
        z.literal("Follow brief exactly"),
      ])
      .optional(),
    sizes: z.string().min(1, { message: "Sizes are required" }).max(800, {
      message: "Sizes are too long",
    }),
    lengthOrPlatform: z
      .string()
      .min(1, { message: "Length or platform are required" })
      .max(250, {
        message: "Length or platform is too long",
      }),
    style: z.string().max(250, {
      message: "Style is too long",
    }),
    musicGenre: z.string().max(255, {
      message: "Music genre is too long",
    }),
    fileTypes: z
      .string()
      .min(1, { message: "File types are required" })
      .max(250, {
        message: "File types are too long",
      }),
    preferredDesigners: z.array(z.string()),
    designComplexity: [
      "account-manager",
      "super-admin",
      "team-leader",
    ].includes(userRole)
      ? z.number({ required_error: "Design complexity is required" })
      : z.any().optional(),
    collaborators: z.array(z.string()),
    /* eslint-enable perfectionist/sort-objects */
  });

export type CreateVideoRequestFormData = z.infer<
  ReturnType<typeof createVideoRequestFormSchema>
>;

export function useCreateVideoRequestForm(): {
  setShowWarningModal: Dispatch<SetStateAction<boolean>>;
  showWarningModal: boolean;
} & UseFormReturn<CreateVideoRequestFormData> {
  const { data: user } = useUser();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const setIsDirty = useIsRequestFormDirty((state) => state.setIsDirty);
  const isMBCSubmitted = useIsMBCSubmitted((state) => state.isSubmitted);

  const form = useForm<CreateVideoRequestFormData>({
    defaultValues: {
      /* eslint-disable perfectionist/sort-objects */
      requestName: "",
      videoType: undefined,
      brand: undefined,
      briefCreatorType: "detailed",
      briefCreatorDetailed: {
        scope: "",
        creativeGoal: "",
        brandGuidelines: "",
        additionalGuidelines: "",
        script: "",
        finalBriefHTML: "",
        finalBrief: new Delta(),
      },
      briefCreatorSingle: {
        requestDescriptionHTML: "",
        requestDescription: new Delta(),
        textsForDesignHTML: "",
        textsForDesign: new Delta(),
      },
      assets: [],
      inspirations: [],
      creativeDirection: undefined,
      sizes: "",
      lengthOrPlatform: "",
      style: "",
      musicGenre: "",
      fileTypes: "",
      preferredDesigners: [],
      designComplexity: undefined,
      collaborators: [],
      /* eslint-enable perfectionist/sort-objects */
    },
    resolver: zodResolver(
      createVideoRequestFormSchema(user?.user.roleName || "").superRefine(
        (data, ctx) => {
          switch (data.briefCreatorType) {
            case "detailed": {
              const currentValues = form.getValues();

              // Don't validate if there is final brief text
              if (
                convert(
                  currentValues.briefCreatorDetailed.finalBriefHTML,
                ).trim().length > 0
              ) {
                return;
              }
              // if AI generated - validate input field
              if (
                isMBCSubmitted &&
                convert(
                  currentValues.briefCreatorDetailed.finalBriefHTML,
                ).trim().length === 0
              ) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "Request description is required",
                  path: ["briefCreatorDetailed", "finalBrief"],
                });
              }

              break;
            }
            case "single": {
              const currentValues = form.getValues();

              // Check for empty requestDescription
              if (
                convert(
                  currentValues.briefCreatorSingle.requestDescriptionHTML,
                ).trim().length === 0
              ) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "Request description is required",
                  path: ["briefCreatorSingle", "requestDescription"],
                });
              }
              // Check for requestDescription length
              if (
                convert(currentValues.briefCreatorSingle.requestDescriptionHTML)
                  .length >= 5000
              ) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "Request description text is too long",
                  path: ["briefCreatorSingle", "requestDescription"],
                });
              }

              // Check for empty textsForDesign
              if (
                convert(
                  currentValues.briefCreatorSingle.textsForDesignHTML,
                ).trim().length === 0
              ) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "Text for design is required",
                  path: ["briefCreatorSingle", "textsForDesign"],
                });
              }
              // Check for textsForDesign length
              if (
                convert(currentValues.briefCreatorSingle.textsForDesignHTML)
                  .length >= 5000
              ) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "Text for design text is too long",
                  path: ["briefCreatorSingle", "textsForDesign"],
                });
              }

              break;
            }
          }
        },
      ),
    ),
  });

  // Handle beforeunload event to prevent unsaved changes from being lost
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";

        return event.returnValue;
      }
    },
    [form.formState.isDirty],
  );

  useEffect(() => {
    setIsDirty(form.formState.isDirty);
  }, [form.formState.isDirty, setIsDirty]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  useEffect(() => {
    const unsubscribe = useIsRequestFormDirty.subscribe(
      (state) => state.isDirty,
      (isDirty) => {
        if (!isDirty) {
          form.reset(form.getValues());
        }
      },
    );

    return () => unsubscribe();
  }, [form]);

  return {
    ...form,
    setShowWarningModal,
    showWarningModal,
  };
}

export function useCreateVideoRequestFormContext() {
  return useFormContext<CreateVideoRequestFormData>();
}
