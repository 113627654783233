import type { GetDesignTypesResponse } from "@nlc/shared-types";

import { useSuspenseQuery } from "@tanstack/react-query";
import { groupBy } from "es-toolkit";

import { designTypesKeys } from "@/hooks/queryKeys";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";
import apiClient from "@/lib/api/client";

type SubcategoryWithTypes = {
  types: GetDesignTypesResponse["designTypes"]["types"];
} & GetDesignTypesResponse["designTypes"]["subcategories"][number];

function transformDesignTypes(
  response: GetDesignTypesResponse,
): SubcategoryWithTypes[] {
  const { subcategories, types } = response.designTypes;

  const videoSubcategories = subcategories.filter(
    (sub) => sub.category === "video",
  );

  const groupedTypes = groupBy(types, (item) => item.subcategoryId);

  return videoSubcategories.map((subcategory) => ({
    ...subcategory,
    types: groupedTypes[subcategory.id] || [],
  }));
}

export function useGetVideoTypes() {
  const projectId = useGetCurrentProjectId();

  return useSuspenseQuery<
    GetDesignTypesResponse,
    Error,
    SubcategoryWithTypes[]
  >({
    queryFn: async () =>
      await apiClient.url(`/design-types/picker/${projectId}`).get().json(),
    queryKey: designTypesKeys.designTypesByProjectId(projectId),
    select: (data: GetDesignTypesResponse) => transformDesignTypes(data),
  });
}
