import type { RowSelectionState, Table } from "@tanstack/react-table";

import { DownloadIcon, Trash2Icon, XIcon } from "lucide-react";
import { Button as RACButton } from "react-aria-components";

import { Button } from "@/components/button";
import { RoleGuard } from "@/features/auth/permissions/role-guard";

import type { FileItem, View } from "../types";

type SelectionActionsProps = {
  rowSelection: RowSelectionState;
  table: Table<FileItem>;
  view: View;
};

export function SelectionActions({
  rowSelection,
  table,
  view,
}: SelectionActionsProps) {
  // Selected rows count for display
  const selectedCount =
    view === "grid"
      ? table
          .getPreFilteredRowModel()
          .rows.filter((row) => !row.getIsGrouped() && row.getIsSelected())
          .length
      : Object.keys(rowSelection).length;

  return (
    <div className="flex items-center gap-lg">
      {selectedCount > 0 && (
        <div className="flex items-center gap-lg">
          <RACButton
            className="flex size-[36px] items-center justify-center outline-none"
            onPress={() => table.resetRowSelection()}
          >
            <XIcon className="text-fg-quinary" />
          </RACButton>
          <span className="text-sm font-medium text-text-tertiary">
            {selectedCount} item(s) selected
          </span>
        </div>
      )}
      <Button color="gray" isDisabled={selectedCount === 0} variant="secondary">
        <DownloadIcon />
        Download
      </Button>
      <RoleGuard allowedRoles={["super-admin", "team-leader"]}>
        {selectedCount > 0 && (
          <Button color="destructive" variant="secondary">
            <Trash2Icon />
            Delete
          </Button>
        )}
      </RoleGuard>
    </div>
  );
}
