/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as AuthAppShellImport } from './routes/_auth/_app-shell'
import { Route as AuthAppShellUsersIndexImport } from './routes/_auth/_app-shell/users/index'
import { Route as AuthAppShellMyFilesIndexImport } from './routes/_auth/_app-shell/my-files/index'
import { Route as AuthAppShellRequestsPickTypeImport } from './routes/_auth/_app-shell/requests/pick-type'
import { Route as AuthAppShellRequestsCreateImport } from './routes/_auth/_app-shell/requests/create'
import { Route as AuthAppShellPaywallNoCreditsImport } from './routes/_auth/_app-shell/paywall/no-credits'
import { Route as AuthAppShellPaywallExpiredSubscriptionImport } from './routes/_auth/_app-shell/paywall/expired-subscription'
import { Route as AuthAppShellUsersUserIdEditImport } from './routes/_auth/_app-shell/users/$userId.edit'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthAppShellRoute = AuthAppShellImport.update({
  id: '/_app-shell',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAppShellUsersIndexRoute = AuthAppShellUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => AuthAppShellRoute,
} as any)

const AuthAppShellMyFilesIndexRoute = AuthAppShellMyFilesIndexImport.update({
  id: '/my-files/',
  path: '/my-files/',
  getParentRoute: () => AuthAppShellRoute,
} as any)

const AuthAppShellRequestsPickTypeRoute =
  AuthAppShellRequestsPickTypeImport.update({
    id: '/requests/pick-type',
    path: '/requests/pick-type',
    getParentRoute: () => AuthAppShellRoute,
  } as any)

const AuthAppShellRequestsCreateRoute = AuthAppShellRequestsCreateImport.update(
  {
    id: '/requests/create',
    path: '/requests/create',
    getParentRoute: () => AuthAppShellRoute,
  } as any,
)

const AuthAppShellPaywallNoCreditsRoute =
  AuthAppShellPaywallNoCreditsImport.update({
    id: '/paywall/no-credits',
    path: '/paywall/no-credits',
    getParentRoute: () => AuthAppShellRoute,
  } as any)

const AuthAppShellPaywallExpiredSubscriptionRoute =
  AuthAppShellPaywallExpiredSubscriptionImport.update({
    id: '/paywall/expired-subscription',
    path: '/paywall/expired-subscription',
    getParentRoute: () => AuthAppShellRoute,
  } as any)

const AuthAppShellUsersUserIdEditRoute =
  AuthAppShellUsersUserIdEditImport.update({
    id: '/users/$userId/edit',
    path: '/users/$userId/edit',
    getParentRoute: () => AuthAppShellRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_app-shell': {
      id: '/_auth/_app-shell'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthAppShellImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_app-shell/paywall/expired-subscription': {
      id: '/_auth/_app-shell/paywall/expired-subscription'
      path: '/paywall/expired-subscription'
      fullPath: '/paywall/expired-subscription'
      preLoaderRoute: typeof AuthAppShellPaywallExpiredSubscriptionImport
      parentRoute: typeof AuthAppShellImport
    }
    '/_auth/_app-shell/paywall/no-credits': {
      id: '/_auth/_app-shell/paywall/no-credits'
      path: '/paywall/no-credits'
      fullPath: '/paywall/no-credits'
      preLoaderRoute: typeof AuthAppShellPaywallNoCreditsImport
      parentRoute: typeof AuthAppShellImport
    }
    '/_auth/_app-shell/requests/create': {
      id: '/_auth/_app-shell/requests/create'
      path: '/requests/create'
      fullPath: '/requests/create'
      preLoaderRoute: typeof AuthAppShellRequestsCreateImport
      parentRoute: typeof AuthAppShellImport
    }
    '/_auth/_app-shell/requests/pick-type': {
      id: '/_auth/_app-shell/requests/pick-type'
      path: '/requests/pick-type'
      fullPath: '/requests/pick-type'
      preLoaderRoute: typeof AuthAppShellRequestsPickTypeImport
      parentRoute: typeof AuthAppShellImport
    }
    '/_auth/_app-shell/my-files/': {
      id: '/_auth/_app-shell/my-files/'
      path: '/my-files'
      fullPath: '/my-files'
      preLoaderRoute: typeof AuthAppShellMyFilesIndexImport
      parentRoute: typeof AuthAppShellImport
    }
    '/_auth/_app-shell/users/': {
      id: '/_auth/_app-shell/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthAppShellUsersIndexImport
      parentRoute: typeof AuthAppShellImport
    }
    '/_auth/_app-shell/users/$userId/edit': {
      id: '/_auth/_app-shell/users/$userId/edit'
      path: '/users/$userId/edit'
      fullPath: '/users/$userId/edit'
      preLoaderRoute: typeof AuthAppShellUsersUserIdEditImport
      parentRoute: typeof AuthAppShellImport
    }
  }
}

// Create and export the route tree

interface AuthAppShellRouteChildren {
  AuthAppShellPaywallExpiredSubscriptionRoute: typeof AuthAppShellPaywallExpiredSubscriptionRoute
  AuthAppShellPaywallNoCreditsRoute: typeof AuthAppShellPaywallNoCreditsRoute
  AuthAppShellRequestsCreateRoute: typeof AuthAppShellRequestsCreateRoute
  AuthAppShellRequestsPickTypeRoute: typeof AuthAppShellRequestsPickTypeRoute
  AuthAppShellMyFilesIndexRoute: typeof AuthAppShellMyFilesIndexRoute
  AuthAppShellUsersIndexRoute: typeof AuthAppShellUsersIndexRoute
  AuthAppShellUsersUserIdEditRoute: typeof AuthAppShellUsersUserIdEditRoute
}

const AuthAppShellRouteChildren: AuthAppShellRouteChildren = {
  AuthAppShellPaywallExpiredSubscriptionRoute:
    AuthAppShellPaywallExpiredSubscriptionRoute,
  AuthAppShellPaywallNoCreditsRoute: AuthAppShellPaywallNoCreditsRoute,
  AuthAppShellRequestsCreateRoute: AuthAppShellRequestsCreateRoute,
  AuthAppShellRequestsPickTypeRoute: AuthAppShellRequestsPickTypeRoute,
  AuthAppShellMyFilesIndexRoute: AuthAppShellMyFilesIndexRoute,
  AuthAppShellUsersIndexRoute: AuthAppShellUsersIndexRoute,
  AuthAppShellUsersUserIdEditRoute: AuthAppShellUsersUserIdEditRoute,
}

const AuthAppShellRouteWithChildren = AuthAppShellRoute._addFileChildren(
  AuthAppShellRouteChildren,
)

interface AuthRouteChildren {
  AuthAppShellRoute: typeof AuthAppShellRouteWithChildren
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAppShellRoute: AuthAppShellRouteWithChildren,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthAppShellRouteWithChildren
  '/login': typeof LoginRoute
  '/paywall/expired-subscription': typeof AuthAppShellPaywallExpiredSubscriptionRoute
  '/paywall/no-credits': typeof AuthAppShellPaywallNoCreditsRoute
  '/requests/create': typeof AuthAppShellRequestsCreateRoute
  '/requests/pick-type': typeof AuthAppShellRequestsPickTypeRoute
  '/my-files': typeof AuthAppShellMyFilesIndexRoute
  '/users': typeof AuthAppShellUsersIndexRoute
  '/users/$userId/edit': typeof AuthAppShellUsersUserIdEditRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthAppShellRouteWithChildren
  '/login': typeof LoginRoute
  '/paywall/expired-subscription': typeof AuthAppShellPaywallExpiredSubscriptionRoute
  '/paywall/no-credits': typeof AuthAppShellPaywallNoCreditsRoute
  '/requests/create': typeof AuthAppShellRequestsCreateRoute
  '/requests/pick-type': typeof AuthAppShellRequestsPickTypeRoute
  '/my-files': typeof AuthAppShellMyFilesIndexRoute
  '/users': typeof AuthAppShellUsersIndexRoute
  '/users/$userId/edit': typeof AuthAppShellUsersUserIdEditRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/_auth/_app-shell': typeof AuthAppShellRouteWithChildren
  '/_auth/_app-shell/paywall/expired-subscription': typeof AuthAppShellPaywallExpiredSubscriptionRoute
  '/_auth/_app-shell/paywall/no-credits': typeof AuthAppShellPaywallNoCreditsRoute
  '/_auth/_app-shell/requests/create': typeof AuthAppShellRequestsCreateRoute
  '/_auth/_app-shell/requests/pick-type': typeof AuthAppShellRequestsPickTypeRoute
  '/_auth/_app-shell/my-files/': typeof AuthAppShellMyFilesIndexRoute
  '/_auth/_app-shell/users/': typeof AuthAppShellUsersIndexRoute
  '/_auth/_app-shell/users/$userId/edit': typeof AuthAppShellUsersUserIdEditRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/paywall/expired-subscription'
    | '/paywall/no-credits'
    | '/requests/create'
    | '/requests/pick-type'
    | '/my-files'
    | '/users'
    | '/users/$userId/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/paywall/expired-subscription'
    | '/paywall/no-credits'
    | '/requests/create'
    | '/requests/pick-type'
    | '/my-files'
    | '/users'
    | '/users/$userId/edit'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/login'
    | '/_auth/_app-shell'
    | '/_auth/_app-shell/paywall/expired-subscription'
    | '/_auth/_app-shell/paywall/no-credits'
    | '/_auth/_app-shell/requests/create'
    | '/_auth/_app-shell/requests/pick-type'
    | '/_auth/_app-shell/my-files/'
    | '/_auth/_app-shell/users/'
    | '/_auth/_app-shell/users/$userId/edit'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/login"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_app-shell"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_auth/_app-shell": {
      "filePath": "_auth/_app-shell.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_app-shell/paywall/expired-subscription",
        "/_auth/_app-shell/paywall/no-credits",
        "/_auth/_app-shell/requests/create",
        "/_auth/_app-shell/requests/pick-type",
        "/_auth/_app-shell/my-files/",
        "/_auth/_app-shell/users/",
        "/_auth/_app-shell/users/$userId/edit"
      ]
    },
    "/_auth/_app-shell/paywall/expired-subscription": {
      "filePath": "_auth/_app-shell/paywall/expired-subscription.tsx",
      "parent": "/_auth/_app-shell"
    },
    "/_auth/_app-shell/paywall/no-credits": {
      "filePath": "_auth/_app-shell/paywall/no-credits.tsx",
      "parent": "/_auth/_app-shell"
    },
    "/_auth/_app-shell/requests/create": {
      "filePath": "_auth/_app-shell/requests/create.tsx",
      "parent": "/_auth/_app-shell"
    },
    "/_auth/_app-shell/requests/pick-type": {
      "filePath": "_auth/_app-shell/requests/pick-type.tsx",
      "parent": "/_auth/_app-shell"
    },
    "/_auth/_app-shell/my-files/": {
      "filePath": "_auth/_app-shell/my-files/index.tsx",
      "parent": "/_auth/_app-shell"
    },
    "/_auth/_app-shell/users/": {
      "filePath": "_auth/_app-shell/users/index.tsx",
      "parent": "/_auth/_app-shell"
    },
    "/_auth/_app-shell/users/$userId/edit": {
      "filePath": "_auth/_app-shell/users/$userId.edit.tsx",
      "parent": "/_auth/_app-shell"
    }
  }
}
ROUTE_MANIFEST_END */
