import type { DesignPickerData } from "./types";

import { Header } from "./components/Header";
import { SubcategoryTabs } from "./components/SubcategoryTabs";
import { useGetDesignTypes } from "./hooks/useGetDesignTypes";
import { usePickTypeState } from "./hooks/usePickTypeState";

export function PickType() {
  const { data } = useGetDesignTypes();
  const {
    category,
    handleCategoryChange,
    handleSubcategoryChange,
    subcategory,
  } = usePickTypeState();

  const designPickerData: DesignPickerData = {
    categories: data.categories,
    designTypes: data.designTypes,
    subcategories: data.subcategories,
  };

  return (
    <>
      <Header
        categories={designPickerData.categories}
        onCategoryChange={handleCategoryChange}
        selectedCategory={category}
      />
      {designPickerData.categories.map(
        (cat) =>
          cat.id === category && (
            <div className="overflow-hidden" key={cat.id}>
              <SubcategoryTabs
                categoryId={cat.id}
                designTypes={designPickerData.designTypes}
                onSubcategoryChange={handleSubcategoryChange}
                subcategories={designPickerData.subcategories}
                subcategory={subcategory}
              />
            </div>
          ),
      )}
    </>
  );
}
