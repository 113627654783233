import type { UpdateRequestUnion } from "@nlc/shared-types";
import type { UseFormReturn } from "react-hook-form";

import { RequestTypeEnum, updateRequestUnionType } from "@nlc/shared-types";
import { useMutation } from "@tanstack/react-query";

import { toast } from "@/components/toast/toast-queue";
import authenticatedApi from "@/lib/api/client";

import type { CreateVideoRequestFormData } from "../components/useCreateVideoRequestForm";

import { getValidAttachmentIds } from "../../shared/utils";

type UseSaveDraftMutationProps = {
  formMethods: UseFormReturn<CreateVideoRequestFormData>;
  latestRequestId: { requestId: number };
};

export function useSaveDraftMutation({
  formMethods,
  latestRequestId,
}: UseSaveDraftMutationProps) {
  return useMutation({
    mutationFn: async (formData: CreateVideoRequestFormData) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const commonFields: UpdateRequestUnion = {
        attachmentIds: [
          ...getValidAttachmentIds(formData.assets),
          ...getValidAttachmentIds(formData.inspirations),
        ],
        brandId: formData.brand,
        collaboratorIds: formData.collaborators.map(Number),
        complexityId: formData.designComplexity,
        creativeDirection: formData.creativeDirection,
        designTypeId: formData.videoType,
        lengthOrPlatform: formData.lengthOrPlatform,
        musicGenre: formData.musicGenre,
        name: formData.requestName,
        preferredDesignerIds: formData.preferredDesigners.map(Number),
        preferredFileType: formData.fileTypes,
        requestType: RequestTypeEnum.VIDEO,
        sizesNeeded: formData.sizes,
        styleOfVideo: formData.style,
      };

      const mbcFields = formData.briefCreatorDetailed;

      const briefTypeFields =
        formData.briefCreatorType === "detailed"
          ? {
              briefText: mbcFields.finalBriefHTML,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              textForDesign: mbcFields.script,
            }
          : {
              briefText: undefined,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              textForDesign: formData.briefCreatorSingle.textsForDesignHTML,
            };

      const magicalBriefCreatorFields =
        formData.briefCreatorType === "detailed"
          ? {
              magicalBriefCreator: {
                additionalGuidelines: mbcFields.additionalGuidelines,
                brandGuidelines: mbcFields.brandGuidelines,
                creativeGoals: mbcFields.creativeGoal,
                scope: mbcFields.scope,
                script: mbcFields.script,
              },
            }
          : {};

      const parsed = updateRequestUnionType.parse({
        ...commonFields,
        ...briefTypeFields,
        ...magicalBriefCreatorFields,
      });

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}`)
        .patch(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error) => {
      console.error("Failed to save draft:", error);

      if (error.message.includes("Request name already exists")) {
        formMethods.setError("requestName", {
          message: "Request name already exists",
        });
        formMethods.setFocus("requestName");
      }

      toast.add(
        {
          description: error.message,
          title: "Error saving draft",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onSuccess: () => {
      toast.add(
        {
          title: "Draft saved successfully",
          type: "success",
        },
        {
          timeout: 5000,
        },
      );
    },
  });
}
