import { Button as RACButton } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { Button } from "@/components/button";

import type { CategoryWithIcon } from "../types";

import { DuplicatePastRequestModal } from "../../duplicate-past-request-modal";
import DownloadIcon from "../assets/download.svg?react";
import LockIcon from "../assets/lock.svg?react";
import websiteModalImage from "../assets/website-modal.png";
import { UpgradeModal } from "./UpgradeModal";

type HeaderProps = {
  categories: CategoryWithIcon[];
  onCategoryChange: (categoryId: string) => void;
  selectedCategory: string;
};

export function Header({
  categories,
  onCategoryChange,
  selectedCategory,
}: HeaderProps) {
  return (
    <div className="flex items-center justify-between border-b border-border-primary px-3xl py-xl">
      <span className="text-xl font-semibold text-text-primary">
        Create new request
      </span>

      <div className="flex divide-x divide-border-primary overflow-hidden rounded-md border border-border-primary">
        {categories.map((category) => {
          if (category.isBlocked) {
            return (
              <UpgradeModal
                bodyText="Unlock the ability to request custom website designs, intuitive UI kits, and high-impact landing pages that drive results."
                headerText="Want to unlock new capabilities?"
                heroImage={websiteModalImage}
                key={category.id}
                onConfirm={() => {
                  window.open("https://nlc.com/manage-my-account/", "_blank");
                }}
              >
                <RACButton
                  className={twMerge(
                    "cursor-pointer px-xl py-md text-sm font-semibold text-text-secondary outline-none gap-md flex items-center hover:bg-button-secondary-border-hover/15",
                    selectedCategory === category.id &&
                      "bg-button-primary-bg text-text-white hover:bg-button-primary-bg-hover",
                  )}
                  key={category.id}
                  type="button"
                >
                  <LockIcon />
                  {category.name}
                </RACButton>
              </UpgradeModal>
            );
          }

          return (
            <RACButton
              className={twMerge(
                "cursor-pointer px-xl py-md text-sm font-semibold text-text-secondary outline-none gap-md flex items-center hover:bg-button-secondary-border-hover/15",
                selectedCategory === category.id &&
                  "bg-button-primary-bg text-text-white hover:bg-button-primary-bg-hover",
              )}
              key={category.id}
              onPress={() => onCategoryChange(category.id)}
              type="button"
            >
              {category.icon && <category.icon className="size-5" />}
              {category.name}
            </RACButton>
          );
        })}
      </div>

      <DuplicatePastRequestModal>
        <Button color="gray" variant="tertiary">
          <DownloadIcon />
          Duplicate Past Request
        </Button>
      </DuplicatePastRequestModal>
    </div>
  );
}
