import type {
  ButtonProps as RACButtonProps,
  ToggleButtonProps as RACToggleButtonProps,
  ToggleButtonGroupProps,
} from "react-aria-components";

import { Loader } from "lucide-react";
import React from "react";
import {
  composeRenderProps,
  Button as RACButton,
  ToggleButton as RACToggleButton,
  ToggleButtonGroup as RACToggleButtonGroup,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { AsChildProps } from "../slot";

import { Slot } from "../slot";
import { focusOutlineStyle } from "../utils";

type Color = "color" | "destructive" | "gray";

type Size = "2xl" | "lg" | "md" | "sm" | "xl";

type Variant = "plain" | "primary" | "secondary" | "tertiary" | "unstyled";

export type BasicButtonProps = {
  className?: string;
  color?: Color;
  isCustomLoading?: boolean;
  isIconOnly?: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
  size?: Size;
  variant?: Variant;
};

export type ButtonProps = AsChildProps<RACButtonProps> & BasicButtonProps;

export type ButtonWithoutAsChildProps = BasicButtonProps & RACButtonProps;

const buttonVariants = {
  base: "group relative inline-flex justify-center items-center whitespace-nowrap rounded-md outline-none font-semibold focus-visible:outline-4 focus-visible:outline-offset-0",
  plain: "",
  primary: "",
  secondary: "",
  tertiary: "",
};

const buttonSizes = {
  "2xl": {
    button:
      "text-xl px-[22px] py-xl gap-x-[10px] [&_svg:not([class*=size-])]:size-[24px]",
    iconOnly: "p-xl [&_svg:not([class*=size-])]:size-[24px]",
  },
  lg: {
    button:
      "text-md px-xl py-[10px] gap-x-sm [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-lg [&_svg:not([class*=size-])]:size-[20px]",
  },
  md: {
    button:
      "text-sm px-[14px] py-[10px] gap-x-xs [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-[10px] [&_svg:not([class*=size-])]:size-[20px]",
  },
  sm: {
    button:
      "text-sm px-lg py-md gap-x-xs [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-md [&_svg:not([class*=size-])]:size-[20px]",
  },
  xl: {
    button:
      "text-md px-[18px] py-lg gap-x-sm [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-[14px] [&_svg:not([class*=size-])]:size-[20px]",
  },
};

const buttonColors = {
  color: {
    plain: "",
    primary: `
      bg-button-primary-bg
      text-button-primary-fg
      hover:bg-button-primary-bg-hover
      hover:text-button-primary-fg-hover
      focus-visible:outline-button-primary-border
      disabled:bg-bg-disabled
      disabled:text-fg-disabled
      disabled:border-border-disabled
    `,
    secondary: `
      bg-button-secondary-color-bg
      text-button-secondary-color-fg
      hover:bg-button-secondary-color-bg-hover
      hover:text-button-secondary-color-fg-hover
      border
      border-button-secondary-color-border
      hover:border-button-secondary-color-border-hover
      focus-visible:outline-button-secondary-color-border
      disabled:bg-bg-disabled-subtle
      disabled:text-fg-disabled
      disabled:border-border-disabled-subtle
    `,
    tertiary: `
      bg-button-tertiary-color-bg
      text-button-tertiary-color-fg
      hover:bg-button-tertiary-color-bg-hover
      hover:text-button-tertiary-color-fg-hover
      focus-visible:outline-none
      disabled:bg-bg-disabled-subtle
      disabled:text-fg-disabled
    `,
  },
  destructive: {
    plain: "",
    primary: `
      bg-button-primary-error-bg
      text-button-primary-error-fg
      hover:bg-button-primary-error-bg-hover
      hover:text-button-primary-error-fg-hover
      focus-visible:outline-button-primary-error-border
      disabled:bg-bg-disabled
      disabled:text-fg-disabled
      disabled:border-border-disabled
    `,
    secondary: `
      bg-button-secondary-error-bg
      text-button-secondary-error-fg
      hover:bg-button-secondary-error-bg-hover
      hover:text-button-secondary-error-fg-hover
      border
      border-button-secondary-error-border
      hover:border-button-secondary-error-border-hover
      focus-visible:outline-button-secondary-error-border
      disabled:bg-bg-disabled-subtle
      disabled:text-fg-disabled
      disabled:border-border-disabled-subtle
    `,
    tertiary: `
      bg-transparent
      text-button-tertiary-error-fg
      hover:bg-button-tertiary-error-bg-hover
      hover:text-button-tertiary-error-fg-hover
      focus-visible:outline-none
      disabled:bg-bg-disabled-subtle
      disabled:text-fg-disabled
    `,
  },
  gray: {
    plain: "",
    primary: "",
    secondary: `
      bg-button-secondary-bg
      text-button-secondary-fg
      hover:bg-button-secondary-bg-hover
      hover:text-button-secondary-fg-hover
      border
      border-button-secondary-border
      hover:border-button-secondary-border-hover
      focus-visible:outline-button-secondary-border
      disabled:bg-bg-disabled-subtle
      disabled:text-fg-disabled
      disabled:border-border-disabled-subtle
    `,
    tertiary: `
      bg-transparent
      text-button-tertiary-fg
      hover:bg-button-tertiary-bg-hover
      hover:text-button-tertiary-fg-hover
      focus-visible:outline-none
      disabled:text-fg-disabled
    `,
  },
};

function buttonStyle({
  color = "color",
  isIconOnly,
  size = "md",
  variant = "primary",
}: BasicButtonProps) {
  if (variant === "unstyled") {
    return "relative outline-none";
  }

  const buttonType = isIconOnly ? "iconOnly" : "button";

  return twMerge(
    buttonVariants.base,
    buttonVariants[variant],
    buttonColors[color][variant],
    buttonSizes[size][buttonType],
  );
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const {
      asChild,
      children,
      color,
      isCustomLoading,
      isIconOnly,
      isLoading,
      loadingLabel,
      size,
      variant,
      ...buttonProps
    } = props;

    if (asChild) {
      return <Slot className={buttonStyle(props)}>{children}</Slot>;
    }

    return (
      <>
        <RACButton
          {...buttonProps}
          className={composeRenderProps(
            props.className,
            (className, renderProps) =>
              twMerge(
                buttonStyle({ color, isIconOnly, isLoading, size, variant }),
                renderProps.isFocusVisible && focusOutlineStyle,
                isLoading && !isCustomLoading && "text-transparent",
                className,
              ),
          )}
          data-variant={variant ?? "solid"}
          ref={ref}
        >
          {(renderProps) => (
            <>
              {isLoading && !isCustomLoading && (
                <Loader
                  className={twMerge(
                    "absolute flex h-full items-center justify-center animate-spin",
                    isLoading ? "flex" : "hidden",
                  )}
                />
              )}

              {typeof children === "function"
                ? children(renderProps)
                : children}
            </>
          )}
        </RACButton>

        <span aria-live="polite" className="sr-only">
          {isLoading ? loadingLabel : ""}
        </span>
      </>
    );
  },
);

export function ToggleButton(
  props: {
    allowTooltipOnDisabled?: boolean;
    tooltip?: React.ReactNode;
  } & BasicButtonProps &
    RACToggleButtonProps,
) {
  const { color, isIconOnly, size, variant, ...buttonProps } = props;

  return (
    <RACToggleButton
      {...buttonProps}
      className={composeRenderProps(props.className, (className, renderProps) =>
        twMerge(
          buttonStyle({ color, isIconOnly, size, variant, ...renderProps }),
          className,
        ),
      )}
      data-variant={variant}
    />
  );
}

const buttonGroupStyle = ({
  inline,
  orientation = "horizontal",
}: {
  inline?: boolean;
  orientation?: "horizontal" | "vertical";
}) => {
  const style = {
    base: [
      "group inline-flex w-max items-center",
      "[&>*:not(:first-child):not(:last-child)]:rounded-none",
      "[&>*[data-variant=solid]:not(:first-child)]:border-s-[lch(from_var(--btn-bg)_calc(l*0.85)_c_h)]",
    ],
    horizontal: [
      "[&>*:first-child]:rounded-e-none",
      "[&>*:last-child]:rounded-s-none",
      "[&>*:not(:last-child)]:border-e-0",
      inline && "shadow-xs [&>*:not(:first-child)]:border-s-0 *:shadow-none",
    ],
    vertical: [
      "flex-col",
      "[&>*:first-child]:rounded-b-none",
      "[&>*:last-child]:rounded-t-none",
      "[&>*:not(:last-child)]:border-b-0",

      inline && "shadow-xs [&>*:not(:first-child)]:border-t-0 *:shadow-none",
    ],
  };

  return [style.base, style[orientation]];
};

export function ToggleButtonGroup({
  inline,
  orientation = "horizontal",
  ...props
}: {
  inline?: boolean;
  orientation?: "horizontal" | "vertical";
} & ToggleButtonGroupProps) {
  return (
    <RACToggleButtonGroup
      {...props}
      className={composeRenderProps(props.className, (className) =>
        twMerge(buttonGroupStyle({ inline, orientation }), className),
      )}
      data-ui="button-group"
    />
  );
}

export function ButtonGroup({
  className,
  inline,
  orientation = "horizontal",
  ...props
}: {
  inline?: boolean;
  orientation?: "horizontal" | "vertical";
} & React.JSX.IntrinsicElements["div"]) {
  return (
    <div
      {...props}
      className={twMerge(buttonGroupStyle({ inline, orientation }), className)}
      data-ui="button-group"
    />
  );
}
