import type { DropEvent, FileDropItem } from "react-aria";
import type {
  Path,
  PathValue,
  UseFormReturn,
  UseFormSetError,
} from "react-hook-form";

import { useMutation } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { useState } from "react";

import { useCapturePosthogEvent } from "@/hooks/posthog/useCapturePosthogEvent";
import apiClient from "@/lib/api/client";

import type { Asset, FileAsset, RequestFormData } from "../types";
import type { LinkFormValues } from "../useLinkForm";

import {
  hasDuplicateFileName,
  MAX_FILE_SIZE,
  MAX_FILES,
} from "../utils/uploadUtils";
import { useFileDelete } from "./useFileDelete";
import { useLinkManagement } from "./useLinkManagement";

interface UploadUrlFields {
  attachmentId: number;
  fields: Record<string, string>;
  presignedUrl: string;
  url: string;
}

interface UseAssetsSectionReturn {
  assets: Asset[];
  error: null | string;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (attachmentId: number) => void;
  handleFileDrop: (e: DropEvent) => void;
  handleFileSelect: (e: FileList | null) => void;
  handleLinkAdd: (url: { link: string }) => void;
  handleLinkDelete: (linkId: string) => void;
}

function useFileUpload<TFormValues extends RequestFormData>(
  methods: UseFormReturn<TFormValues>,
  field: Path<TFormValues>,
) {
  const capture = useCapturePosthogEvent();

  return useMutation({
    mutationFn: async ({
      field,
      newFile,
    }: {
      field: Path<TFormValues>;
      newFile: FileAsset;
    }) => {
      const uploadUrlFields = await apiClient
        .query({
          fileName: newFile.file.name,
          size: newFile.file.size,
          type: field === "assets" ? "asset" : "example",
        })
        .get("/attachments/upload-url")
        .json<UploadUrlFields>();

      const formData = new FormData();

      for (const [key, value] of Object.entries(uploadUrlFields.fields)) {
        formData.append(key, value);
      }
      formData.append("file", newFile.file);

      const uploadResponse = await fetch(uploadUrlFields.presignedUrl, {
        body: formData,
        method: "POST",
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }

      return uploadUrlFields;
    },
    onError: (_error, variables) => {
      methods.setValue(
        field,
        methods
          .getValues(field)
          .map((asset: Asset) =>
            asset.id === variables.newFile.id
              ? { ...asset, status: "error" as const }
              : asset,
          ),
      );
    },
    onSuccess: (uploadUrlFields, variables) => {
      capture("brief_asset_upload", {
        file_type: variables.newFile.file.type,
        form_version: methods.watch("briefCreatorType" as Path<TFormValues>),
      });

      methods.setValue(
        field,
        methods.getValues(field).map((asset: Asset) =>
          asset.id === variables.newFile.id
            ? {
                ...asset,
                attachmentId: uploadUrlFields.attachmentId,
                status: "success" as const,
              }
            : asset,
        ),
      );
    },
  });
}

/**
 * Hook to manage assets section including file uploads and link management
 * @param field - The form field to manage ('assets' or 'inspirations')
 * @param setLinkFormError - Function to set errors in the link form
 */
export function useAssetsSection<TFormValues extends RequestFormData>(
  field: Path<TFormValues>,
  assets: Asset[],
  setLinkFormError: UseFormSetError<LinkFormValues>,
  formMethods: UseFormReturn<TFormValues>,
): UseAssetsSectionReturn {
  const [error, setError] = useState<null | string>(null);

  const { mutate: uploadAssetFile } = useFileUpload(formMethods, field);
  const { handleLinkAdd, handleLinkDelete } = useLinkManagement(
    field,
    assets,
    formMethods,
    setError,
    setLinkFormError,
  );
  const { handleFileDelete } = useFileDelete(
    field,
    assets,
    setError,
    formMethods,
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setError(null);

    const files = event.target.files;

    if (!files) return;

    const uploadedFiles = [...files];

    if (uploadedFiles.length + assets.length > MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files.`);

      return;
    }

    const newFiles: FileAsset[] = uploadedFiles.map((file) => ({
      attachmentId: null,
      file,
      id: nanoid(),
      status: "uploading" as const,
    }));

    formMethods.setValue(field, [...newFiles, ...assets] as PathValue<
      TFormValues,
      Path<TFormValues>
    >);

    // Validate and upload each file
    for (const file of newFiles) {
      if (hasDuplicateFileName(file.file.name, assets)) {
        setError(`Cannot upload duplicate file: ${file.file.name}`);
        updateFileStatus(file.id, "error");
        continue;
      }

      if (file.file.size > MAX_FILE_SIZE) {
        setError(`File ${file.file.name} exceeds the maximum size of 200MB.`);
        updateFileStatus(file.id, "error");
        continue;
      }

      uploadAssetFile({ field, newFile: file });
    }
  };

  // Helper function to update file status
  const updateFileStatus = (fileId: string, status: "error" | "success") => {
    formMethods.setValue(
      field,
      formMethods
        .getValues(field)
        .map((asset: Asset) =>
          asset.id === fileId ? { ...asset, status } : asset,
        ),
    );
  };

  // Helper function to convert FileList to a synthetic event for handleFileChange
  const processFiles = (fileList: FileList) => {
    const syntheticEvent = {
      preventDefault: () => {},
      target: {
        files: fileList,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleFileChange(syntheticEvent);
  };

  // Handler for file drop events
  const handleFileDrop = (e: DropEvent) => {
    const fileItems = e.items.filter(
      (item) => item.kind === "file",
    ) as FileDropItem[];

    if (fileItems.length > 0) {
      const dataTransfer = new DataTransfer();

      Promise.all(fileItems.map((item) => item.getFile())).then((files) => {
        for (const file of files) {
          if (file) dataTransfer.items.add(file);
        }

        processFiles(dataTransfer.files);
      });
    }
  };

  // Handler for file selection events
  const handleFileSelect = (e: FileList | null) => {
    if (e) {
      const fileList = [...e];
      const dataTransfer = new DataTransfer();

      for (const file of fileList) {
        dataTransfer.items.add(file);
      }

      processFiles(dataTransfer.files);
    }
  };

  return {
    assets,
    error,
    handleFileChange,
    handleFileDelete,
    handleFileDrop,
    handleFileSelect,
    handleLinkAdd,
    handleLinkDelete,
  };
}
