import type {
  TabListProps,
  TabPanelProps,
  TabProps,
  TabsProps,
} from "react-aria-components";

import {
  composeRenderProps,
  Tab as RACTab,
  TabList as RACTabList,
  TabPanel as RACTabPanel,
  Tabs as RACTabs,
} from "react-aria-components";
import { tv } from "tailwind-variants";

const tabsStyles = tv({
  base: "flex",
  variants: {
    orientation: {
      horizontal: "flex-col bg-bg-primary",
      vertical: "w-[800px] flex-row",
    },
  },
});

export function Tabs(props: TabsProps) {
  return (
    <RACTabs
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabsStyles({ ...renderProps, className }),
      )}
    />
  );
}

const tabListStyles = tv({
  base: "flex gap-lg",
  variants: {
    orientation: {
      horizontal:
        " flex-row border-b border-b-border-secondary bg-bg-primary outline-none",
      vertical: "flex-col items-start",
    },
  },
});

export function TabList<T extends object>(props: TabListProps<T>) {
  return (
    <RACTabList
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabListStyles({ ...renderProps, className }),
      )}
    />
  );
}

const tabProps = tv({
  base: "relative flex cursor-pointer items-center  px-4 py-1.5 text-sm font-medium transition forced-color-adjust-none",
  variants: {
    isDisabled: {
      true: "text-sm font-semibold text-text-quaternary",
    },
    isSelected: {
      false: "text-sm font-semibold text-text-quaternary outline-none",
      true: "border-b-2 border-fg-brand-primary text-sm  font-semibold text-text-brand-secondary outline-none",
    },
  },
});

export function Tab(props: TabProps) {
  return (
    <RACTab
      {...props}
      className={composeRenderProps(
                props.className,
        (className, renderProps) => tabProps({ ...renderProps, className }),
      )}
    />
  );
}

const tabPanelStyles = tv({
  base: "flex-1 py-3 text-sm",
});

export function TabPanel(props: TabPanelProps) {
  return (
    <RACTabPanel
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabPanelStyles({ ...renderProps, className }),
      )}
    />
  );
}
