import type { GetCustomerUser } from "@nlc/shared-types";
import type { SortDescriptor } from "react-stately";

import { useState } from "react";

import { Loader } from "@/components/Loader";
import { UserTable } from "@/features/users/customer/components/CustomerUsersTable.tsx";
import { Header } from "@/features/users/shared/components/Header";
import { SearchBar } from "@/features/users/shared/components/SearchBar.tsx";
import { useGetUsers } from "@/features/users/shared/hooks/useGetUsers.tsx";
import { useDebounce } from "@/hooks/useDebounce.ts";

import { TableFooter } from "../shared/components/TableFooter.tsx";

export function CustomerUsers() {
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "createdOn",
    direction: "ascending",
  });

  const debouncedQuery = useDebounce(inputValue, 500, ()=>setPage(1));

  const direction: "asc" | "desc" =
    sortDescriptor.direction === "descending" ? "asc" : "desc";

  const searchParams = {
    direction,
    itemsPerPage,
    page,
    search: debouncedQuery,
    sortBy: sortDescriptor?.column as keyof GetCustomerUser as string,
  };

  const { data: response, isFetching } = useGetUsers(searchParams);

  const rows = response?.data?.customer || [];
  const { totalItems } = response?.metadata ?? { totalItems: 0 };

  return (
    <>
      <Header />
      <SearchBar
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
      {isFetching ? (
        <Loader className="py-20" />
      ) : (
        <>
          <UserTable
            rows={rows}
            setSortDescriptor={setSortDescriptor}
            sortDescriptor={sortDescriptor}
          />
        </>
      )}
      <TableFooter
        itemsPerPage={itemsPerPage}
        page={page}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        totalItems={totalItems}
      />
    </>
  );
}
