import { Controller, useFormContext } from "react-hook-form";

import { Button } from "@/components/button";
import { Label } from "@/components/field";
import { MinusIcon, PlusIcon } from "@/components/icons";

import type { DuplicatePastRequestFormData } from "../useDuplicatePastRequestForm";

export function DuplicatesCounter() {
  const { control } = useFormContext<DuplicatePastRequestFormData>();

  return (
    <Controller
      control={control}
      name="numberOfDuplicates"
      render={({ field: { onChange, value } }) => (
        <div className="flex items-center justify-between">
          <Label>Number of duplicates</Label>
          <div className="flex items-center justify-center">
            <Button
              color="gray"
              isDisabled={value <= 1}
              isIconOnly
              onPress={() => onChange(value - 1)}
              size="sm"
              variant="secondary"
            >
              <MinusIcon />
            </Button>
            <span className="w-5xl text-center text-text-primary">{value}</span>
            <Button
              color="gray"
              isIconOnly
              onPress={() => onChange(value + 1)}
              size="sm"
              variant="secondary"
            >
              <PlusIcon />
            </Button>
          </div>
        </div>
      )}
    />
  );
}
