import { Loader } from "lucide-react";
import { useCallback } from "react";
import { z } from "zod";

import { Alert } from "@/components/alert";
import { Button } from "@/components/button";
import { Label } from "@/components/field";
import { ControlledQuillEditor } from "@/components/ui/controlled/ControlledQuillEditor";
import { useIsMBCSubmitted } from "@/features/request/create/shared/useIsMBCSubmitted";
import { useCapturePosthogEvent } from "@/hooks/posthog/useCapturePosthogEvent";
import { useFormChangeTracking } from "@/hooks/posthog/useFormChangeTracking";

import type useAIStream from "./hooks/useAIStream";

import SparklesIcon from "../../../shared/assets/sparkles.svg?react";
import WandIcon from "../../../shared/assets/wand.svg?react";
import { useCreateWebsiteDesignRequestFormContext } from "../useCreateWebsiteDesignRequestForm";
import { useAlert } from "./hooks/useAlert";

function PendingState() {
  return (
    <div className="row-span-2 flex h-min flex-col gap-lg rounded-2xl border border-border-secondary bg-bg-secondary p-4xl text-center">
      <div className="flex flex-col items-center gap-lg">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-fg-brand-primary" />
          <span className="font-semibold text-text-secondary">
            Magical Brief Creator
          </span>
        </div>
        <span className="w-[386px] text-sm font-medium text-text-secondary">
          Enter your brief details on the left, and enhance or just preview the
          final results here.
        </span>
      </div>

      <div className="flex flex-col items-center gap-md">
        <Button isCustomLoading isDisabled isLoading>
          <Loader className="animate-spin" />
          Improving...
        </Button>
        <span className="text-sm text-text-secondary">
          Enhancements in progress...
        </span>
      </div>
    </div>
  );
}

type SuccessStateProps = {
  handleClearResponse: () => void;
  isPending: boolean;
  onSubmit: () => void;
  streamedResponse: string;
};

function getEditorHeightClassName(
  isSubmissionDetailsHidden: boolean,
  isMagicalBriefFormHidden: boolean,
): string {
  const EDITOR_HEIGHT_CLASSNAMES = {
    ALL_ALERTS: "h-[913px]",
    MBF_ONLY: "h-[1036px]",
    NO_ALERTS: "h-[954px]",
    SUBMISSION_DETAILS_ONLY: "h-[828px]",
  };

  switch (true) {
    case isSubmissionDetailsHidden && isMagicalBriefFormHidden: {
      return EDITOR_HEIGHT_CLASSNAMES.NO_ALERTS;
    }
    case isSubmissionDetailsHidden: {
      return EDITOR_HEIGHT_CLASSNAMES.MBF_ONLY;
    }
    case isMagicalBriefFormHidden: {
      return EDITOR_HEIGHT_CLASSNAMES.SUBMISSION_DETAILS_ONLY;
    }
    default: {
      return EDITOR_HEIGHT_CLASSNAMES.ALL_ALERTS;
    }
  }
}

function SuccessState({
  handleClearResponse,
  isPending,
  onSubmit,
}: SuccessStateProps) {
  const capture = useCapturePosthogEvent();
  const { control, setValue, watch } =
    useCreateWebsiteDesignRequestFormContext();
  const { incrementRegeneration, trackEvent } = useFormChangeTracking(watch);

  const {
    hideAlert: hideSubmissionDetailsAlert,
    isAlertHidden: isSubmissionDetailsAlertHidden,
  } = useAlert("submission-details");
  const { isAlertHidden: isCheckMagicalBriefFormAlertHidden } =
    useAlert("check-mbf");

  const heightClassName = getEditorHeightClassName(
    isSubmissionDetailsAlertHidden,
    isCheckMagicalBriefFormAlertHidden,
  );

  return (
    <div className="row-span-2 flex h-min flex-col gap-lg rounded-2xl border border-border-secondary bg-bg-secondary p-4xl text-center">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-fg-brand-primary" />
          <span className="font-semibold text-text-secondary">
            Magical Brief Creator
          </span>
        </div>
        <div className="flex items-center gap-sm">
          <Button
            className="w-auto"
            color="destructive"
            onPress={() => {
              capture("magic_brief_discarded");
              handleClearResponse();
            }}
            size="sm"
            variant="secondary"
          >
            Discard
          </Button>
          <Button
            className="w-auto"
            isDisabled={isPending}
            onPress={() => {
              incrementRegeneration();
              trackEvent("brief_regenerated");
              onSubmit();
            }}
            size="sm"
            variant="secondary"
          >
            <WandIcon />
            Regenerate
          </Button>
        </div>
      </div>
      {isSubmissionDetailsAlertHidden ? null : (
        <Alert
          description="Submitting will send only the content from Magical Brief Creator.
              To edit the fields or move to Classic Brief Form, click 'Discard'"
          onClick={hideSubmissionDetailsAlert}
          title="Submission Details"
        />
      )}
      <div className="flex flex-col text-left">
        <div className="mb-xs flex items-center justify-between">
          <Label requiredHint>Final Brief</Label>
        </div>

        <ControlledQuillEditor
          className={heightClassName}
          control={control}
          name="briefCreatorDetailed.finalBrief"
          onChangeHTML={(html) => {
            setValue("briefCreatorDetailed.finalBriefHTML", html);
          }}
          readOnly={isPending}
        />
      </div>
    </div>
  );
}

type EmptyStateProps = {
  isPending: boolean;
  onSubmit: () => void;
};

function EmptyState({ isPending, onSubmit }: EmptyStateProps) {
  const capture = useCapturePosthogEvent();
  const { getValues, watch } = useCreateWebsiteDesignRequestFormContext();

  const mandatoryFields = [
    "briefCreatorDetailed.scope",
    "briefCreatorDetailed.creativeGoal",
  ] as const;

  const filledMandatoryFields = mandatoryFields.reduce(
    (count, field) => count + Number(Boolean(watch(field))),
    0,
  );
  const totalMandatoryFields = 2;

  return (
    <div className="row-span-2 flex h-min flex-col gap-3xl rounded-2xl border border-border-secondary bg-bg-secondary p-4xl text-center">
      <div className="flex flex-col items-center gap-lg">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-fg-brand-primary" />
          <span className="font-semibold text-text-secondary">
            Magical Brief Creator
          </span>
        </div>
        <span className="w-[386px] text-sm font-medium text-text-secondary">
          Enter your brief details on the left, and enhance or just preview the
          final results here.
        </span>
      </div>

      <div className="flex flex-col items-center gap-md">
        <Button
          isCustomLoading
          isDisabled={
            isPending || filledMandatoryFields !== totalMandatoryFields
          }
          isLoading={isPending}
          loadingLabel="Deploying"
          onPress={() => {
            const { briefCreatorDetailed } = getValues();

            capture("magic_brief_initiated", {
              additional_guidelines: briefCreatorDetailed.additionalGuidelines,
              brand_guidelines: briefCreatorDetailed.brandGuidelines,
              creative_goal: briefCreatorDetailed.creativeGoal,
              scope: briefCreatorDetailed.scope,
              texts_for_design: briefCreatorDetailed.textsForDesign,
            });

            onSubmit();
          }}
        >
          {isPending ? (
            <>
              <Loader className="animate-spin" />
              Improving...
            </>
          ) : (
            <>
              <WandIcon />
              Improve Brief
            </>
          )}
        </Button>
        <span className="text-sm text-text-secondary">
          {`${filledMandatoryFields}/${totalMandatoryFields} mandatory fields filled`}
        </span>
      </div>
    </div>
  );
}

type MagicalBriefCreatorProps = ReturnType<typeof useAIStream>;

export function MagicalBriefCreator({
  ...aiStreamUtils
}: MagicalBriefCreatorProps) {
  const { setIsSubmitted } = useIsMBCSubmitted((state) => state);

  const {
    abortStream,
    isPending,
    resetStreamedResponse,
    sendPrompt,
    streamedResponse,
  } = aiStreamUtils;

  const { clearErrors, getValues, setError } =
    useCreateWebsiteDesignRequestFormContext();

  const handleSubmit = useCallback(async () => {
    const formValues = getValues();

    if (formValues) {
      const { briefCreatorDetailed } = formValues;

      const schema = z.object({
        additionalGuidelines: z
          .string()
          .max(800, { message: "Additional guidelines are too long" }),
        brandGuidelines: z
          .string()
          .max(800, { message: "Brand guidelines are too long" }),
        creativeGoal: z
          .string()
          .min(1, { message: "Creative goal is required" })
          .max(800, { message: "Creative goal is too long" }),
        scope: z
          .string()
          .min(1, { message: "Scope is required" })
          .max(800, { message: "Scope is too long" }),
        textsForDesign: z
          .string()
          .max(800, { message: "Text for design is too long" }),
      });

      // Validate fields required to create Magical Brief with AI
      const result = schema.safeParse(briefCreatorDetailed);

      if (result.success) {
        clearErrors([
          "briefCreatorDetailed.additionalGuidelines",
          "briefCreatorDetailed.brandGuidelines",
          "briefCreatorDetailed.creativeGoal",
          "briefCreatorDetailed.textsForDesign",
          "briefCreatorDetailed.scope",
        ]);

        sendPrompt(briefCreatorDetailed);
      } else {
        const { fieldErrors } = result.error.flatten();

        for (const [field, errors] of Object.entries(fieldErrors)) {
          if (errors?.[0]) {
            setError(
              `briefCreatorDetailed.${field}` as
                | "briefCreatorDetailed.additionalGuidelines"
                | "briefCreatorDetailed.brandGuidelines"
                | "briefCreatorDetailed.creativeGoal"
                | "briefCreatorDetailed.scope"
                | "briefCreatorDetailed.textsForDesign",
              {
                message: errors[0],
              },
            );
          }
        }

        return;
      }
    }

    setIsSubmitted(true);
  }, [getValues, sendPrompt, setIsSubmitted, setError, clearErrors]);

  const handleClearResponse = useCallback(() => {
    if (isPending) {
      abortStream();
    } else {
      resetStreamedResponse();
    }

    setIsSubmitted(false);
  }, [isPending, abortStream, resetStreamedResponse, setIsSubmitted]);

  const isFetching = isPending && !streamedResponse;
  const isSuccess = streamedResponse;
  const isEmpty = !isPending && !streamedResponse;

  if (isEmpty) {
    return <EmptyState isPending={isPending} onSubmit={handleSubmit} />;
  }

  if (isFetching) {
    return <PendingState />;
  }

  if (isSuccess) {
    return (
      <SuccessState
        handleClearResponse={handleClearResponse}
        isPending={isPending}
        onSubmit={handleSubmit}
        streamedResponse={streamedResponse}
      />
    );
  }

  return null;
}
