import { Button } from "@/components/button";
import { Link } from "@/components/link";
import { env } from "@/config/env.ts";

export function ImpersonateButton({ id }: { id: string }) {
  return (
    <Link href={`${env.VITE_PHP_URL}/impersonate/${id}`}>
      <Button className="text-xs" size="sm" variant="secondary">
        Impersonate
      </Button>
    </Link>
  );
}
